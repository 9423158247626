<div class="p-8">
  <app-loader *ngIf="isLoading"></app-loader>
  <div class="bg-white rounded-lg p-8">
    <p class="text-xl font-bold mb-5 text-gray-800">
      <span *ngIf="!labId">Create New Lab</span>
      <span *ngIf="labId">Edit Lab</span>
    </p>
    <p class="text-md mb-5">Fill In The Details Of The Lab</p>
    <form [formGroup]="updateLabForm">
      <div class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3"
          >Organization Name*</label
        >
        <select
          *ngIf="isGrokCloudMember"
          formControlName="orgName"
          (change)="onOrgSelect($event)"
        >
          <ng-container *ngFor="let org of orgList">
            <option class="input-style" [value]="org.orgName">
              {{ org.orgName }}
            </option>
          </ng-container>
        </select>
        <select *ngIf="!isGrokCloudMember">
          <option value="" disabled selected [value]="organizationName">
            {{ organizationName }}
          </option>
        </select>
      </div>
      <div class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3"
          >Lab Name</label
        >
        <input
          class="input-style p-3"
          name="lab-name"
          formControlName="labName"
          placeholder="Lab Name"
        />
      </div>
      <div class="flex">
        <button class="mr-2 text-white btn-1" (click)="updateLab()">
          Update Lab
        </button>
        <button class="btn-2 mr-2 text-white" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
