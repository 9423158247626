import { 
  SelectOption, 
  InputField, 
  SelectField, 
  FieldConfig, 
  FieldParams, 
  FieldValue, 
  DeviceFieldConfigurations, 
  FieldAppearanceSettings, 
  FieldSettingsConfig, 
  FieldDisplaySettings, 
  DOMConfiguration, 
  DOMFieldBase, 
  DOMSelectField, 
  DOMInputField, 
  FieldGroupConfigurations 
} from "../models/sensor";
import { FieldType } from "./fieldTypes";

// Constants
const deviceTypes = [
  'Boolean',
  'Number',
  '2Numbers',
  '6Numbers',
  'Character',
  'Text',
  'Climate_sensor',
  'Power_meter',
  'Dc_Drive',
  'Mes_Color_Packet',
  'Car_Controller',
  'Vehicle_Controller',
  'Chemical_Reactor',
] as const;

const sensorTypes = [
  'INPUT',
  'OUTPUT',
  'IOT GATEWAY',
  'COMPONENTS',
] as const;

type DeviceType = (typeof deviceTypes)[number];
type SensorType = (typeof sensorTypes)[number];

/**
 * Capitalizes the first letter of a string.
 * 
 * @param text - The input string.
 * @returns The string with the first letter capitalized.
 */
const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Creates a list of numeric select options with a prefix and count.
 * 
 * @param prefix - The prefix for the option labels (e.g., 'prefix').
 * @param count - The number of options to generate (e.g., 10).
 * @returns A list of select options with values from 0 to the specified count.
 */
const createNumberSelectOpts = (prefix: string, count: number): SelectOption<number>[] => Array.from({ length: count }, (_, i) => ({
    value: i + 1,
    label: `${capitalizeFirstLetter(prefix)}${i + 1}`,
  }));

/**
 * Creates a list of select options based on input values.
 * Each value is formatted and converted to a select option.
 * 
 * @param inputValues - The list of string values to convert into select options.
 * @param formatValue - A boolean to control if the first letter of the value should be capitalized.
 * @returns A list of formatted select options with labels and values.
 */
const createSelectOptions = (inputValues: string[], formatValue: boolean): SelectOption<string>[] =>
  inputValues.map((value) => {
    const lowercaseValue = value.toLowerCase();
    const formattedValue = lowercaseValue === 'none'
      ? 'None'
      : formatValue 
        ? capitalizeFirstLetter(lowercaseValue) 
        : lowercaseValue;
    return {
      value: formattedValue,
      label: capitalizeFirstLetter(value),
    };
  });

/**
 * Creates a list of strings with a given prefix and count.
 * 
 * @param prefix - The prefix to prepend for each string.
 * @param count - The number of strings to generate.
 * @returns An array of strings with the specified prefix and count.
 */
const createPrefixedStrValues = (prefix: string, count: number): string[] =>
  Array.from({ length: count }, (_, index) => `${prefix}${index + 1}`);

/**
 * Creates a field configuration with a label and input type (numeric or boolean).
 * Optionally, a placeholder can be provided for the field.
 * 
 * @param label - The label for the field.
 * @param inputType - The type of the input (numeric or boolean).
 * @param placeholder - An optional placeholder for the field.
 * @returns A new input field configuration.
 */
const createField = (label: string, inputType: FieldType.Numeric | FieldType.Boolean, placeholder?: string): InputField => ({
  label,
  inputType,
  placeholder,
});

/**
 * Creates a numeric field configuration with label and optional placeholder.
 * 
 * @param label - The label for the numeric field.
 * @param placeholder - An optional placeholder for the field.
 * @returns A new numeric input field configuration.
 */
const createNumericField = (label: string, placeholder?: string): InputField => createField(label, FieldType.Numeric, placeholder);

/**
 * Creates a boolean field configuration with label and optional placeholder.
 * 
 * @param label - The label for the boolean field.
 * @param placeholder - An optional placeholder for the field.
 * @returns A new boolean input field configuration.
 */
const createBooleanField = (label: string, placeholder?: string): InputField => createField(label, FieldType.Boolean, placeholder);

/**
 * Creates a select field configuration with label and options.
 * 
 * @template T - The type of the select options (string or number).
 * @param label - The label for the select field.
 * @param options - The list of select options for the field.
 * @returns A new select field configuration.
 */
const createSelectField = <T extends FieldValue>(label: string, options: SelectOption<T>[]): SelectField<T> => ({
  label,
  inputType: FieldType.Select,
  options,
});

/**
 * Generates numeric field configurations based on group configurations.
 * Each field will be configured with a label and an optional placeholder.
 * 
 * @param configs - The group configurations containing labels and placeholders.
 * @returns A mapping of group names to their respective numeric field configurations.
 */
const generateNumericFieldConfigs = (configs: FieldGroupConfigurations): { [K in keyof FieldGroupConfigurations]: InputField[] } => {
  return Object.fromEntries(
    Object.entries(configs).map(([key, { labels, placeholders }]) => [
      key,
      labels.map((label: string, index: number) => createNumericField(label, placeholders?.[index]))
    ])
  ) as { [K in keyof FieldGroupConfigurations]: InputField[] };
};

// Numeric Configurations using consolidated helper function
const NUMERIC_CONFIGURATIONS = generateNumericFieldConfigs({
  climate: { 
    labels: ['temp', 'hum'], 
    placeholders: ['Temperature', 'Humidity'] 
  },
  powerMeter: { 
    labels: ['volt', 'curr', 'pw', 'en', 'fq', 'pf'], 
    placeholders: ['Voltage', 'Current', 'Power', 'Energy', 'Frequency', 'Power Factor'] 
  },
  heartbeat: { 
    labels: ['hb', 'spo2'], 
    placeholders: ['Heartbeat', 'SPO2'] 
  },
  mesColor: { 
    labels: ['red', 'green', 'yellow'] 
  }
});

/**
 * Generates field configurations for different devices, creating `SelectField` or `InputField`
 * objects based on the provided field parameters.
 * 
 * @param configs An object where keys are device types and values are arrays of field parameters.
 * @returns An object with device types as keys and arrays of corresponding field configurations.
 * 
 * @example
 * const deviceFieldConfigs = generateFieldConfigs({
 *   Climate_sensor: [{ label: 'Temperature', inputType: 'numeric' }],
 *   Power_meter: [{ label: 'Voltage', inputType: 'numeric' }]
 * });
 */
const generateFieldConfigs = <T extends FieldValue>(configs: DeviceFieldConfigurations): DeviceFieldConfigurations => {
  return Object.fromEntries(
    Object.entries(configs).map(([key, paramsArray]) => [
      key,
      paramsArray.map((params: FieldParams<FieldType>) => {

        if (params.inputType === FieldType.Select) {
          return { 
            ...params, 
            options: params.options as SelectOption<T>[] 
          } as SelectField<T>;
        }

        return params as InputField;
      }),
    ])
  ) as DeviceFieldConfigurations;
};

const fieldConfigs: DeviceFieldConfigurations = generateFieldConfigs({
  dcDrive: [
    createNumericField('rpm'),
    createSelectField(
      'mode',
      [
        { value: 0, label: 'None' },
        ...createNumberSelectOpts('mode ', 10),
      ]
    ),
    createBooleanField('power'),
  ],

  carController: [
    createSelectField(
      'car controller',
      createSelectOptions(['none', 'red', 'green', 'yellow', 'blue', 'purple', 'brown', 'white'], true)
    ),
  ],

  vehicleController: [
    createSelectField(
      'vehicle controller',
      createSelectOptions(
        [
          'none', 
          ...createPrefixedStrValues('vehicle-', 10)
        ],
        false
      )
    ),
  ],

  chemicalReactor: [
    createNumericField('stirrer speed'),
    createNumericField('water temperature'),
    createBooleanField('hot water supply'),
    createBooleanField('cold water supply'),
    createSelectField(
      'reaction type',
      createSelectOptions(['exothermic', 'endothermic'], false)
    ),
  ],
});

/**
 * A map of field display settings that defines whether a suffix and/or a placeholder
 * should be added to the fields. Each key in this map represents a specific display 
 * setting configuration.
 * 
 * - `default`: Both suffix and placeholder are added.
 * - `noSuffixOrPlaceholder`: Neither suffix nor placeholder is added.
 * - `noSuffix`: Placeholder is added, but suffix is not.
 * - `noPlaceholder`: Suffix is added, but placeholder is not.
 * 
 * The settings are represented by an object with boolean properties `addSuffix` and `addPlaceholder`.
 */
const fieldDisplaySettings: FieldDisplaySettings = Object.fromEntries(
  Object.entries({
    default: [true, true],
    noSuffixOrPlaceholder: [false, false],
    noSuffix: [false, true],
    noPlaceholder: [true, false],
  } as const).map(([key, [addSuffix, addPlaceholder]]) => [
    key,
    { addSuffix, addPlaceholder },
  ])
);

/**
 * Generates a base DOM field with optional suffix and placeholder.
 * The resulting field is either a select field or a simple input field (numeric or boolean).
 * 
 * @template T - The type of field (numeric, boolean, or select).
 * @param field - The field configuration to be converted to DOM format.
 * @param settings - Optional settings for customizing the DOM field (i.e., adding suffix or placeholder).
 * @returns A DOM-compatible field configuration.
 */
const generateDOMField = <T extends FieldValue>(
  field: FieldConfig<T>,
  settings: FieldAppearanceSettings = fieldDisplaySettings.default
): DOMConfiguration => {
  const STRING_FORMATS = {
    WORD_SEPARATOR: ' ',
    SNAKE_SEPARATOR: '_',
    PREFIX_FOR_FIELD_SUFFIX: '_'
  } as const;

  const formatString = {
    toTitleCase: (str: string): string => 
      str.split(STRING_FORMATS.WORD_SEPARATOR)
         .map(capitalizeFirstLetter)
         .join(STRING_FORMATS.WORD_SEPARATOR),
         
    toSnakeCase: (str: string): string => 
      str.toLowerCase().replace(/\s+/g, STRING_FORMATS.SNAKE_SEPARATOR)
  };

  const { addSuffix, addPlaceholder } = settings;
  const label = formatString.toTitleCase(field.label);
  const domFieldBase: DOMFieldBase = {
    label,
    suffix: addSuffix ? `${STRING_FORMATS.PREFIX_FOR_FIELD_SUFFIX}${formatString.toSnakeCase(field.label)}` : '',
    placeholder: addPlaceholder ? (field.placeholder ?? label) : '',
    inputType: field.inputType
  };

  if (field.inputType === FieldType.Select) {
    return { 
      ...domFieldBase, 
      options: field.options 
    } as DOMSelectField;
  }
  
  return domFieldBase as DOMInputField;
};

/**
 * Generates DOM-compatible field configurations for a given set of device configurations.
 * Each field is processed and converted based on the provided settings (i.e., adding suffix or placeholder).
 * 
 * @template T - The type of field.
 * @param configs - A mapping of field configurations and optional settings for each device.
 * @returns A mapping of configuration names to their respective DOM field configurations.
 */
const generateDOMConfigurations = <T extends FieldValue>(
  configs: Record<string, FieldSettingsConfig<T>>,
): Record<string, DOMConfiguration[]> => 
  Object.fromEntries(
    Object.entries(configs).map(([key, [config, settings]]) => [
      key, 
      config.map(field => generateDOMField(field, settings)) //generate DOM config per field
    ])
  );

// Generating DOM configurations for different device types and sensor configurations
const domConfigurations = generateDOMConfigurations({
  'Climate_sensor': [NUMERIC_CONFIGURATIONS.climate],
  'Power_meter': [NUMERIC_CONFIGURATIONS.powerMeter],
  '2Numbers': [NUMERIC_CONFIGURATIONS.heartbeat],
  'Mes_Color_Packet': [NUMERIC_CONFIGURATIONS.mesColor],
  'Dc_Drive': [fieldConfigs.dcDrive],
  'Car_Controller': [fieldConfigs.carController, fieldDisplaySettings.noSuffixOrPlaceholder],
  'Vehicle_Controller': [fieldConfigs.vehicleController, fieldDisplaySettings.noSuffixOrPlaceholder],
  'Chemical_Reactor': [fieldConfigs.chemicalReactor],
});

export {
  deviceTypes,
  sensorTypes,
  domConfigurations,
}

export type {
  DeviceType,
  SensorType,
}
