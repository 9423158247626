import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardModule } from '../ide/containers/dashboard/dashboard.module';
import { routes } from './admin.routes';
import { ApplicationCreatorModule } from './container/application-creator/application-creator.module';
import { ApplicationModule } from './container/application/application.module';
import { BlockProgrammingModule } from './container/block-programming/block-programming.module';
import { CircuitDesignModule } from './container/circuit-design/circuit-design.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeviceModule } from './container/device/device.module';
import { AddDeviceLabModule } from './container/device/add-device-lab/add-device-lab.module';
import { SensorsListComponent } from './container/sensors-list/sensors-list.component';
import { SidePanelModule } from '../components/side-panel/side-panel.module';
import { EditApplicationModule } from './container/application/components/edit-application/edit-application.component';
import { CreateNewSensorComponent } from './container/sensors-list/create-new-sensor/create-new-sensor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditSensorComponent } from './container/sensors-list/edit-sensor/edit-sensor.component';
import { UsersSessionModule } from './container/users-session/users-session.module';
import { LabListModule } from './container/labs-list/lab-list.module';
import { PythonDirectComponent } from './container/python-direct/python-direct.component';
import { PythonDirectModule } from './container/python-direct/python-direct.module';
import { LoaderModule } from '../shared/components/loader/loader.module';


@NgModule({
  declarations: [
    SensorsListComponent,
    CreateNewSensorComponent,
    EditSensorComponent,
    ],
  imports: [
    CommonModule,
    DashboardModule,
    ApplicationModule,
    ApplicationCreatorModule,
    DeviceModule,
    AddDeviceLabModule,
    BlockProgrammingModule,
    CircuitDesignModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    SidePanelModule,
    EditApplicationModule,
    FormsModule,
    ReactiveFormsModule,
    UsersSessionModule,
    LabListModule,
    PythonDirectModule,
    LoaderModule
    ],
  exports: [
  ]
})
export class AdminModule { }
