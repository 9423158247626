<div class="grid grid-cols-1">
  <div class="p-2 flex flex-row justify-end items-center" *ngIf="!ieeeEvent">
    <span style="color:#0788FF" class="clickable-link font-semibold text-base" (click)="switchToPython()">Switch to Python Interface</span>
    <button class="grey-btn float-right custom-lg-btn" (click)="goBack()">Back</button>
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">{{['iot', 'aiot', 'iiot'].includes(moduleKey) ? "Save & Next" : "Save & Exit"}}</button>
      <button class="grey-btn float-right custom-lg-btn" (click)="onExit()">Exit</button>
  </div>
  <!-- <ng-template #ieeeContent>
    <div>
      <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">Save</button>
    </div>
  </ng-template> -->
  <div>
    <div class="relative">
      <div class="floating-button floating-button1">
        <button class="floating-button" (click)="switch()">
          SWITCH TO
          {{
            blockType?.toLowerCase() == this.codeName?.toLowerCase()
              ? this.blockName
              : this.codeName
          }}
        </button>
      </div>

      <div class="floating-button floating-button2">
        <button class="floating-button" (click)="save()">SAVE</button>
      </div>

      <!-- Action button like undo,redo, zoomIn, zoomOut Focus -->
      <div
        *ngIf="
          blockType?.toLowerCase() == this.codeName?.toLowerCase()
            ? false
            : true
        "
        class="floating-button py-2 box-1 action-buttons" style="right:20px">
        <button (click)="zoomIn($event)" class="wd-48">
          <img class="img" src="assets/img/blockly/zoom-in.svg" title="Zoom In" />
        </button>
        <hr />
        <button (click)="zoomOut($event)" class="wd-48">
          <img class="img" src="assets/img/blockly/zoom-out.svg" title="Zoom Out" />
        </button>
        <hr />
        <button (click)="focus()" class="wd-48">
          <img class="img" src="assets/img/blockly/recenter.svg" title="Focus" />
        </button>
        <hr />
        <button (click)="undo()" class="wd-48">
          <img class="img" src="assets/img/blockly/undo.svg" title="Undo" />
        </button>
        <hr />
        <button (click)="redo()" class="wd-48 ">
          <img class="img" src="assets/img/blockly/redo.svg" title="Redo" />
        </button>
        <hr />
        <button (click)="delete()" class="wd-48">
          <img class="img" src="assets/img/blockly/delete.svg" title="Delete" />
        </button>
      </div>

      <ng-container *ngIf="!['data_science', 'artificial_intelligence'].includes(moduleKey)">
        <div class="floating-button floating-button3">
          <button class="floating-button" (click)="copyFromOtherDiagram()">
            COPY FROM "{{ isBlockWithSimulator ? "without" : "with" }} mobile"
          </button>
        </div>

        <!-- <div class="floating-button floating-button4">
          <button class="floating-button">
            SWITCH to "{{isBlockWithSimulator ? 'without' : 'with'}} mobile"
          </button>
        </div> -->
      </ng-container>

      <app-blockly-section
        (emitXMLEvent)="saveXML($event)"
        [(switchType)]="blockType"
        [isAdmin]="isAdmin"
        [toggle]="true"
        [isAdmin]="isAdmin"
        [ieeeEvent]="ieeeEvent"
        (emitBlockState)="toggleBlockState($event)"
        [applicationIdentifier]="applicationIdentifier"
        [moduleKey]="moduleKey"
        [selectedApplication]="selectedApplication"
        [parentComponent]="'CreateBlockProgramComponent'"
        (getGeneratedCode)="getGeneratedCodeBlock($event)"
      ></app-blockly-section>
    </div>
    <app-modal [modalType]="'new_variable'"></app-modal>
  </div>
</div>