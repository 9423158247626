import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabUsersComponent } from './lab-users.component';
import { AddLabUsersComponent } from './add-lab-users/add-lab-users.component';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [LabUsersComponent, AddLabUsersComponent],
  imports: [
    CommonModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,

  ]
})
export class LabUsersModule { }
