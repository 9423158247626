import { Pipe, PipeTransform } from '@angular/core';
import { DOMConfiguration } from '../models/sensor';
import { FieldType } from '../constants/fieldTypes';

/**
 * Represents an item with device and sensor details.
 */
interface Item {
  name: string;
  deviceType: string;
  sensorType: string;
}

/**
 * Represents the context for a template, including item, sensor DOM configuration, and optional control panel values.
 */
interface TemplateContext {
  item: Item;
  sensorDOM: DOMConfiguration;
  controlPanelValues?: Record<string, any>;
}

/**
 * Represents the result of the transform function, including DOM type validity, DOM configurations, and template contexts.
 */
interface TransformResult {
  isDOMTypeValid: boolean;
  domConfigs: DOMConfiguration[];
  templateContexts: TemplateContext[];
}

/**
 * Angular pipe to process and render templates dynamically based on device DOM configurations.
 */
@Pipe({ name: 'domTemplateRenderer', pure: true })
export class DomTemplateRendererPipe implements PipeTransform {

  /**
   * Transforms the input item and DOM map into a structured result including validity, configurations, and contexts.
   * @param item The item to be processed.
   * @param deviceDOMMap A map of device types to DOM configurations.
   * @param controlPanelValues Optional control panel values.
   * @returns A structured result with validity, DOM configurations, and template contexts.
   */
  transform(item: Item, deviceDOMMap: Record<string, DOMConfiguration[]>, controlPanelValues?: Record<string, any>): TransformResult {
    if (!item || !deviceDOMMap) {
      return { isDOMTypeValid: false, domConfigs: [], templateContexts: [] };
    }

    const isDOMTypeValid: boolean = deviceDOMMap 
      ? deviceDOMMap.hasOwnProperty(item.deviceType) 
      : false;

    const domConfigs: DOMConfiguration[] = deviceDOMMap?.[item?.deviceType] ?? [];

    const templateContexts: TemplateContext[] = domConfigs.map(sensorDOM => {
      const context: TemplateContext = { 
        item, 
        sensorDOM 
      };
      const inputType: FieldType = sensorDOM.inputType;
      
      if (inputType !== FieldType.Select) {
        context.controlPanelValues = controlPanelValues;
      }
      
      return context;
    });


    return { isDOMTypeValid, domConfigs, templateContexts };
  }
}
