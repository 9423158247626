import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { IDEService } from 'src/app/shared/services/ide.service';
import { TimeTrackingService } from "src/app/shared/services/time-tracking.service";

@Component({
  selector: 'app-python-direct',
  templateUrl: './python-direct.component.html',
  styleUrls: ['./python-direct.component.scss'],
})
export class PythonDirectComponent implements OnInit, OnChanges {
  @Output() onExitPressed = new EventEmitter();
  @Output() onUnsavedChanges = new EventEmitter();
  @Output() onSavePressed = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();
  @Output() onPopupOpen = new EventEmitter();
  @Output() refreshApplication = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Output() loader = new EventEmitter();

  @Input() userName: any;
  @Input() ieeeEvent: any;
  @Input() isCreateApplication: any;
  @Input() blockGeneratedCode: any;
  @Input() applicationIdentifier: any;
  @Input() ieeeSave!: boolean;
  @Input() moduleKey: any;
  @Input() isAdmin: any;
  @Input() selectedApplication: any;
  @Input() isSaveAndExitInitiated: boolean = false;
  @Input() isSimulatorBack: boolean = false;

  codeGenetared: any;
  onClose: any;
  isCollapsed: boolean = true;
  isDataScience: boolean = false;
  isArtificialIntelligence: boolean = false;
  isBack: boolean = false;
  currentCode: any;
  isSaveAndExit: boolean = false;
  isBackSimulator: boolean = false;

  constructor(
    private applicationTemplateService: ApplicationService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private ideService: IDEService,
    private timeTrackingService: TimeTrackingService
  ) {}

  ngOnInit(): void {
    this.timeTrackingService.startTracking();
    if (['data_science', 'robotics', 'artificial_intelligence'].includes(this.moduleKey)) {
      this.isDataScience = true;
      this.isArtificialIntelligence = true;
    }
  }

  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isSaveAndExitInitiated?.currentValue) {
      const isSaveAndExitInitiated =
        changes?.isSaveAndExitInitiated?.currentValue;
      this.isSaveAndExit = isSaveAndExitInitiated;
      if (isSaveAndExitInitiated === true) {
        this.onSaveAndExit();
      }
    }
    if(changes?.isSimulatorBack?.currentValue){
      this.isBackSimulator=changes?.isSimulatorBack?.currentValue;
    }
    if(this.ieeeSave){
     this.save()
    }
  }

  isScreenCollaps(event: any) {
    this.isCollapsed = event;
    this.onClose = { onClose: this.isCollapsed };
  }

  generatedCode(event: any) {
    if (event.code != undefined) {
      this.codeGenetared = [event.code.trim()];
    } else {
      this.codeGenetared = [''];
    }
  }

  onExit() {
    let isUnsavedChangesPresent = this.checkUnsavedChanges();
    this.onUnsavedChanges.emit(isUnsavedChangesPresent);
    this.onExitPressed.emit({});
  }

  repair() {
    window.dispatchEvent(new Event('resize'));
  }

  checkUnsavedChanges(): boolean {
    const currentPythonInterfaceDefinition = this.codeGenetared;
    const savedPythonInterfaceDefinition = JSON.parse(
      this.selectedApplication.pythonInterfaceDefinition
    );
    if (
      currentPythonInterfaceDefinition.length == 0 ||
      savedPythonInterfaceDefinition.length == 0
    ) {
      return false;
    }

    const currentPythonCode = currentPythonInterfaceDefinition[0]?.trim();
    const savedPythonCode = savedPythonInterfaceDefinition[0]?.trim();

    const isUnsavedChangesPresent = savedPythonCode != currentPythonCode;
    return isUnsavedChangesPresent;
  }

  // get s3 url for python interface definition
  async getS3URL() {
    let payload: any = {};
    if (this.codeGenetared) {
      payload['pythonInterfaceDefinition'] = true;
    }
    let s3_urls: any;
    if(this.ieeeEvent){
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    }else{
      if (this.isAdmin) {
        s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
      } else {
        s3_urls = await this.applicationTemplateService.updateApplicationV2(payload, this.applicationIdentifier).toPromise();
      }
    }
    return s3_urls;
  }

  // update python code to s3
  async updatePythonCodeToS3(s3_urls: any) {
    const PythonInterfaceDefinition = JSON.stringify(this.codeGenetared);
    const blob = new Blob([PythonInterfaceDefinition], {
      type: 'text/x-python-script',
    });
    const file = new File([blob], 'python.py', {
      type: 'text/x-python-script',
    });
    this.toasterService.warning('Saving Python code...');
    if (s3_urls?.data?.pythonInterfaceDefinition) {
      const result = await this.ideService
        .uploadToS3(file, s3_urls.data.pythonInterfaceDefinition)
        .toPromise()
        .then(() => {
          this.toasterService.success('Python code saved');
          this.selectedApplication.pythonInterfaceDefinition = JSON.stringify(
            this.codeGenetared
          );
          this.refreshApplication.emit();
          this.loader.emit(false);
        })
        .catch((err: any) => {
          this.toasterService.error('Could not save python code.');
          console.error(err);
        });
        this.loader.emit(false);
      return result;
    }
  }

  onDrag(event: any) {
    this.repair();
  }

  // save python code
  async save() {
    this.loader.emit(true);
    let s3_urls: any = await this.getS3URL();
    await this.updatePythonCodeToS3(s3_urls);
    return s3_urls;
  }

  // Save python code & go to next step
  async onNext() {
    let s3_urls: any = await this.save();
    if (s3_urls) {
      this.onSavePressed.emit(s3_urls);
    }
  }

  // on back pressed
  goBack() {
    this.isBack = true;
    this.onBackPressed.emit();
    this.onBack.emit({ backType: 'python-back' });
  }

  async onSaveAndExit() {
    await this.save();
    await this.onExit();
  }

  switchToBlock() {
    this.onPopupOpen.emit({ data: 'switchToBlock' });
  }
}
