import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, ViewEncapsulation, Output, EventEmitter, Input } from "@angular/core";
import { JoyrideService } from "ngx-joyride";
import { ApplicationService } from "src/app/shared/services/application.service";
import { SensorService } from "src/app/shared/services/sensor.service";
import { environment } from "src/environments/environment.prod";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { CommonActions } from "src/app/shared/utils/helpers";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { sidePanelSelector } from "src/app/store/layout/layout.selector";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { map } from "rxjs/operators";
import { CircuitComparisonService } from "src/app/shared/services/circuit-comparison.service";
import { ToastrService } from "ngx-toastr";
import { UsersSessionModule } from "src/app/admin/container/users-session/users-session.module";
import { TimeTrackingService } from "src/app/shared/services/time-tracking.service";

declare var $: any;
@Component({
  selector: "app-circuit-builder",
  templateUrl: "./circuit-builder.component.html",
  styleUrls: ["./circuit-builder.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CircuitBuilderComponent implements OnInit {
  @ViewChild('mismatchPanel', { static: false }) ref!: ElementRef;

  blockData: any;
  draggedElement: any;
  goFullScreen: boolean = false;
  hasAppId: boolean = false;
  infoSteps: any;
  toturials: any;
  selectedNavMenu: any;
  isFullWorkspace: boolean = false;
  isFullCircuit: boolean = false;
  isMenuOpen: boolean = true;
  isUserCircuit: boolean = false;
  initial_steps: any = [];
  sensorInfo!: any;
  showSidePanel$ = this.store.select(sidePanelSelector);
  applicationIdentifier: string = "";
  circuitMismatches: string[] = [];
  areCircuitsMatched: boolean = true;
  showCircuitDifferences: boolean = false;
  isTemplate: boolean;
  isTutorialsDisabled: boolean = false;
  title: string = "Start Tour";
  @Input() ieeeEvent: string;
  @Output() loader = new EventEmitter();


  constructor(
    private applicationService: ApplicationService,
    private sensorService: SensorService,
    private readonly joyrideService: JoyrideService,
    private domSanitizer: DomSanitizer,
    private store: Store<IAppState>,
    private commonActions: CommonActions,
    private circuitComparisonService: CircuitComparisonService,
    private changeDetector: ChangeDetectorRef,
    private toasterService: ToastrService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
    private timeTrackingService: TimeTrackingService
  ) {
    this.isTemplate = window.location.href.includes('/template/');
  }

  async ngOnInit() {
    this.timeTrackingService.startTracking();
    this.hasAppId = !window.location.href.includes("-direct");
    this.isUserCircuit = window.location.href.includes("/application/circuit");
    if (this.hasAppId) {
      this.getTemplate();
    } else {
      this.getSensors();
    }
    this.circuitComparisonService.circuitMismatchesSubject.subscribe((data: string[]) => {
      this.circuitMismatches = data;
      if (this.circuitMismatches.length > 0) this.areCircuitsMatched = false; else this.areCircuitsMatched = true;
      // console.log("circuit mismatches updated", this.circuitMismatches);
    })
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  startTour() {
    let stepsName: any = [];
    this.infoSteps.map((value: any, index: number) => {
      if (value.type == "video") {
        stepsName = [...stepsName, `Step-video`]
      } else {
        stepsName = [...stepsName, `Step-${index + 1}`]
      }
    })
    console.log("steps", stepsName)
    this.joyrideService.startTour({
      steps: stepsName,
      themeColor: "#232023"
    });

  }

  isTourData: boolean = false;

  private async getTemplate() {
    this.applicationIdentifier = sessionStorage.getItem("app_id") as string;
    let template: any;
    if (this.isUserCircuit) {
      template = await this.applicationService
        .getApplication(this.applicationIdentifier)
        .toPromise();
      this.loader.emit(false);
    } else {
      template = await this.applicationService
        .getApplicationTemplateV3(this.applicationIdentifier);
      this.loader.emit(false);
    }
    if (template) {
      const application = template.Application;
      if (application.configurationDefinition && (application.configurationDefinition !== null || application.configurationDefinition !== "")) {
        // this.blockData = JSON.parse(JSON.stringify(application.configurationDefinition || {}));
        this.blockData = JSON.parse(application.configurationDefinition);
        console.log(JSON.parse(application.configurationDefinition));
        this.setReferenceCircuit();
      }
      this.toturials = application.tutorials;
      //Adding initial steps which are exists in application
      let tourData: any[] = [];
      (application.tourControls || []).forEach((item: any) => {
        if (item.page === 'Circuit Designer') {
          tourData.push({
            "type": "text",
            "value": item.displayedText,
            "location": item.controlVal === 'Components Bar' ? "mid-left" : 'mid',//item.controlVal==='Components Bar'? "mid-left":'mid'
            "direction": item.position
          })
         }
        });
        this.initial_steps.push(...tourData);
        this.setInfoSteps();
    }
  }

  //get step Id to show steps popup screen properly if type is video then show big screen in center
  getJoyId(i: number, step: any) {
    if (step.type != "video") {
      return `Step-${i + 1}`;
    } else {
      return `Step-video`;
    }
  }

  private setInfoSteps() {
    const { circuit: circuitSteps, default: basic } = this.toturials || {};
    const tutorial = this.commonActions.isEmpty(circuitSteps) ? basic : circuitSteps;
    const isTutorialPresent = this.commonActions.isEmpty(tutorial) ? true : false;
    if (isTutorialPresent) {
      this.initial_steps = this.initial_steps.filter((el: any) => el.type !== "video");
    } else {
      //checking if url include vimeo or youtube
      let tutorialUrl = tutorial;
      if (tutorialUrl.includes('youtube') || tutorialUrl.includes('vimeo')) {
        this.isTutorialsDisabled = true;
        // to check if url is from youtube 
        var url = tutorial.split('=');
        let videoUrl: any;
        let urlVideo: any;
        if (url[0] === 'https://www.youtube.com/watch?v') {
          videoUrl = 'https://www.youtube.com/embed/' + url[1];
          const tutorial_url = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrl);
          urlVideo = tutorial_url;
        } else {
          const tutorial_url = this.commonActions.getIframeUrl(tutorial);
          urlVideo = tutorial_url;
        }
        this.initial_steps.push({
          "type": "video",
          "value": "This is sample video",
          "location": "mid",
          "url": urlVideo,
          "direction": "center",
        });
      }
      else {
        this.isTutorialsDisabled = false;
      }
    }
    this.infoSteps = this.initial_steps;
    if (!this.infoSteps.length) {
      this.title = "Tutorials not available";
      this.isTutorialsDisabled = true;
    }
    else {
      this.isTutorialsDisabled = false;
    }
  }

  private async getSensors() {
    this.blockData = await this.sensorService.getAllSensors().toPromise();
    this.setReferenceCircuit();
  }

  setDraggedElement(element: any) {
    this.draggedElement = element;
    this.isMenuOpen = false;
  }

  toggleSideMenu(event: boolean): void {
    this.isMenuOpen = event;
    let menuOpenSize = window.innerWidth * 0.25;
    let menuCloseSize = window.innerWidth * 0.05;
    let draggable_diagram = $("#draggable-ref-diagram");
    let draggable_diagram_left_position = draggable_diagram.position().left;
    if (event == false) {
      if (draggable_diagram_left_position == menuOpenSize) {
        draggable_diagram.css({ left: menuCloseSize });
      }
    } else {
      if (draggable_diagram_left_position < menuOpenSize) {
        draggable_diagram.css({ left: menuOpenSize });
      }
    }
  }

  setWindowSize(event: any) {
    let draggable_diagram = $("#draggable-ref-diagram");
    if (event.areaName === "pre-build") {
      if (event.action === "maximize") {
        this.isFullCircuit = true;
      } else {
        this.isFullCircuit = false;
      }
    } else {
      if (event.action === "maximize") {
        this.isFullWorkspace = true;
        if (this.hasAppId) {
          $("#draggable-ref-diagram").draggable({ containment: "parent" });
          $("#draggable-ref-diagram").resizable({ containment: "parent" });
          draggable_diagram.css({
            display: 'block',
            alignItems: 'center'
          });

          console.log(this.hasAppId)
        }
      } else {
        this.isFullWorkspace = false;
        draggable_diagram.css({ display: 'none' });
      }
    }
  }

  viewInformation(sensorData: any) {
    if (event) {
      this.sensorInfo = sensorData;
      this.store.dispatch(showSidePanel({ value: true }));
    }
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
    }
  }

  circuitUpdated(event: any) {
    this.setWorkareaCircuit(event);
  }

  setReferenceCircuit() {
    if (this.blockData.circuitDefinition) {
      this.circuitComparisonService.referenceCircuit = this.blockData.circuitDefinition;
    }
  }

  setWorkareaCircuit(circuit: any) {
    if (!circuit) return;
    this.circuitComparisonService.workareaCircuit = circuit;
    this.compareCircuits();
  }

  compareCircuits() {
    this.circuitComparisonService.compareCircuits();
  }

  openCircuitDifferencesPanel() {
    if (this.areCircuitsMatched)
      this.toasterService.success("There are no differences found between the reference circuit and work circuit");
    else this.showCircuitDifferences = true;
    this.changeDetector.detectChanges();
  }

  closeCircuitDifferencesPanel() {
    this.showCircuitDifferences = false;
    this.changeDetector.detectChanges();
  }

  renderMismatchedListItems() {
    let counter = 1;
    let list = document.getElementById("mismatchList") as HTMLElement;
    this.circuitMismatches.forEach((item) => {
      var line = document.createElement('p');
      line.className = "circuit-diff-lines";

      var div = document.createElement("div");
      div.className = "round-counters";

      var span_counter = document.createElement("span");
      span_counter.innerText = counter.toString();
      div.appendChild(span_counter);

      var span_text = document.createElement("span");
      span_text.innerText = item;

      line.appendChild(div);
      line.appendChild(span_text);

      list.appendChild(line);
      counter++;
    })
  }
}
