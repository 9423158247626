import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { OrganizationsService, Organization } from 'src/app/shared/services/organizations.service';
import { DeviceSessionsService } from 'src/app/shared/services/device-sessions.service';
import {
  DeviceSessionsList,
  SearchDeviceSessions
} from "src/app/shared/models/device-sessions";
import { PAGE_NUMBER, PAGE_SIZE, DESC, ASC } from "src/app/shared/constants/utils.constants"
import { PopupActionTypes } from "./action-popup/action-popup.component";
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { ToastrService } from 'ngx-toastr';
import { TimeTrackingService } from "src/app/shared/services/time-tracking.service";

const SortByTypes = {
  DEVICE_NAME: "device_name",
  DEVICE_TOKEN: "device_token",
  LAB_NAME: "lab_name",
  ORG_NAME: "organization_name",
  CREATED_BY: "created_by",
  TIME_LEFT: "time_left"
}

interface SearchParams {
  sortBy?: string,
  sortOrder?: string,
  pageNumber?: number,
  pageSize?: number,
}

@Component({
  selector: 'app-device-session',
  templateUrl: './device-session.component.html',
  styleUrls: ['./device-session.component.scss']
})
export class DeviceSessionComponent implements OnInit {

  isDevice: boolean = true;
  filteredDeviceSessions: DeviceSessionsList[] = [];
  isAction: boolean = false;
  actionType!: PopupActionTypes;
  sessionData!: DeviceSessionsList;
  searchString = new FormControl(null);
  orgName = new FormControl(null);
  availableDevicesOnly = new FormControl(false);
  appliedSearchString!: string;
  appliedOrgName!: string | null;
  userName: string;
  appliedCheckboxFilter!: boolean;
  currentOrganization!: string;
  orgList: Organization[] = [];
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  
  constructor(
    private orgService: OrganizationsService,
    private deviceSessionsService: DeviceSessionsService,
    private sessionStorageService: SessionStorageService,
    private userSessionService: UserSessionService,
    private toasterService: ToastrService,
    private timeTrackingService: TimeTrackingService
  ) {
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization !== environment.organizationID) {
      this.isGrokCloudMember = false;
      this.appliedOrgName = this.currentOrganization;
    }
  }

  async ngOnInit() {
    this.timeTrackingService.startTracking();
    this.userName = this.sessionStorageService.getUsername()
    this.searchOrganizations();
    this.refresh();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  refresh() {
    this.searchDeviceSessions({});
  }

  async searchOrganizations() {
    try {
      const result: any = await this.orgService.search().toPromise();
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async searchDeviceSessions(searchParams: SearchParams) {
    try {
      const { sortBy, sortOrder, pageNumber, pageSize } = searchParams;

      const payload: SearchDeviceSessions = {
        userOrgName: this.sessionStorageService.getCurrentOrganization(),
        searchOrgName: this.appliedOrgName ? this.appliedOrgName : null,
        userName: this.userName || '',
        pageNumber: pageNumber ? pageNumber : PAGE_NUMBER,
        pageSize: pageSize ? pageSize : PAGE_SIZE,
        searchString: this.appliedSearchString || null,
        availableDevices: this.appliedCheckboxFilter,
        isSessionsQuery: true,
        orderBy: [{
          sortBy: sortBy ? sortBy : SortByTypes.DEVICE_NAME,
          sortOrder: sortOrder ? sortOrder : DESC
        }]
      }
      const result: any = await this.deviceSessionsService.search(payload).toPromise();
      if (result && result?.success && result?.devices?.length > 0) {
        this.isDevice = true;
        this.filteredDeviceSessions = result?.devices;
      } else {
        this.filteredDeviceSessions = []
      }
    } catch (error:any) {
      console.log(error);
      this.filteredDeviceSessions = [];
      this.toasterService.error(error.error.message);
      
    }
  }

  toggleAvailableDevicesCheckbox() {
    this.appliedCheckboxFilter = !this.availableDevicesOnly.value;
    this.refresh();
  }

  applyTextFilter(event: Event) {
    this.appliedSearchString = this.searchString.value;
    this.refresh();
  }

  applyOrganizationFilter(event: any) {
    if (event.target.value != "") {
      this.appliedOrgName = event.target.value;
    } else {
      this.appliedOrgName = null;
    }
    this.refresh();
  }

  openModal(item: DeviceSessionsList, name: string) {
    this.isAction = true;
    this.actionType = name as PopupActionTypes;
    this.sessionData = item;
  }

  closeModal(event: any) {
    this.isAction = false;
    this.refresh();
  }

  isDeviceName: boolean = true;
  changeOrdDeviceName(colName: any, sortOrder:any) {
    if (colName == "deviceName" && sortOrder == "ASC") {
    this.filteredDeviceSessions.sort((a:any,b:any) => a.deviceName.localeCompare(b.deviceName));
    this.isDeviceName = !this.isDeviceName;
    }
    else if (colName == "deviceName" && sortOrder == "DESC") {
      this.filteredDeviceSessions.sort((a:any,b:any) => b.deviceName.localeCompare(a.deviceName));
      this.isDeviceName = !this.isDeviceName;
    }
  }

  isLabName: boolean = true;
  changeOrdLabName(colName: any, sortOrder:any) {
    if (colName == "labName" && sortOrder == "ASC") {
    this.filteredDeviceSessions.sort((a:any,b:any) => a.labName.localeCompare(b.labName));
    this.isLabName = !this.isLabName;
    }
    else if (colName == "labName" && sortOrder == "DESC") {
      this.filteredDeviceSessions.sort((a:any,b:any) => b.labName.localeCompare(a.labName));
      this.isLabName = !this.isLabName;
    }
  }

  isOrgName: boolean = true;
  changeOrdOrgName(colName: any, sortOrder:any) {
    if (colName == "orgName" && sortOrder == "ASC") {
    this.filteredDeviceSessions.sort((a:any,b:any) => a.orgName.localeCompare(b.orgName));
    this.isOrgName = !this.isOrgName;
    }
    else if (colName == "orgName" && sortOrder == "DESC") {
      this.filteredDeviceSessions.sort((a:any,b:any) => b.orgName.localeCompare(a.orgName));
      this.isOrgName = !this.isOrgName;
    }
  }

  isAssociatedUser: boolean = true;
  changeOrdAssosiatedUser(colName: any, sortOrder:any) {
    if (colName == "associatedUser" && sortOrder == "ASC") {
    this.filteredDeviceSessions.sort((a:any,b:any) => (a.associatedUser || "").localeCompare(b.associatedUser || ""));
    this.isAssociatedUser = !this.isAssociatedUser;
    }
    else if (colName == "associatedUser" && sortOrder == "DESC") {
      this.filteredDeviceSessions.sort((a:any,b:any) => (b.associatedUser || "").localeCompare(a.associatedUser || ""));
      this.isAssociatedUser = !this.isAssociatedUser;
    }
  }
  isTimeLeft: boolean = true;
  changeOrdTimeLeft(colName: any, sortOrder:any) {
    if (colName == "timeLeft" && sortOrder == "ASC") {
    this.filteredDeviceSessions.sort((a:any,b:any) => a.timeLeft - b.timeLeft);
    this.isTimeLeft = !this.isTimeLeft;
    }
    else if (colName == "timeLeft" && sortOrder == "DESC") {
      this.filteredDeviceSessions.sort((a:any,b:any) => b.timeLeft - a.timeLeft);
      this.isTimeLeft = !this.isTimeLeft;
    }
  }
}
