<div class="p-8">
  <div class="flex mr-2 justify-end">
    <!-- module name dropdown button start -->
    <div class="my-6 text-right relative">
      <button class="bg-greenish-blue text-white p-2 rounded-lg shadow-xl inline-flex items-center"
          (click)="toggleMenu()">Module Name
          <i class="p-4 m-2 arrow down text-white"></i>
        </button>
        <div *ngIf="isMenuOpen"
          class="origin-top-right absolute right-0 mt-2 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1 cursor-pointer">
            <a class="drop-down-links" (click)="onSelectModule('all')">All</a>
            <a class="drop-down-links" (click)="onSelectModule('iot')">IOT</a>
            <a class="drop-down-links" (click)="onSelectModule('robotics')">Robotics</a>
            <a class="drop-down-links" (click)="onSelectModule('aiot')">AIoT</a>
            <a class="drop-down-links" (click)="onSelectModule('artificialintelligence')">Artificial Intelligence</a>
            <a class="drop-down-links" (click)="onSelectModule('iiot')">IIoT</a>
          </div>
        </div>
    </div>
    <!-- module name dropdown button end -->
    <div class="my-6 text-right">
      <button class="bg-greenish-blue text-white p-2 rounded-lg shadow-xl" (click)="createNewSensor()">New
        Sensor</button>        
    </div>  
  </div>
  


  <div class="min-h-screen flex">
    <div class="overflow-x-auto w-full table-wrapper">
      <div class="min-w-screen min-h-screen flex justify-center font-sans">
        <div class="w-full">
          <div class="bg-white shadow-md rounded my-6">
            <table class="w-full table-auto">
              <ng-container *ngIf="isDeleteClicked">
                <div  class="w-full min-w-screen h-screen animated fadeIn faster fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
                  <div class="absolute bg-black opacity-80 inset-0 z-0  "></div>
                  <div style="height: 300px; width: 700px;" class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                    <!--content-->
                    <div class="">
                      <!--body-->
                      <div class="text-center p-5 flex-auto justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                              </svg>
                              <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
                                      <h2 class="text-xl font-bold py-4 ">Are you sure?</h2>
                                      <p class="text-sm text-gray-500 px-8">Do you really want to delete this item?
                              This process cannot be undone</p>    
                      </div>
                      <!--footer-->
                      <div class="p-3  mt-2 text-center space-x-4 md:block">
                          <button (click)="cancelDelete()" class="bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded-full">
                              Cancel
                          </button>
                          <button (click)="confirmAndDelete()" class="bg-blue-500 hover:bg-blue-700 text-red-500 font-bold py-2 px-4 rounded-full">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <thead>
                <tr class="text-white-600 uppercase text-sm leading-normal">
                  <!-- <th class="py-3 px-6 text-left">#</th> -->
                  <th class="py-3 px-6 text-left">Name</th>
                  <th class="py-3 px-6 text-left">Type</th>
                  <th class="py-3 px-6 text-left">Control Type</th>
                  <th class="py-3 px-6 text-left">Description</th>
                  <th class="py-3 px-6 text-left">Created By</th>
                  <th class="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light" id="tableBody">
                <ng-container *ngFor="let item of filteredSenorData">
                  <tr class="border-b border-gray-200 hover:bg-gray-100"
                    *ngFor="let item of item.components; let i = index">
                    <!-- <td class="py-3 px-6 text-left">
                                <span class="text-sm font-medium text-gray-900">{{i+1}}</span>
                              </td> -->
                    <td class="py-3 px-6 text-left">
                      <div class="text-sm font-medium text-blue-900 cursor-pointer" (click)="editSensor(item)">
                        {{item.title}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{item.id}}
                      </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                      <span class="text-sm font-medium text-gray-900">
                        {{item.sensorType}}
                      </span>
                    </td>
                    <td class="py-3 px-6 text-left">
                      <span class="text-sm font-medium text-gray-900">
                        {{item.deviceType}}
                      </span>
                    </td>
                    <td class="py-3 px-6 text-left">
                      <span class="text-sm text-gray-900">
                        {{(item.sensorDesc && item.sensorDesc.length > 50) ? (item.sensorDesc | slice:0:50)+'..' :
                        (item.sensorDesc)}}
                      </span>
                    </td>
                    <td class="py-3 px-6 text-left">
                      <div class="text-sm font-medium text-gray-900">
                        {{item.updatedBy}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{toDateTime(item.updatedDate)}}
                      </div>
                    </td>
                    <td class="py-3 px-6 text-center">
                      <div class="flex item-center justify-center">
                        <div class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                          (click)="editSensor(item)">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                          </svg>
                        </div>
                        <div class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" style="display:none;"
                          (click)="confirmAndDelete()">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="!isModal">
  <app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)">
    <div class="scrollable">
      <app-edit-application [application]="(selectedSensor)" [isEdit]="true" [isAdmin]="isAdmin"
        (emitCancelEvent)="sidePanelClosed($event)">
      </app-edit-application>
    </div>
  </app-side-panel>
</div>

<div *ngIf="isModal">
  <app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)" [isModal]="true" [boxWidth]="'w-2/6'">
    <div class="scrollable">
      <h4 class="p-4 text-md font-light">Application template <span class="px-4 text-md font-medium">"{{selectedSensor.applicationName}}"</span>will be deleted permanently.</h4>
      <p class="font-medium px-4 my-4">Are you sure?
      </p>
      <div class="p-4">
        <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" (click)="delete()">YES</button>
        <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button" (click)="sidePanelClosed($event)">CANCEL</button>
      </div>
    </div>
  </app-side-panel>
</div> -->
