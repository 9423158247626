<div class="grid grid-cols-1">
  <div class="p-2" *ngIf="!ieeeEvent">
    <button class="grey-btn float-right custom-lg-btn" (click)="onExit()">Exit</button>
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">Save & Next</button>
    <button class="grey-btn float-right custom-lg-btn" (click)="goBack()">Back</button>
  </div>
  <!-- <ng-template #ieeeContent>
    <div class="p-2">
      <button class="blue-btn float-right custom-lg-btn" (click)="save()">Save</button>
    </div>
  </ng-template> -->

  <div style="overflow: hidden;">
    <app-circuit-section [sensors]="sensors?.info" (emitSavedJSON)="onNext()" [konvaUtils]="konvaUtils"
      [isEdit]="isEdit" [isNewWorkspace]="isNewWorkspace" [applicationIdentifier]="applicationIdentifier"
      [applicationData]="applicationData">
    </app-circuit-section>
  </div>
</div>