<div [class.hidden]="switchType?.toLowerCase() === 'code'" class="w-full h-screen relative">
  <app-blockly [selectedApplication]="selectedApplication" [toolbox]="toolbox" [isAdmin]="isAdmin" [isBlock]="isBlock"
    [applicationIdentifier]="applicationIdentifier" (emitGeneratedCode)="onCode($event)"
    (emitXMLEvent)="emitXMLEvent.emit($event)" [isReadOnly]="false" [sizeChanged]="size" [ieeeEvent]="ieeeEvent"
    (isBlockWithSimulator)="toggleBlockState($event)" [parentComponent]="'BlocklySectionComponent'" [toggle]="toggle"
    *ngIf="toolbox">
  </app-blockly>
</div>


<div [class.h-screen]="isCompiled">
  <as-split direction="vertical" [gutterSize]="5">
    <as-split-area [size]="isCollasped?70:97">
      <!-- For showing python code  -->
    <div class="bg-ide-blue p-4 relative" [class.hidden]="switchType?.toLowerCase() === 'block'">
      <div class="min-h-screen">
        <div class="flex flex-col">
          <div class="flex flex-row">
            <div *ngIf="isRPIConnected" class="flex items-center">
              <p class="text-white mr-2">Language Code</p>
              <button class="font-light text-xxxs text-white px-1 flex items-center justify-end  rounded-lg"
                [class.bg-red-400]="!isDeviceOnline" [class.bg-green-400]="isDeviceOnline" (click)="getConnectedDevice()">
                <span class="rounded-full p-1 mr-2" [class.bg-red-100]="!isDeviceOnline"
                  [class.bg-green-100]="isDeviceOnline"></span>
                {{isDeviceOnline ? 'Online': 'Offline'}}
              </button>
              <button class="ml-2 font-light text-xxxs text-white px-1 flex items-center justify-end  rounded-lg"
                [class.bg-red-400]="!isDeviceOnline" [class.bg-green-400]="isDeviceOnline">
                <span class="rounded-full p-1 mr-2" [class.bg-red-100]="!isDeviceOnline"
                  [class.bg-green-100]="isDeviceOnline"></span>
                {{hostName}}
              </button>
            </div>
          </div>
          <div class="flex flex-row retry-margin mb-5">
            <button (click)="retryConnection()" class="ml-2 text-xxxs  px-1 flex items-center justify-end  rounded-lg">
              <img [hidden]="isDeviceOnline || isAdmin || !isRPIConnected" src="assets/img/icons/retry.svg" title="Retry" >
              <p [hidden]="isDeviceOnline || isAdmin || !isRPIConnected" class = "retry">&nbsp;Retry </p>
            </button>
          </div>
        </div>
        <div class="code-block text-yellow-500 text-xs">
          <pre *ngFor="let code of generatedCode_list">{{ code }}</pre>
        </div>
      </div>
    </div>
      <!-- Download Button  -->
      <div class="fixed download-btn text-white font-md right-10" [class.hidden]="switchType?.toLowerCase() === 'block'"
        [class.block-btn]="isBlock">
        <div class="relative inline-block text-left">
          <div>
            <button [disabled]="!isCodePresent" type="button" (click)="showOptions(!showSelectOptions)"
              [class]="!isCodePresent ? buttonInActive : buttonActive"
              class="inline-flex justify-center w-full rounded-lg px-4 py-2 bg-button text-md font-medium text-white focus:outline-none">
              <div class="flex items-center">
                Download
              </div>
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <div *ngIf="showSelectOptions" style="z-index: 60;"
            class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div *ngFor="let item of downloadOptions">
              <div class="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-button"
                (click)="verifyAndDownloadPart1(item.key)">{{ item.display }}</div>
            </div>
          </div>
        </div>
      </div>
    </as-split-area>
    <!-- for showing output screen start-->
    <div *ngIf="isCompiled" style="background-color: rgba(0, 57, 69, var(--tw-bg-opacity));" class="text-white bg-button">
      <button style="z-index:9000;" (click)="onCollasped()" class="flex bg-button arrow">
        <img class="mt-1 arrow-img" src="assets/img/python-direct/down-arrow.svg" [class.tf-90]="!isCollasped">
      </button>
    </div>
    <as-split-area [size]="isCollasped?30:3" [minSize]="25" [maxSize]="75" *ngIf="isCompiled">
      <!-- *ngIf="isCompiled  && !isTemplate" -->
      <div class="bg-ide-blue">
        <app-output-screen (isCollaspedEvent)="isScreenCollaps($event)" [codeGenetared]="generatedCode_list"></app-output-screen>
      </div>
    </as-split-area>
  </as-split>
  <!-- for showing output screen end-->
</div>


<!-- For verify and download -->
<app-side-panel *ngIf="(showSidePanel$ | async)" (emitCloseEvent)="sidePanelClosed($event)" [isModal]="true">
  <div class="scrollable">
    <h4 class="p-4 text-md font-medium">Block diagram does not match with the left diagram.</h4>
    <p class="font-light px-4 my-4">Do you still want to download the code?</p>
    <div class="p-4">
      <button class="bg-button text-white p-2 rounded-md shadow font-bold mr-2" (click)="downloadCode()">YES</button>
      <button class="text-gray-600 p-2 rounded-md font-bold mr-2" type="button"
        (click)="cancelDownload()">CANCEL</button>
    </div>
  </div>
</app-side-panel>