import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  DeviceType, deviceTypes, SensorType, sensorTypes } from 'src/app/shared/constants/sensors';
import { ISensor } from 'src/app/shared/models/sensor';
import { SensorService } from 'src/app/shared/services/sensor.service';

@Component({
  selector: 'app-create-new-sensor',
  templateUrl: './create-new-sensor.component.html',
  styleUrls: ['./create-new-sensor.component.scss'],
})
export class CreateNewSensorComponent implements OnInit {
  sensorSaved: boolean;
  sensorFormData: any;
  readonly sensorTypes: SensorType[] = [...sensorTypes];
  readonly deviceTypes: DeviceType[] = [...deviceTypes];

  moduleTypes: any[] = [
    { name: 'Internet of Things', value:'iot',selected: true },
    { name: 'Robotics',value:'robotics', selected: true },
    { name: 'AIoT',value:'aiot', selected: true },
    { name: 'IIoT',value:'iiot', selected: true }
  ];

  selectedModule: any[] = [];

  constructor(private sensorService: SensorService, private fb: FormBuilder) {
    this.sensorSaved = false;
  }

  ngOnInit(): void {
    this.sensorFormData = this.fb.group({
      sensorName: ['', Validators.required],
      sensorDesc: ['', Validators.required],
      sensorType: ['', Validators.required],
      deviceType: [''],
      sensorImageURL: ['', Validators.required],
      isActive: [''],
      sensorBlockScript: [''],
      sensorImportScript: [''],
      sensorCodeGenScript: [''],
      createdBy: [sessionStorage.getItem('username')],
      updatedBy: '',
      boardIdentifier: 'ba2f7b9e-3fda-4332-be1e-6b71b4a9d050',
      metadata: '',
      moduleType: [''],
    });
    // for module type select all or one module
    this.selectedModule = [this.moduleTypes[0]];
    this.selectAllForDropdownItems(this.moduleTypes);
  }

  createSensor() {
    let sensorData = this.sensorFormData.value;
    this.sensorService.create(sensorData).subscribe((res:any) => {
      if(res['success']==true){
        this.sensorSaved=true;
      }
    });
  }

  selectAllForDropdownItems(items: any) {
    let allSelect = (items: any) => {
      items.forEach((element: any) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
