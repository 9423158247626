import Konva from "konva";
import { Action, Store } from "@ngrx/store";
import { IAppState } from "../../store/index.state";
import circuitDesignActions from "../../store/circuit-design/c-d.actions";
import { environment } from "src/environments/environment";
import { Subject } from 'rxjs';
import { CircuitDesignState, Target, Wire } from "src/app/store/circuit-design/c-d.state";
import { BlankCircuitChildren } from "../constants/circuits";

export enum CircuitLoadOptions {
  READ_ONLY = "read_only",
  WRITABLE = "writable",
  DRAGGABLE = "draggable"
}

declare var $: any;
/**
 * Service class to hold helper method required by Konva to render our canvas.
 */

interface CurrentShape {
  shape?: any;
  shapeId?: string;
  connectorId?: string;
}
export class KonvaUtils {
  stage!: Konva.Stage;
  container!: any;
  private layer!: Konva.Layer;
  targets: any = [];
  wires: any = [];
  // children: any = {};
  stage_copy: any;
  currentSensorId!: string;
  sensorsOnWorkspace: any = [];
  allSensorsList: any = [];
  // pinsInUse: any[] = [];
  allPins: any[] = [];
  localStorageKey!: string;
  alternateKey!: string;
  applicationId!: string;
  tempStage = new Subject();

  // Variables for creating overlays
  new_overlays: any = [];
  isSelectingPins: boolean = false;

  // For Sensor custom name
  private sensorCustomNames: any = {};
  private sensorCustomNamesList: String[] = [];

  private transformers: Konva.Transformer[] = [];
  private minScale: number = 0.15;
  private maxScale: number = 3;
  private scaleChange: number = 1.2;
  currentShape: CurrentShape = {
    shape: "",
    shapeId: "",
    connectorId: "",
  };
  isCurrentShapeWire = false
  isKeyStrokeEvent: boolean = false;
  private activeComponent: any = null;
  private copiedComponent: any = null;
  private readonly wireColors = [
    // "red",
    "orange",
    "green",
    "cyan",
    "magenta",
    "purple",
    // "gray",
  ];
  readonly menuIdList: string[] = ["menu", "arrange-menu", "change-color-menu", "connect-to-menu", "connect-pin-menu"];

  private height;
  private width;
  undoableData: any = {
    pinsInUse: [],
    children: {}
  };

  constructor(private store: Store<IAppState>, applicationId: string | null, calledFrom: string) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    this.setLocalStorageKey(applicationId, calledFrom);
  }

  private setAlternateKey() {
    this.alternateKey = this.localStorageKey.replace("edit", "create");
  }

  setLocalStorageKey(applicationId: string | null, calledFrom: string): void {
    // this.consoleLog("Konva Utils loaded from: " + calledFrom + " where route: " + window.location.href);
    
    const isCreate = window.location.href.includes("/create");
    const isEdit = window.location.href.includes("/edit/");
    const isWorkarea = window.location.href.includes("/ide/template/circuit") || window.location.href.includes("/ide/application/circuit");
    const isAdmin = window.location.href.includes('/admin/') || window.location.href.includes("/ieee/");
    const isPrebuild = calledFrom.toLowerCase().includes('prebuild');

    const keyPrefix = "konvaCircuitJson";
    const platformType = isAdmin ? "admin" : "ide";

    if (applicationId) {
      this.applicationId = applicationId;
      let keyType = 'unknown';

      if (isEdit) { keyType = "edit"; } 
      else if (isCreate) { keyType = "create"; } //create flow is triggered just once; upon exiting midway we will enter edit flow
      else if (isWorkarea) { keyType = "workarea"; }

      this.localStorageKey = `${keyPrefix}-${platformType}-${keyType}-${this.applicationId}`;
    } else if (!isPrebuild) {

      this.localStorageKey = `${keyPrefix}-${platformType}-direct`;
    }

    if (isEdit) { this.setAlternateKey(); }
    // this.consoleLog("localStorageKey " + this.localStorageKey);
  }

  initStage(calledFrom: string, width?: number, height?: number): void {
    // this.consoleLog("init stage called from: " + calledFrom);
    this.resetVariables();
    let tempStage = this.restoreStageFromLocalStorage();
    if (tempStage) {
      this.loadExisting(tempStage, CircuitLoadOptions.WRITABLE, KonvaUtils.name, false);
    } else {
      this.loadNew();
    }
  }

  /**
   * Method to create the layer on the screen.
   */
  createLayer(): void {
    if (!this.layer) {
      this.layer = new Konva.Layer();
      this.stage?.add(this.layer);
    }
  }
  /**
   * Method to Add the contextMenu on the target Clicked in workspace
   */
  showContextMenu(): void {
    const component = this;
    const stage: any = this.stage;
    const container = this.container;
    container?.addEventListener("click", () => {
      // hide menu
      this.hideContextMenu();
    });
    stage?.on("contextmenu", function (e: any) {
      if (e.target === stage) {
        // if we are on empty place of the stage show
        return;
      }
      component.currentSensorId = e.target.attrs.sensorId;
      // update the currentShape object
      component.currentShape.shape = e.target;
      component.currentShape.shapeId =
        e.target.attrs.name === "component"
          ? e.target.attrs.id
          : e.target.attrs.wid;
      component.currentShape.connectorId = e.target.attrs.cid;
      component.setContextMenuPosition(stage.getPointerPosition());
      // for wire color menu option
      component.currentShape.shape.attrs.name === "wire"
        ? component.isCurrentShapeWire = true
        : component.isCurrentShapeWire = false
    });
  }

  private setContextMenuPosition(pos: any): void {
    const { x, y } = pos;
    const stageYmax = this.stage.height();
    const stageXmax = this.stage.width();
    const navbarHeight = $(".navbar").height();
    const rowHeight = 32;
    const menuNodeWidth = 200;
    const arrangeMenuNodeWidth = 160;
    const connectToMenuNodeWidth = 160;
    // console.log(x, y, stageXmax);

    // Define Nodes
    let menuNode: any = document.getElementById("menu");
    let arrangeMenuNode: any = document.getElementById("arrange-menu");
    let changeColorMenuNode: any = document.getElementById("change-color-menu");
    let connectToMenuNode: any = document.getElementById("connect-to-menu");
    let connectPinMenuNode: any = document.getElementById("connect-pin-menu");


    // change position of arrange sub-menu
    const arrangeMenuPosition = 2;

    // change position of color sub-menu
    const changeColorMenuPosition = 2;

    // change position of connect-to sub-menu
    let connectToMenuPosition = 1;

    // change position of pin sub-menu
    const connectPinMenuPosition = 1;


    // Main menu position logic
    let menuNodeItems = $("#menu button").length;
    let arrangeMenuItems = $('#arrange-menu button').length;
    let connectToItems = $('#connect-to-menu button').length;
    let changeColorItems = $("#change-color-menu button").length;
    let connectToPinItems = $("#connect-pin-menu button").length;


    const menuNodeHeight = menuNodeItems * rowHeight;
    //set position for height
    if (pos.y + menuNodeHeight + navbarHeight + rowHeight > stageYmax) {

      menuNode.style.top = (y - menuNodeHeight) + "px";
      arrangeMenuNode.style.top = y - (rowHeight * (arrangeMenuItems + 1)) + "px";
      connectToMenuNode.style.top = y - (rowHeight * (connectToItems + 2)) + "px";
      changeColorMenuNode.style.top = y - (rowHeight * (changeColorItems + 2)) + "px";
      connectPinMenuNode.style.top = y - (rowHeight * (connectToPinItems + 2)) + "px"

    } else {
      menuNode.style.top = y + "px";
      arrangeMenuNode.style.top = (y + (rowHeight * (arrangeMenuPosition - 1))) + "px";
      changeColorMenuNode.style.top = (y + (rowHeight * (changeColorMenuPosition - 1))) + "px";
      connectToMenuNode.style.top = (y + (rowHeight * (connectToMenuPosition - 1))) + "px";
      connectPinMenuNode.style.top = (y + (rowHeight * (connectPinMenuPosition - 1))) + "px";
    }
    //set position for width
    if (pos.x + menuNodeWidth + arrangeMenuNodeWidth > stageXmax) {
      menuNode.style.left = (x - menuNodeWidth) + "px";
      if (pos.x + 2 * menuNodeWidth + arrangeMenuNodeWidth > stageXmax) {
        arrangeMenuNode.style.left = (x - arrangeMenuNodeWidth - menuNodeWidth) + "px";
      }
      if (pos.x + 200 + menuNodeWidth > stageXmax) {
        connectToMenuNode.style.left = (x - (200 + menuNodeWidth)) + "px";
      }
      if (pos.x + 160 + menuNodeWidth > stageXmax) {
        changeColorMenuNode.style.left = (x - 160 - menuNodeWidth) + "px";
      }
      connectPinMenuNode.style.left = (x - menuNodeWidth - 160 - 160) + "px";
    } else {
      menuNode.style.left = x + "px";
      arrangeMenuNode.style.left = (x + menuNodeWidth) + "px";
      changeColorMenuNode.style.left = (x + menuNodeWidth) + "px";
      connectToMenuNode.style.left = (x + menuNodeWidth) + "px";
      connectPinMenuNode.style.left = (x + menuNodeWidth + connectToMenuNodeWidth) + "px";
    }
    menuNode.style.display = "initial";

    //arrange sub menu position logicconnectPinMenuNode
  }

  private selectPinFromPointer(): void {

    if (this.isSelectingPins) {
      let pos: any = this.stage.getPointerPosition();
      let x = pos.x - this.currentShape.shape.attrs.x;
      let y = pos.y - this.currentShape.shape.attrs.y;
      let msg = {
        "name": "GPIO",
        "display": "GPIO-" + Math.floor(Math.random() * 1000),
        "x": x,
        "y": y,
        "allowedPins": ["breadBoard", "Data"]
      };
      this.new_overlays.push(msg);
      // console.log("Double Clicked on ", { x: x, y: y });
    }
  }
  /**
   * Method to hide the context menu
   */
  hideContextMenu(): void {
    this.menuIdList.forEach(menu => {
      let node = document.getElementById(menu);
      node ? node.style.display = "none" : null;
    })
  }
  /**
   * Method to delete the currentShape.
   */
  deleteCurrentShape(event: any): void {
    const deleteBtn = document.getElementById("delete-button");
    const currentShape = this.currentShape;
    const layer = this.layer;
    const store = this.store;
    // check if it is the target by target-id
    if (currentShape.shapeId?.startsWith("t")) {
      // find all overlays on it and delete it too
      const overlays: any = layer
        .find("Circle")
        .toArray()
        .filter((el: any) => el.attrs.targetId === currentShape.shapeId);
      overlays.forEach((el: any) => {
        el.destroy();
      });
      const tags: any = layer
        .find("Label")
        .toArray()
        .filter((el: any) => el.attrs.targetId === currentShape.shapeId);
      tags.forEach((el: any) => {
        el.destroy();
      });
      const text: any = layer
        .find("Text")
        .toArray()
        .filter((el: any) => el.attrs.id === currentShape.shapeId);
      text.forEach((el: any) => {
        el.destroy();
      });
      // remove sensor on Workspace
      this.sensorsOnWorkspace = this.sensorsOnWorkspace.filter((el: any) => el.item.id !== this.currentSensorId);
      currentShape.shape.destroy();
    }
    // if it is a wire
    if (
      currentShape.shape.attrs.name ||
      currentShape.shapeId?.startsWith("w")
    ) {
      if (!currentShape.shape.image) {
        this.showTooltip(currentShape.shape, false)
      }
      this.deleteWholeWire();
    }
    this.hideContextMenu();
    layer.batchDraw();
    // delete the target
    this.setChildren();
    store.dispatch(
      circuitDesignActions.removeTarget({ payload: currentShape.shapeId, undoableData: this.undoableData }) as unknown as { type: string }
    );
    this.saveStageToLocalStorage(this.deleteCurrentShape.name);
  }
  /**
   * Method to delete anchors and the wire
   */
  private deleteWholeWire() {
    const currentShape = this.currentShape;
    const layer = this.layer;
    // find the 3 anchors from layer and delete
    const anchors: any = layer
      .find("Circle" && "Shape")
      .toArray()
      .filter((el: any) => el.attrs.wid === currentShape.shapeId);
    anchors.forEach((el: any) => {
      if (el.attrs.pinId) {
        this.removePinInUse(el.attrs.pinId);
      }
      el.destroy();
    });
    const store = this.store;
    this.setChildren();
    store.dispatch(
      circuitDesignActions.removeWire({ payload: currentShape.shapeId, undoableData: this.undoableData }) as unknown as { type: string }
    );
    this.saveStageToLocalStorage(this.deleteWholeWire.name);
  }
  /**
   * Method to move shape (up, down, top and bottom) on the layer
   */
  moveCurrentShape(direction: string): void {
    const currentShape = this.currentShape.shape.parent;
    switch (direction) {
      case "top":
        currentShape.moveToTop();
        break;
      case "bottom":
        currentShape.moveToBottom();
        break;
      case "up":
        currentShape.moveUp();
        break;
      case "down":
        currentShape.moveDown();
        break;
    }
    this.hideContextMenu();
    this.layer.batchDraw();
    this.saveStageToLocalStorage(this.moveCurrentShape.name);
  }

  /**
   * Method to refresh the complete Workspace
   */
  clearWorkspace(onDelete: boolean = false): void {
    this.resetVariables();
    this.stage.clear();
    if (onDelete === true) {
      this.removeAutoSavedCircuit();
    }
    this.initStage(this.clearWorkspace.name);
  }

  removeAutoSavedCircuit() {
    localStorage.removeItem(this.localStorageKey);
  }

  resetVariables() {
    this.sensorCustomNamesList = [];
    this.sensorCustomNames = {};
    this.sensorsOnWorkspace = [];
    this.activeComponent = null
    this.copiedComponent = null;
    this.currentSensorId = "";
    this.clearPinInUse();
    this.store.dispatch(circuitDesignActions.clearCanvas() as unknown as { type: string });
  }

  resetContainer() {
    document.getElementById("container")?.remove();
    // let element = document.createElement("div");
    // element.setAttribute('id', 'container');
    // let workspace = document.querySelector('[data-attribute-id="workspace"]') as HTMLElement;
    // workspace.appendChild(element);
  }

  /**
   * Method to accept image from outside the canvas.
   * TODO: check why it is creating duplicate when dragged internally.
   */
  dragFromOutside(element: any) {
    const component = this;
    this.container.addEventListener("dragover", function (e: any) {
      e.preventDefault(); // !important
      component.saveStageToLocalStorage(`${component.dragFromOutside.name}-${component.container.addEventListener.name}-dragover`);
    });
    
    this.container.addEventListener("drop", function (e: any) {
      e.preventDefault();
      component.sensorsOnWorkspace = [...component.sensorsOnWorkspace, ...element];
      // // now we need to find pointer position
      // // we can't use stage.getPointerPosition() here, because that event
      // // is not registered by Konva.Stage
      // // we can register it manually (with private method):
      component.stage?.setPointersPositions(e);

      // // now you can add a shape. We will add an image

      component.createImage(
        element,
        component.stage?.getPointerPosition()?.x,
        component.stage?.getPointerPosition()?.y
      );
      element = "";
      component.saveStageToLocalStorage(`${component.dragFromOutside.name}-${component.container.addEventListener.name}-drop`);
    });
  }

  updateAvailableSensorsList(sensorsList: any) {
    this.allSensorsList = sensorsList;
  }

  duplicate(element: any, isClickEvent?: boolean) {
    const component = this;
    let event: any;
    if (isClickEvent) {
      this.addSensorsOnWorkspaceAsync(element, event);
    }
  }

  addSensorsOnWorkspaceAsync(element: any, e: any) {
    const component = this;
    component.sensorsOnWorkspace = [...component.sensorsOnWorkspace, ...element];
    // push element in the sensors on Workspace

    // now we need to find pointer position
    // we can't use stage.getPointerPosition() here, because that event
    // is not registered by Konva.Stage
    // we can register it manually (with private method):
    component.stage?.setPointersPositions(e);

    // now you can add a shape. We will add an image

    component.createImageAsync(
      element,
      component.stage?.getPointerPosition()?.x,
      component.stage?.getPointerPosition()?.y
    );
    element = "";
    component.saveStageToLocalStorage(this.addSensorsOnWorkspaceAsync.name);
  }


  addSensorsOnWorkspace(element: any, e: any) {
    const component = this;
    // push element in the sensors on Workspace

    component.sensorsOnWorkspace = [...component.sensorsOnWorkspace, ...element];
    // now we need to find pointer position
    // we can't use stage.getPointerPosition() here, because that event
    // is not registered by Konva.Stage
    // we can register it manually (with private method):
    component.stage?.setPointersPositions(e);

    // now you can add a shape. We will add an image

    component.createImage(
      element,
      component.stage?.getPointerPosition()?.x,
      component.stage?.getPointerPosition()?.y
    );
    element = "";
    this.saveStageToLocalStorage(this.addSensorsOnWorkspace.name);
  }

  /**
   * for testing purposes
   */
  addSensorToWorkspace(element: any, e: any) {
    const component = this;
    component.sensorsOnWorkspace = [...component.sensorsOnWorkspace, ...element];
    const [global, layer, overlays] = [
      this,
      this.layer,
      element[0]?.item?.meta.pins || [],
    ];
    for (let i = 0; i < overlays.length; i++) {
      const pinName = overlays[i].display;
      const circle = new Konva.Circle({
        type: "overlay",
        id: this.uuidv4(),
        pinId: `${pinName}-${global.uuidv4()}`,
        name: overlays[i].name,
        display: overlays[i].display,
        x: 0 + overlays[i].x,
        y: 0 + overlays[i].y,
        group: overlays[i].group,
        allowedPins: overlays[i].allowedPins,
        radius: element[0].item.meta.pinRadius,
        fill: global.getPinColor(pinName),
        opacity: 0.65,
        targetId: 0,
      });
      // add overlay int the group
      this.allPins.push(circle);
    }
    element = "";
  }


  /**
   * Method to open the right-side Info-Panel of the Sensor.
   *
   */
  viewInformation(): void {
    let currentSensor: any = this.sensorsOnWorkspace.filter((el: any) => el.item.id === this.currentSensorId)[0];
    const sensorInfo: any = { _id: this.currentSensorId, name: currentSensor?.item?.title, desc: currentSensor?.item?.sensorDesc, image_url: currentSensor?.newElement };
    return sensorInfo;
  }

  getSensorId(): string {
    return this.currentSensorId;
  }
  /**
   * Method to add the header text on top of the layer.
   *
   * @deprecated
   */
  addHeaderText(): void {
    const simpleText = new Konva.Text({
      x: this.stage.width() / 1.7,
      y: 30,
      text: "Build your circuit here",
      fontSize: 24,
      fontStyle: "bold",
      fill: "black",
    });

    simpleText.offsetX(simpleText.width() / 2);
    this.layer.add(simpleText);
    this.stage.add(this.layer);
    this.saveStageToLocalStorage(this.addHeaderText.name);
  }

  /**
   * Method to get the original height and width of component/sensor from svg
   * @param url
   * @returns
   */
  getComponentSvgSize(url: string) {
    let img = new Image();
    img.src = url;
    return [img.width, img.height];

  }

  async getComponentSvgSizeAsync(url: string) {
    let img = await this.loadImage(url);
    return [img.width, img.height]
  }

  async loadImage(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = url
      return img;
    })

  }

  /**
   * Method to generate a unique id
   * @returns
   */
  private uuidv4() {
    return "xxxx-yxyx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  /**
   * Method responsible for creating an image inside the canvas board.
   * @returns
   */

  private createImage(element: any, x?: number, y?: number): void {
    // if no element then do nothing
    if (!element) {
      return;
    }
    const [global, layer, overlays] = [
      this,
      this.layer,
      element[0]?.item?.meta.pins || [],
    ];
    const sensorId = element[0].item?.id;
    let prevOverlays: any = [];
    let currentOverlays: any = [];
    overlays.forEach((ele: any) => {
      prevOverlays = [...prevOverlays, { x: 0, y: 0 }];
    });
    overlays.forEach((ele: any) => {
      currentOverlays = [...currentOverlays, { x: ele.x, y: ele.y }];
    });
    // else create a new target-object
    // generate new target-id
    const id = "t-" + global.uuidv4();
    // unique-id with the component name so that we can import duplicate components
    const deviceTempName = element[0].item?.title.replace(/ /g, "_");
    const uniqueID = `${deviceTempName}${global.uuidv4()}`;
    // push into array of targets
    const target = {
      t_id: id,
      controlType: element[0].item?.deviceType === "INPUT" ? element[0].item?.controlType : "",
      name: element[0].item?.title,
      customName: this.generateCustomNameLogic(element[0].item),
      customEditName: this.generateCustomEditNameLogic(element[0].item,id),
      sensorType: element[0].item?.sensorType,
      deviceType: element[0].item?.deviceType,
      prev: { x: 0, y: 0, scaleX: 0, scaleY: 0, overlays: prevOverlays },
      current: { x, y, scaleX: 1, scaleY: 1, overlays: currentOverlays },
      elementSrc: element[0].newElement,
      connectors: Array<any>(),
    };
    // set value of x and y accordingly
    // temporary size fix, later will be from db
    const [width, height] = global.getComponentSvgSize(element[0].newElement);
    // create image from url on the stage
    const group = new Konva.Group({
      id: "component-group",
      name: id,
    });
    Konva.Image.fromURL(element[0].newElement, function (darthNode: any) {
      darthNode.setAttrs({
        sensorId,
        title: uniqueID,
        name: "component",
        customName: target.customName,
        customEditName: target.customEditName,
        itemName: element[0].item?.item,
        controlType: element[0].item?.controlType || "",
        id,
        imageSrc: element[0].newElement,
        x: x || 200,
        y: y || 50,
        scaleX: 1,
        scaleY: 1,
        width,
        height,
        draggable: true,
      });
      // add component int the GROUP
      group.add(darthNode);
      // create the overlays on the image
      for (let i = 0; i < overlays.length; i++) {
        const pinName = overlays[i].display;
        const circle = new Konva.Circle({
          type: "overlay",
          id: uniqueID,
          pinId: `${pinName}-${global.uuidv4()}`,
          name: overlays[i].name,
          display: overlays[i].display,
          x: x + overlays[i].x,
          y: y + overlays[i].y,
          group: overlays[i].group,
          allowedPins: overlays[i].allowedPins,
          radius: element[0].item.meta.pinRadius,
          fill: global.getPinColor(pinName),
          opacity: 0.65,
          targetId: id,
        });
        // add overlay int the group
        group.add(circle);
      }

      // Add label for the image (customName)
      const [customName_x, customName_y] = [element[0].item.meta?.customName?.x, element[0].item.meta?.customName?.y];
      const labelX = customName_x || 10;
      const labelY = customName_y || -5;

      const labelX2 = 5;
      const labelY2 = -80;
      var textNode = new Konva.Text({
        x: darthNode.attrs.x + labelX2,
        y: darthNode.attrs.y + labelY2,
        text: target.customEditName,
        fontFamily: 'Calibri',
        fill: 'black',
        fontSize: 18,
        padding: 10,
        id: target.t_id
      });

      function writeMessage(message1:string,x:any,y:any) {
        //textNode.text(message1);
        textNode.x(x);
        textNode.y(y);
      }

      darthNode.on('dragstart', function () {
        writeMessage('dragstart', darthNode.attrs.x + labelX2, darthNode.attrs.y + labelY2);
      });

      darthNode.on('dragmove', function () {
        writeMessage('dragstart', darthNode.attrs.x + labelX2, darthNode.attrs.y + labelY2);
      });

      darthNode.on('dragend', function () {
        writeMessage('dragend', darthNode.attrs.x + labelX2, darthNode.attrs.y + labelY2);
      });

      textNode.on('dragstart', function () {
        //writeMessage('dragstart');
      });
      textNode.on('dragend', function () {
        //writeMessage('dragend');
      });

      let customNameLabel = global.createLabel(uniqueID, id, target.customName, darthNode.attrs.x + labelX, darthNode.attrs.y + labelY);
      group.add(customNameLabel);
      // add the group on the layer
      layer?.add(group);
      layer?.add(textNode);
      // Add TOOLTIP for the OVERLAYS
      const tooltip = global.createTooltip(layer);
      layer?.add(tooltip);
      layer?.batchDraw();
      global.saveStageToLocalStorage(global.createImage.name);

       var tr = new Konva.Transformer({
        enabledAnchors: ['middle-left', 'middle-right'],
        boundBoxFunc: function (oldBox, newBox) {
          newBox.width = Math.max(30, newBox.width);
          return newBox;
        },
      });

      textNode.on('transform', function () {
        textNode.setAttrs({
          width: textNode.width() * textNode.scaleX(),
          scaleX: 1,
        });
      });

      layer?.add(tr);

      textNode.on('dblclick dbltap', () => {
        // hide text node and transformer:
        textNode.hide();
        tr.hide();
        var textPosition = textNode.absolutePosition();
        var areaPosition = {
          x: textPosition.x,
          y: textPosition.y,
        };

        var textarea = document.createElement('textarea');
        let rotation;
        let scale;
        let appRoot = document.querySelector('.konvajs-content');
        appRoot?.appendChild(textarea);

        textarea.value = textNode.text();
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
        textarea.style.height =
        textNode.height() - textNode.padding() * 2 + 5 + 'px';
        textarea.style.fontSize = textNode.fontSize() + 'px';
        textarea.style.border = 'none';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        rotation = textNode.rotation();
        var transform = '';
        if (rotation) {
          transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';

        textarea.style.transform = transform;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 3 + 'px';
        textarea.focus();

        function removeTextarea() {
          textarea?.parentNode?.removeChild(textarea);
          window.removeEventListener('click', handleOutsideClick);
          textNode.show();
          tr.show();
          tr.forceUpdate();
        }

        function setTextareaWidth(newWidth:any) {
          var isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          var isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isSafari || isFirefox) {
            newWidth = Math.ceil(newWidth);
          }

          // @ts-ignore
          var isEdge = /Edge/.test(navigator.userAgent);
          if (isEdge) {
            newWidth += 1;
          }
          textarea.style.width = newWidth + 'px';
        }

        textarea.addEventListener('keydown', function (e) {
          if (e.keyCode === 13 && !e.shiftKey) {
            textNode.text(textarea.value);
            removeTextarea();
          }
          if (e.keyCode === 27) {
            removeTextarea();
          }
        });

        textarea.addEventListener('focusout', function (e) {
          textNode.text(textarea.value);
          global.updateNameTarget(target.t_id,textarea.value)
        });

        textarea.addEventListener('keydown', function (e) {
          scale = textNode.getAbsoluteScale().x;
          setTextareaWidth(textNode.width() * scale);
          textarea.style.height = 'auto';
          textarea.style.height =
          textarea.scrollHeight + textNode.fontSize() + 'px';
        });

        function handleOutsideClick(e :any) {
          if (e.target !== textarea) {
            textNode.text(textarea.value);
            global.updateNameTarget(target.t_id,textarea.value)
            removeTextarea();
          }
        }
        setTimeout(() => {
          window.addEventListener('click', handleOutsideClick);
        });
      });

      // Track target's previous and current position on stage on dragmove
      group.on("mouseenter", function () {
        global.stage.container().style.cursor = "pointer";
        global.saveStageToLocalStorage(`${global.createImage.name}-mouseenter`);
      });
      group.on("mouseleave", function () {
        global.stage.container().style.cursor = "default";
        global.saveStageToLocalStorage(`${global.createImage.name}-mouseleave`);
      });
      group.on("click", function (e: any) {
        global.duplicate(element, false);
        global.saveStageToLocalStorage(`${global.createImage.name}-click`);
      })
      // Track overlays on moving Component
      darthNode.on("dragmove dragend", (e: any) => {
        const doesUpdateStore = e?.type == "dragend" ? true : false;
        global.circuitImageDragEvent({ global, e, darthNode, labelX, labelY, doesUpdateStore });
      });
      global.registerComponentClickEvent(darthNode);

      global.setChildren();
      // add target-object in the store
      global.store.dispatch(
        circuitDesignActions.addTarget({ payload: target, undoableData: global.undoableData }) as unknown as { type: string }
      );
    });
  }

  private async createImageAsync(element: any, x?: number, y?: number): Promise<void> {
    // if no element then do nothing

    if (!element) {
      return;
    }
    const [global, layer, overlays] = [
      this,
      this.layer,
      element[0].item?.meta.pins || [],
    ];
    const sensorId = element[0].item?.id;
    let prevOverlays: any = [];
    let currentOverlays: any = [];
    overlays.forEach((ele: any) => {
      prevOverlays = [...prevOverlays, { x: 0, y: 0 }];
    });
    overlays.forEach((ele: any) => {
      currentOverlays = [...currentOverlays, { x: ele.x, y: ele.y }];
    });
    // else create a new target-object
    // generate new target-id
    const id = "t-" + global.uuidv4();
    // unique-id with the component name so that we can import duplicate components
    const deviceTempName = element[0].item?.title.replace(/ /g, "_");
    const uniqueID = `${deviceTempName}${global.uuidv4()}`;
    // push into array of targets
    const target = {
      t_id: id,
      controlType: element[0].item?.deviceType === "INPUT" ? element[0].item?.controlType : "",
      name: element[0].item?.title,
      customName: this.generateCustomNameLogic(element[0].item),
      customEditName: this.generateCustomEditNameLogic(element[0].item,id),
      sensorType: element[0].item?.sensorType,
      deviceType: element[0].item?.deviceType,
      prev: { x: 0, y: 0, scaleX: 0, scaleY: 0, overlays: prevOverlays },
      current: { x, y, scaleX: 1, scaleY: 1, overlays: currentOverlays },
      elementSrc: element[0].newElement,
      connectors: Array<any>(),
    };
    // set value of x and y accordingly
    // temporary size fix, later will be from db
    const [width, height] = await global.getComponentSvgSizeAsync(element[0].newElement);
    // create image from url on the stage
    const group = new Konva.Group({
      id: "component-group",
      name: id,
    });
    Konva.Image.fromURL(element[0].newElement, function (darthNode: any) {
      darthNode.setAttrs({
        sensorId,
        title: uniqueID,
        name: "component",
        customName: target.customName,
        itemName: element[0].item?.item,
        customEditName: target.customEditName,
        controlType: element[0].item?.controlType || "",
        id,
        imageSrc: element[0].newElement,
        x: x || 200,
        y: y || 50,
        scaleX: 1,
        scaleY: 1,
        width,
        height,
        draggable: true,
      });
      // add component int the GROUP
      group.add(darthNode);
      // create the overlays on the image
      for (let i = 0; i < overlays.length; i++) {
        const pinName = overlays[i].display;
        const circle = new Konva.Circle({
          type: "overlay",
          id: uniqueID,
          pinId: `${pinName}-${global.uuidv4()}`,
          name: overlays[i].name,
          display: overlays[i].display,
          x: x + overlays[i].x,
          y: y + overlays[i].y,
          group: overlays[i].group,
          allowedPins: overlays[i].allowedPins,
          radius: element[0].item.meta.pinRadius,
          fill: global.getPinColor(pinName),
          opacity: 0.65,
          targetId: id,
        });
        // add overlay int the group
        group.add(circle);

      }
      // Add label for the image (customName)

      const [customName_x, customName_y] = [element[0].item.meta?.customName?.x, element[0].item.meta?.customName?.y];
      const labelX = customName_x || 10;
      const labelY = customName_y || -5;
      let customNameLabel = global.createLabel(uniqueID, id, target.customName, darthNode.attrs.x + labelX, darthNode.attrs.y + labelY);
      group.add(customNameLabel);
      // add the group on the layer
      layer?.add(group);
      // Add TOOLTIP for the OVERLAYS
      const tooltip = global.createTooltip(layer);
      layer?.add(tooltip);
      layer?.batchDraw();
      global.saveStageToLocalStorage(global.createImageAsync.name);

      // Track target's previous and current position on stage on dragmove
      group.on("mouseenter", function () {
        global.stage.container().style.cursor = "pointer";
        global.saveStageToLocalStorage(`${global.createImageAsync.name}-mouseenter`);
      });
      group.on("mouseleave", function () {
        global.stage.container().style.cursor = "default";
        global.saveStageToLocalStorage(`${global.createImageAsync.name}-mouseleave`);
      });
      // Track overlays on moving Component
      darthNode.on("dragmove dragend", (e: any) => {
        console.log("dragmove dragend 1");
        const doesUpdateStore = e?.type == "dragend" ? true : false;
        global.circuitImageDragEvent({ global, e, darthNode, labelX, labelY, doesUpdateStore });
      });
      global.registerComponentClickEvent(darthNode);

      global.setChildren();
      // add target-object in the store
      global.store.dispatch(
        circuitDesignActions.addTarget({ payload: target, undoableData: global.undoableData }) as unknown as { type: string }
        );
    })
  }

  generateCustomNameString(sensorName: string) {
    return sensorName.replace(/ /g, "_") + "_" + String(this.sensorCustomNames[sensorName]);
  }

  generateCustomNameLogic(item: any) {
    let sensorName;
    if (item?.meta.type) {
      sensorName = item.meta.type;
    } else if (item?.title) {
      sensorName = item.title;
    } else {
      sensorName = "device";
    }
    sensorName = sensorName.toLowerCase();
    let customName;
    if (this.sensorCustomNames[sensorName]) {
      this.sensorCustomNames[sensorName] += 1;
    } else {
      this.sensorCustomNames[sensorName] = 1;
    }
    customName = this.generateCustomNameString(sensorName);
    while (this.sensorCustomNamesList.includes(customName)) {
      this.sensorCustomNames[sensorName] += 1;
      customName = this.generateCustomNameString(sensorName);
    }
    this.sensorCustomNamesList.push(customName);
    return customName;
  }

  generateCustomEditNameLogic(item: any,id:any) {
    let sensorName;
    if (item.meta.type) {
      sensorName = item.meta.type;
    } else if (item.title) {
      sensorName = item.title;
    } else {
      sensorName = "device";
    }
    sensorName = sensorName.toLowerCase();
    let customName = sensorName;
    return customName;
  }

  loadCustomNames(targets: any) {
    targets.forEach((shape: any) => {
      if (shape['customName']) {
        this.sensorCustomNamesList.push(shape['customName']);
      }
    });
  }

  createLabel(id: string, targetId: string, text: string, x: any, y: any): Konva.Label {
    // label with left pointer
    var labelLeft = new Konva.Label({
      type: "customName",
      id: id,
      x: x,
      y: y,
      opacity: 0.75,
      targetId: targetId,
    });

    labelLeft.add(
      new Konva.Tag({
        fill: 'white',
        pointerDirection: 'down',
        pointerWidth: 10,
        pointerHeight: 10,
        lineJoin: 'round',
        shadowColor: 'black',
        shadowBlur: 10,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowOpacity: 0.3,
      })
    );

    labelLeft.add(
      new Konva.Text({
        text: text,
        fontFamily: 'Calibri',
        fontSize: 18,
        padding: 10,
        fill: 'black',
      })
    );

    return labelLeft;
  }
  /**
   * Method to return the color of the pin
   */
  private getPinColor(pinName: any) {
    let color: string = "";
    switch (pinName) {
      case "Vcc":
      case "5V":
        color = "#FDCCCD";
        break;
      case "3V3":
        color = "#FFCB99";
        break;
      case "DAT":
      case "OUT":
        color = "#428DD9";
        break;
      case "GPIO3":
        color = "#FFCBFF";
        break;
      case "GND":
        color = "#545452";
        break;
      case "GPIO7":
      case "GPIO8":
      case "GPIO9":
      case "GPIO10":
      case "GPIO11":
        color = "#CFE6F4";
        break;
      case "ID_SD":
      case "ID_SC":
        color = "#FFFFC9";
        break;
      case "GPIO14":
      case "GPIO15":
        color = "#E6E6FD";
        break;
      case "Pin":
        color = "#B9B9B4";
        break;
      case "Anode (+)":
      case "Positive (+)":
        color = "#F84F4F";
        break;
      case "Cathode (-)":
      case "Negative (-)":
        color = "#A6A6A6";
        break;
      default:
        color = "#CDFFCC";
        break;
    }
    return color;
  }
  /**
   * Method to update target's position in the store on dragmove
   *
   */
  private updateTargetPosition(
    id: string,
    x: number,
    y: number,
    title?: string,
    currentOverlays?: any,
    doesUpdateStore?: boolean
  ) {

    // find the overlays attached to the particular target
    const overlays = this.layer.find("#" + title);

    if (currentOverlays) {
      // update new positions of all the overlays
      for (let i = 0; i < currentOverlays.length; i++) {
        const newX = x + currentOverlays[i].x;
        const newY = y + currentOverlays[i].y;
        overlays[i].x(newX);
        overlays[i].y(newY);
      }
    }
    this.setChildren();
    const actionMethod = doesUpdateStore === true ? circuitDesignActions.updateTarget : circuitDesignActions.updateDragTarget;
    // update the state in global-state-store
    // payload -> target-id, e.x() , e.y()
    this.store.dispatch(
      actionMethod({ payload: { id, x, y }, undoableData: this.undoableData }) as unknown as { type: string }
    );
    // this.saveStageToLocalStorage(this.updateTargetPosition.name);
    // call the method to update the connectors(wires) associated with target
    this.updateConnectedObjects(id, doesUpdateStore);
  }

  private updateNameTarget(
    id: string,
    customEditName? : any
  ) {
    this.setChildren();
    this.store.dispatch(
      circuitDesignActions.updateNameTarget({ payload: {  id, customEditName }, undoableData: this.undoableData }) as unknown as { type: string }
    );
  }

  // /**
  //  * Method to update new overlay coordinates with respect to the size-change of the component
  //  */
  // private updateNewOverlayCoordinates(initialValue:any) {
  //   const {id, newX, newY, height, width, title, scaleXChange, scaleYChange, oldOverlayValues} = initialValue;
  //   const [offsetWidth, offsetHeight]= [(width + scaleXChange * width),(height + scaleYChange * height)];
  //   const overlays = this.layer.find("#" + title);
  //   let currentOverlays: any = [];
  //   for(let i=0; i<oldOverlayValues.length; i++) {
  //     const newOverlayX = (oldOverlayValues[i].x / width) * offsetWidth;
  //     const newOverlayY = (oldOverlayValues[i].y / height) * offsetHeight;
  //     currentOverlays = [...currentOverlays, { x: newOverlayX, y: newOverlayY }]
  //   }
  //   this.updateTargetPosition(id, newX, newY, title, currentOverlays, scaleXChange, scaleYChange);
  // }
  /**
   * Method to create a transformer event listener which helps in positioning and resizing of an element.
   */
  // private addTransformerListeners(): void {
  //   const global = this;
  //   const tr = new Konva.Transformer();
  //   this.stage.on("click", function (e: any) {
  //     if (!this.clickStartShape) {
  //       return;
  //     }
  //     if (e.target._id == this.clickStartShape._id) {
  //       global.addDeleteListener(e.target);
  //       global.layer.add(tr);
  //       tr.nodes([e.target.parent]);
  //       global.transformers.push(tr);
  //       global.layer.draw();
  //     } else {
  //       tr.detach();
  //       global.layer.draw();
  //     }
  //   });
  // }
  /**
   * Method to init a new wire on doubleclick
   */
  initLine(): void {
    const component = this;
    this.stage?.off("dblclick dbltap").on("dblclick dbltap", this.createLine.bind(this));
  }
  /**
   * Method to create a new Wire
   */
  private createLine(e?: any) {
    const global = this;
    const et: any = e.target.attrs;
    const [targetComp, pinId, allowedPins, pinSize, x, y, pinName] = [
      et.targetId,
      et.pinId,
      et.allowedPins,
      et.radius,
      et.x,
      et.y,
      et.name
    ];
    if (this.undoableData.pinsInUse.includes(pinId)) {
      // This condition will be true when line already exists on current pin.
      return;
    }
    // generate new Wire-id
    const wid = "w-" + global.uuidv4();
    const fill = this.wireColorLogic(pinName);
    const quadLinePathObj = new Konva.Line({
      id: "quadLinePath",
      wid,
      fill,
      opacity: 0.3,
      points: [0, 0],
    });
    // target on which it is generated
    const wireMetaData = {
      quadLinePathObj,
      targetComp,
      pinId,
      wid,
      allowedPins,
      pinSize,
      wireColor: fill,
      x,
      y,
    };
    let wireMetaData2;
    if (!e.custom) {
      wireMetaData2 = {
        targetComp2: targetComp,
        pinId2: pinId,
        allowedPins2: allowedPins,
        pinSize2: pinSize,
        x2: x,
        y2: y
      };
    }
    else {
      const et2 = e.target.attrs2;
      const [targetComp2, pinId2, allowedPins2, pinSize2, x2, y2] = [
        et2.targetId,
        et2.pinId,
        et2.allowedPins,
        et2.radius,
        et2.x,
        et2.y
      ];
      wireMetaData2 = {
        targetComp2,
        pinId2,
        allowedPins2,
        pinSize2,
        x2,
        y2
      };
    }

    // make wire only on the component not on the empty stage
    if (targetComp) {
      global.layer?.add(quadLinePathObj);
      global.updateDottedLines(wireMetaData, wireMetaData2);
    } else {
      this.selectPinFromPointer();
      return;
    }
    /**Remove tooltip after connection */
    global.showTooltip(e.target, false)
    this.saveStageToLocalStorage(this.createLine.name)
  }


  /**
   * Method to create default colors for wire
   */
  private wireColorLogic(pinName: string): string {
    let wireColor: string;
    switch (pinName?.toLowerCase()) {
      case "ground":
        wireColor = "black"; break;
      case "vcc": case "5v": case "3v3": case "12v": case "9v":
        wireColor = "red"; break;
      default:
        wireColor = this.getRandomColors(); break;
    }
    return wireColor;
  }
  /**
   * Method to fetch a random color from the default list.
   * @returns
   */
  private getRandomColors() {
    let color = this.wireColors[1];
    const length = this.wireColors.length;
    color = this.wireColors[Math.floor(Math.random() * length)];
    return color;
  }
  /**
   * Method to create a new Wire
   */
  createLineFromMenu(connection: any) {
    this.createLine(connection);
  }
  /**
   * Method to update the connectors(wires) associated with target
   */
  private updateConnectedObjects(id: any, doesUpdateStore?: boolean) {
    const global = this;
    const target = this.targets.find((el: any) => el.t_id === id);
    let wiresToUpdate: any = [];
    // find all the wires connected to the particular target
    target.connectors.forEach((el: any) => {
      const wire = global.wires.find((ele: any) => ele.w_id === el);
      wiresToUpdate = [...wiresToUpdate, wire];
    });
    // console.log({ wiresToUpdate });
    this.updateWireCoordinates(target, wiresToUpdate, doesUpdateStore);
  }
  /**
   * Method to update the new wire coordinates in respect to the new target position
   */
  private updateWireCoordinates(target: any, wiresToUpdate: any, doesUpdateStore?: boolean) {
    const global = this;
    const tid = target.t_id;
    // difference in the previous and current position of the target
    const dx = target.current.x - target.prev.x;
    const dy = target.current.y - target.prev.y;
    // console.log({ dx, dy, current: target.current, prev: target.prev });
    // search for connector anchors in the wire obj
    wiresToUpdate.forEach((el: any) => {
      const [start, control, end] = [
        el.start.t_id,
        el.control.t_id,
        el.end.t_id,
      ];
      let points: any[] = [];
      if (start === tid) {
        points.push(el.start.c_id);
      }
      if (control === tid) {
        points.push(el.control.c_id);
      }
      if (end === tid) {
        points.push(el.end.c_id);
      }
      const wid = el.w_id;
      // update the new positions of the wire(connectors) in the store
      global.setChildren();
      const actionMethod = doesUpdateStore === true ? circuitDesignActions.updateWire : circuitDesignActions.updateDragWire;
      global.store.dispatch(
        actionMethod({ payload: { dx, dy, points, wid }, undoableData: this.undoableData }) as unknown as Action
      );
      global.updateWirePositionOnStage(wid);
    });
  }
  /**
   * Method to change position of the wire on the stage
   */
  private updateWirePositionOnStage(wid: string) {
    const layer = this.layer;
    const wire = this.wires.find((el: any) => el.w_id === wid);
    let [startNode, endNode] = [
      layer.findOne("#" + wire.start.c_id),
      layer.findOne("#" + wire.end.c_id),
    ];
    // update in the layer
    startNode.x(wire.start.current.x);
    startNode.y(wire.start.current.y);
    endNode.x(wire.end.current.x);
    endNode.y(wire.end.current.y);
    // console.log({ startNode, endNode });
    this.saveStageToLocalStorage(this.updateWirePositionOnStage.name);
  }

  /**
   * Method to create the anchor for the line element, which helps in mapping.
   * @param x @param y
   * @param cid @param wid @param name
   * @returns
   */
  private buildAnchor(
    anchorMetaData: any,
    cid: any,
    name: string
  ): Konva.Circle {

    const layer = this.layer;
    const stage = this.stage;
    const { x, y, pinId, wid, allowedPins, pinSize, targetComp } = anchorMetaData;

    //add pinId to pins in use array when pinId isn't in the array
    this.addPinInUse(pinId);

    const anchor = new Konva.Circle({
      targetId: targetComp,
      pinId: pinId,
      name,
      x: x,
      y: y,
      radius: pinSize < 2 ? 1.5 : pinSize >= 4 ? 3.5 : pinSize,
      stroke: name === "control" ? "#d6e2e9" : "black",
      fill: name === "control" ? "#faf9f9" : "#eee",
      strokeWidth: 0.5,
      draggable: true,
      allowedPins,
      wid,
      id: cid,
    });
    layer?.add(anchor);
    // add hover styling
    anchor.on("mouseover", function (e: any) {
      stage.container().style.cursor = "pointer";
      this.strokeWidth(4);
      layer.draw();
    });
    anchor.on("mouseup", (e: any) => {
      // console.log('el', e)
    })
    anchor.on("mouseout", function (e) {
      stage.container().style.cursor = "pointer";
      this.strokeWidth(2);
      layer.draw();
    });
    return anchor;
  }

  /**
   * Method to repeat the dots when moved in a path.
   */
  private updateDottedLines(wireMetaData: any, wireMetaData2: any): void {
    const { quadLinePathObj, targetComp, pinId, wid, allowedPins, pinSize, wireColor, x, y } =
      wireMetaData;
    const { targetComp2, pinId2, allowedPins2, pinSize2, x2, y2 } =
      wireMetaData2;

    let typeOfWire: string;
    if (targetComp2 === targetComp) typeOfWire = "point"; else typeOfWire = "full";

    const stage = this.stage;
    const global = this;
    const store = this.store;

    // create new unique id for 3 anchors
    const id_s = "c-" + global.uuidv4();
    const id_e = "c-" + global.uuidv4();
    const id_c = "c-" + global.uuidv4();

    // initial coordinates of the anchors
    // const coordinates = stage?.getPointerPosition();
    const anchorMetaData = { x, y, pinId, wid, allowedPins, pinSize, targetComp };
    const anchorMetaData2 = { x: x2, y: y2, pinId: pinId2, wid, allowedPins: allowedPins2, pinSize: pinSize2, targetComp: targetComp2 };

    const q = {
      start: this.buildAnchor(anchorMetaData, id_s, "start"),
      control: this.buildAnchor(anchorMetaData, id_c, "control"),
      end: this.buildAnchor(anchorMetaData2, id_e, "end"),
    };

    // create a new wire object and add to the store
    const wire = {
      w_id: wid,
      color: wireColor,
      start: {
        c_id: id_s,
        pinId: pinId,
        t_id: targetComp,
        prev: {
          x: 0,
          y: 0,
        },
        current: {
          x: q.start.x(),
          y: q.start.y(),
        },
      },
      control: {
        c_id: id_c,
        pinId: pinId,
        t_id: targetComp,
        prev: {
          x: 0,
          y: 0,
        },
        current: {
          x: q.control.x(),
          y: q.control.y(),
        },
      },
      end: {
        c_id: id_e,
        pinId: pinId2,
        t_id: targetComp2,
        prev: {
          x: 0,
          y: 0,
        },
        current: {
          x: q.end.x(),
          y: q.end.y(),
        },
      },
    };

    this.setChildren();
    if (typeOfWire === "point") {
      // add wire into the store
      store.dispatch(
        circuitDesignActions.addWire({ payload: { wire, wid }, undoableData: this.undoableData }) as unknown as { type: string }
      );
    } else {
      // add wire into the store
      store.dispatch(
        circuitDesignActions.addFullWire({ payload: { wire, wid }, undoableData: this.undoableData }) as unknown as { type: string }
      );
    }
    // to get the new anchor position when dragged
    q.start.on("dragend", (e: any, point = "start") =>
      global.onAnchorDrag(e, point, typeOfWire)
    );
    q.end.on("dragend", (e: any, point = "end") =>
      global.onAnchorDrag(e, point, typeOfWire)
    );
    q.end.on("dragmove", (e: any) => {
      global.onAnchorMove(e)
    });

    quadLinePathObj.points([
      q.start.x(),
      q.start.y(),
      q.control.x(),
      q.control.y(),
      q.end.x(),
      q.end.y(),
    ]);
    // create curve
    this.createQuadraticLine(q.start, q.control, q.end, "new", wire.color, wid);
  }


  changeWireColor(color: String) {
    const wid = this.currentShape.shape.attrs.wid;
    this.setChildren();
    this.store.dispatch(
      circuitDesignActions.updateWireColor({
        payload: { wid, color }, undoableData: this.undoableData
      }) as unknown as { type: string }
    );
    this.currentShape.shape.stroke(color);
    this.layer.batchDraw();
    this.hideContextMenu();
  }

  /**
   * for testing purposes only
   */
  onAnchorDragTest(event: any) {
    const allowedPins = event.target.attrs.allowedPins;
    let pins: any = [];
    for (let i = 0; i < allowedPins.length; i++) {
      pins = this.allPins;
    }
    pins = pins.filter((pin: any) => {
      if (!this.undoableData.pinsInUse.includes(pin.attrs.pinId)) return pin; else return null;
    })
    return pins;
  }

  /**
   * Method to update new anchor position and new target associated with it on Drag
   * @param event
   * @param point
   */
  private onAnchorDrag(event: any, point: string, typeOfWire: string = "") {
    const global = this;

    let { flag, dropTarget, to, xp, yp, wid } = this.isWireConnectionValid(global.layer, event.target, typeOfWire);

    if (flag) {
      if (typeOfWire === "point") {
        // register 'to' as an anchor
        const existingWire = this.wires.find((el: any) => el.w_id === wid);
        const endAnchorId = existingWire.end.c_id;
        let endAnchor = global.layer.find("#" + endAnchorId);
        if (endAnchor.length > 0) {
          endAnchor[0].setAttr("pinId", dropTarget.pinId);
        }
        this.addPinInUse(dropTarget.pinId);
      }

      // update in the store
      let pinId = dropTarget.pinId;
      let pinToRemove = this.wires.filter((wire: any) => {if (wire.w_id == wid) return wire})
      this.removePinInUse(pinToRemove[0].end.pinId);
      global.store.dispatch(
        circuitDesignActions.updateConnector({
          payload: { wid, xp, yp, to, pinId, point },
        }) as unknown as any
      );
      this.saveStageToLocalStorage(this.onAnchorDrag.name);
    } else {
      global.updateWirePositionOnStage(wid);
    }

    if (point === 'end' || point === 'start') {
      global.showTooltip(event.target, false);
    }
  }

  public isWireConnectionValid(layer: any, target: any, typeOfWire: string): { flag: boolean, dropTarget: any, to: string, xp: number, yp: number, wid: string } {
    // new positions of anchor in xp and yp
    const allowedPins = target.attrs.allowedPins;
    const xp = target.attrs.x;
    const yp = target.attrs.y;
    const wid = target.attrs.wid;
    const targetId = target.attrs.targetId;
    let dropTarget: any;
    let to: string = '';
    let flag: boolean = false;
    // find the allowed pins from the layer
    let pins: any = [];
    for (let i = 0; i < allowedPins.length; i++) {
      const data = layer.find("." + allowedPins[i]);
      pins = [...pins, ...data];
    }

    //return only pins that aren't associated with targetId
    if (typeOfWire === "point") {
      pins = pins.filter((pin: any) => {
        if (pin.attrs.targetId !== targetId) return pin; else return null;
      })
    }

    //return only the pins not in pinsInUse array; failsafe for when wires are being dragged onto same component too
    pins = pins.filter((pin: any) => {
      if (!this.undoableData.pinsInUse.includes(pin.attrs.pinId)) return pin; else return null;
    })

    // Choose the closest target to the anchor
    for (let i = 0; i < pins.length; i++) {
      const xi: any = pins[i].attrs.x;
      const yi: any = pins[i].attrs.y;
      if (Math.abs(xp - xi) < 4 && Math.abs(yp - yi) < 4) {
        flag = true;
        to = pins[i].attrs.targetId || "none";
        dropTarget = pins[i].attrs;
      }
    }

    return { flag, dropTarget, to, xp, yp, wid };
  }

  /**
   * Method to create Shape (Beizer curve) from points
   *
   */
  private createQuadraticLine(
    start: any,
    control: any,
    end: any,
    type: string,
    color: string,
    wid?: string,
  ): any {
    const quadraticLine = new Konva.Shape({
      name: "wire",
      stroke: color ? color : "red",
      strokeWidth: 4,
      wid: wid || "wire-id",
      sceneFunc: (ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(start.x(), start.y());
        ctx.quadraticCurveTo(control.x(), control.y(), end.x(), end.y());
        ctx.fillStrokeShape(shape);
      },
      draggable: false,

    });
    switch (type) {
      case "existing":
        return quadraticLine;
      case "new":
        this.layer?.add(quadraticLine);
        this.layer?.batchDraw();
        break;
    }
  }


  private updateLocalState(): void {
    this.store.select("circuitDesign").subscribe((data: CircuitDesignState) => {
      this.targets = [...data?.targets];
      this.wires = [...data?.wires];
      if (data?.undoableData) {
        this.undoableData = {...data?.undoableData};
      }
    });
    // remove browser's contextmenu
    window.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }

  private prepareStage(stageMetaData: any, isSilent: boolean = false): void {
    const { stage, layer, targets, wires, containerId } = stageMetaData;
    this.layer = layer;
    this.stage = stage;
    this.container = document.getElementById(containerId);
    this.initLine();
    this.showContextMenu();
    if (!isSilent) {
      this.setChildren();
      this.store.dispatch(
        circuitDesignActions.updateWholeData({ payload: { targets, wires, }, undoableData: this.undoableData }) as unknown as { type: string }
      );
    }
    this.updateLocalState();
  }


  private createTooltip(layer: any): Konva.Label {
    const global = this;
    const tooltip = global.configTooltip()
    const tooltipText = global.configTooltipText()
    tooltip.add(tooltipText);
    layer?.find("Circle").forEach((node: any) => {
      if (node["attrs"].type === "overlay") {
        // set the hover effect on the overlay
        node.on("mouseover", () => {
          // const mousePos: any = global.stage.getPointerPosition();
          tooltip.position({
            x: node["attrs"].x,
            y: node["attrs"].y - 5,
          });
          const text = node["attrs"].display || node["attrs"].name;
          if (text !== "" || undefined) {
            tooltipText.text(text);
            tooltip.show();
            global.layer.batchDraw();
            this.saveStageToLocalStorage(`${this.createTooltip.name}-mouseover`);
          }
        });
        node.on("mouseout", () => {
          tooltip.hide();
          global.layer.batchDraw();
          this.saveStageToLocalStorage(`${this.createTooltip.name}-mouseout`);
        });
      }
    });
    return tooltip;
  }

  private recreateWires(stage: any, layer: any, circuitLoadOption: CircuitLoadOptions) {
    let lines_coordinates: any = {}; const global = this;
    const layerChildren = layer?.getChildren();
    layerChildren?.forEach((layerNode: any, index: any) => {
      const attrs = layerNode.getAttrs();
      if (!lines_coordinates.hasOwnProperty(attrs.wid)) {
        lines_coordinates[attrs.wid] = {};
      }
      switch (attrs.name) {
        case "start":
        case "control":
        case "end":
          lines_coordinates[attrs.wid][attrs.name] = layerNode;
          break;
      }
      lines_coordinates[attrs.wid]['color'] = attrs.stroke;
      if (attrs.wid) {
        //add pinId to pins in use array when pinId isn't in the array
        this.addPinInUse(attrs.pinId);
        layerNode.on("dragend", (e: any) => {
          global.onAnchorDrag(e, attrs.name)
        });
        // add hover styling
        layerNode.on("mouseover", () => {
          lines_coordinates[attrs.wid][attrs.name]['attrs']['strokeWidth'] = 4;
          this.saveStageToLocalStorage(`${this.recreateWires.name}-mouseover`);
        });
        layerNode.on("mouseout", () => {
          lines_coordinates[attrs.wid][attrs.name]['attrs']['strokeWidth'] = 2;
          this.saveStageToLocalStorage(`${this.recreateWires.name}-mouseout`);
        });
      }
    });
    stage.find("#quadLinePath").forEach((node: any, index: number) => {
      let current_line = lines_coordinates[node.attrs.wid];
      const quadLinePathObj = global.createQuadraticLine(
        current_line?.start,
        current_line?.control,
        current_line?.end,
        "existing",
        current_line?.color,
        node.attrs.wid
      );
      layer.add(quadLinePathObj);
      layer.batchDraw();
      if (circuitLoadOption == CircuitLoadOptions.WRITABLE)
        this.saveStageToLocalStorage(`${this.recreateWires.name}-createQuadraticLine`);
    });
  }

  loadNew() {
    // bind store state to local variables
    this.updateLocalState();
    this.stage?.destroy();
    this.layer?.destroy();
    this.stage = new Konva.Stage({
      container: "container",
      height: this.height || 980,
      width: this.width || 1980,
      draggable: true,
    });
    this.layer = this.stage.getLayer();
    this.container = this.stage.container() as HTMLElement;
    this.saveStageToLocalStorage(this.loadNew.name);
    this.resetVariables();
  }

  /**
   * Method to load an existing JSON.
   *
   * @param existingJSON - Konva circuits JSON data with Rxjs store data.
   * @param circuitLoadOption - object of class CircuitLoadOptions
   * @param calledFromComponent - source of the function call.
   * @param isSilent - true if you want to avoid rxjs store updates.
   * TODO: improve support for adding new object on existing value. [Fixed]
   * TODO: improve code quality.
   */
  loadExisting(existingJSON: any, circuitLoadOption: CircuitLoadOptions, calledFromComponent: string, isSilent: boolean = false): void {
    // this.consoleLog("called from: " + calledFromComponent);
    // this.consoleLog("application ID: " + this.applicationId)
    try {
      //loadexisting is being called directly with saved circuit definition, which is overriding autosaved circuit definition
      const calledFromCatchBlock = calledFromComponent.toLowerCase().includes("catch");
      const calledFromPrebuild = calledFromComponent.toLowerCase().includes("prebuild");
      const calledFromKonvaUtils = calledFromComponent.toLowerCase().includes("konvautils");
      const calledFromRefresh = calledFromComponent.toLowerCase().includes("refresh");

      let tempStage = this.restoreStageFromLocalStorage();
      if (tempStage && !calledFromCatchBlock && !calledFromPrebuild && !calledFromKonvaUtils && !calledFromRefresh) {
        throw new Error(JSON.stringify(tempStage));
      }
      if (!calledFromPrebuild) {
        this.updateWorkspaceEvent(existingJSON);
      }
      if (this.stage && this.layer) {
        this.stage.destroy();
        this.layer.destroy();
      }

      let containerId;
      switch (circuitLoadOption) {
        case CircuitLoadOptions.DRAGGABLE:
          containerId = "container-draggable";
          break;
        case CircuitLoadOptions.WRITABLE:
          containerId = "container"
          break;
        case CircuitLoadOptions.READ_ONLY:
          containerId = "container-main";
          break;
      }
      
      let targets, wires;
      if (existingJSON) {
        existingJSON = this.repairCircuit(existingJSON, containerId);
        targets = existingJSON.targets;
        wires = existingJSON.wires;
        this.loadCustomNames(targets);
      } else {
        targets = [];
        wires = [];
      }
      const [global, store] = [this, this.store];

      const stage = Konva.Node.create(JSON.stringify(existingJSON), containerId);
      this.stage_copy = stage;
      stage.draggable(true);
      const layer = stage.getLayers()[0]; //undefined on new stage
      // if (!layer) {
      //   layer = new Konva.Layer();
      //   stage.add(layer);
      // }
      const stageMetaData = {
        stage, layer, targets, wires, containerId
      }
      if (circuitLoadOption == CircuitLoadOptions.WRITABLE) {
        this.prepareStage(stageMetaData, isSilent);
        // Add TOOLTIP for the OVERLAYS
        const tooltip = this.createTooltip(layer);
        layer?.add(tooltip);
      }
      // cursor for all the overlays and the anchors on hover
      stage.find("Circle").forEach((node: any) => {
        if (circuitLoadOption == CircuitLoadOptions.READ_ONLY || circuitLoadOption == CircuitLoadOptions.DRAGGABLE) {
          node.draggable(false);
        } else {
          node.on("mouseenter", function () {
            stage.container().style.cursor = "pointer";
          });
          node.on("mouseleave", function () {
            stage.container().style.cursor = "default";
          });
        }
      });
      // find all the images and recreate them on the stage
      stage.find("Image").forEach((node: any) => {
        const img = new Image();
        img.src = node.getAttr("imageSrc");
        img.onload = () => {
          node.image(img);
          stage.batchDraw();
        };
        if (circuitLoadOption == CircuitLoadOptions.READ_ONLY || circuitLoadOption == CircuitLoadOptions.DRAGGABLE) {
          node.draggable(false);
          return;
        } else {
          const textX = 5;
          const textY = -80;
          var textNode = new Konva.Text({
            x: node.attrs.x + textX,
            y: node.attrs.y + textY,
            text: node.attrs.customEditName,
            fontFamily: 'Calibri',
            fill: 'black',
            fontSize: 18,
            padding: 10,
            id: node.attrs.id,
          });
          layer.add(textNode)
          const text: any =  this.layer
            .find("Text")
            .toArray()
            .filter((el: any) => el.attrs.id === node.attrs.id);
          let results = this.layer
            .find("Text")
            .toArray()
            .filter((el: any) => el.attrs.id === node.attrs.id)
            .map(task => task.attrs.text = node.attrs.customEditName);
          text.sort(function(a:any, b:any) {
            return a.attrs.id - b.attrs.id;
          });
          if(text.length===2){
            textNode.text(text[0].attrs.text)
            text[0].destroy()
          }
          var tr = new Konva.Transformer({
            enabledAnchors: ['middle-left', 'middle-right'],
            boundBoxFunc: function (oldBox, newBox) {
              newBox.width = Math.max(30, newBox.width);
              return newBox;
            },
          });
          textNode.on('transform', function () {
            textNode.setAttrs({
              width: textNode.width() * textNode.scaleX(),
              scaleX: 1,
            });
          });
          layer?.add(tr);
          textNode.on('dblclick dbltap', () => {
            textNode.hide();
            tr.hide();
            var textPosition = textNode.absolutePosition();

            var areaPosition = {
              x: textPosition.x,
              y: textPosition.y,
            };

            var textarea = document.createElement('textarea');
            let rotation;
            let scale;
            let appRoot = document.querySelector('.konvajs-content');
            appRoot?.appendChild(textarea);

            textarea.value = textNode.text();
            textarea.style.position = 'absolute';
            textarea.style.top = areaPosition.y + 'px';
            textarea.style.left = areaPosition.x + 'px';
            textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
            textarea.style.height =
            textNode.height() - textNode.padding() * 2 + 5 + 'px';
            textarea.style.fontSize = textNode.fontSize() + 'px';
            textarea.style.border = 'none';
            textarea.style.padding = '0px';
            textarea.style.margin = '0px';
            textarea.style.overflow = 'hidden';
            textarea.style.background = 'none';
            textarea.style.outline = 'none';
            textarea.style.resize = 'none';
            textarea.style.fontFamily = textNode.fontFamily();
            textarea.style.transformOrigin = 'left top';
            textarea.style.textAlign = textNode.align();
            textarea.style.color = textNode.fill();
            rotation = textNode.rotation();
            var transform = '';
            if (rotation) {
              transform += 'rotateZ(' + rotation + 'deg)';
            }

            var px = 0;
            var isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isFirefox) {
              px += 2 + Math.round(textNode.fontSize() / 20);
            }
            transform += 'translateY(-' + px + 'px)';
            textarea.style.transform = transform;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 3 + 'px';
            textarea.focus();

            /** removed unused methods */
            // function removeTextarea() {
            //   textarea?.parentNode?.removeChild(textarea);
            //   // window.removeEventListener('click', handleOutsideClick);
            //   textNode.show();
            //   tr.show();
            //   tr.forceUpdate();
            // }

            // function setTextareaWidth(newWidth:any) {
            //   // some extra fixes on different browsers
            //   var isSafari = /^((?!chrome|android).)*safari/i.test(
            //     navigator.userAgent
            //   );
            //   var isFirefox =
            //     navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            //   if (isSafari || isFirefox) {
            //     newWidth = Math.ceil(newWidth);
            //   }

            //   // @ts-ignore
            //   var isEdge = /Edge/.test(navigator.userAgent);
            //   if (isEdge) {
            //     newWidth += 1;
            //   }
            //   textarea.style.width = newWidth + 'px';
            // }
          });
          node.on("dragmove dragend", (e: any) => {
            console.log("dragmove dragend 2");
            const doesUpdateStore = e?.type == "dragend" ? true : false;
            global.circuitImageDragEvent({ global, e, darthNode: node, labelX: 10, labelY: -5, doesUpdateStore });
          });
          node.on("mouseenter", () => {
            stage.container().style.cursor = "pointer";
          });
          node.on("mouseleave", () => {
            stage.container().style.cursor = "default";
          });
          this.registerComponentClickEvent(node);
        }
      });
      if (circuitLoadOption == CircuitLoadOptions.WRITABLE) {
        this.registerGlobalEvents(`${KonvaUtils.name}-${this.loadExisting.name}`);
      }
      this.recreateWires(stage, layer, circuitLoadOption);
      if (circuitLoadOption == undefined) {
        this.changeStageScale(0.3, stage);
        stage.draggable(false);
      }
      this.saveStageToLocalStorage(this.loadExisting.name)
    } catch (err) { 
        this.consoleLog(err);
        if (calledFromComponent == 'CreateCircuitComponent' || calledFromComponent == 'CircuitDesignComponent') throw err; 
      }
      // const isIeee = window.location.href.includes("/ieee/");
      // if(isIeee){
      //   localStorage.removeItem(this.localStorageKey);
      // }
  }

  repairCircuit(existingJSON: any, containerId: string) {
    const stage = Konva.Node.create(JSON.stringify(existingJSON), containerId);
    
    // remove extra targets from RxJs store
    let targetIds_stage: string[] = stage.find("Image").map((image: Konva.Image) => image.attrs.id);
    existingJSON.targets = existingJSON.targets.filter((target: Target) => targetIds_stage.includes(target.t_id));

    // remove extra wires from RxJs store
    let wireIds_stage: string[] = stage.find("Line").map((line: Konva.Line) => line.attrs.wid);
    existingJSON.wires = existingJSON.wires.filter((wire: Wire) => wireIds_stage.includes(wire.w_id));

    return existingJSON;
  }

  private changeStageScale(newScale: number, stage: Konva.Stage) {
    let oldScale = stage.scaleX();
    let mousePointTo = {
      x: (0 - stage.x()) / oldScale,
      y: (0 - stage.y()) / oldScale,
    };
    stage.scale({ x: newScale, y: newScale });
    var newPos = {
      x: (0 - mousePointTo.x * newScale),
      y: (0 - mousePointTo.y * newScale),
    };
    stage.absolutePosition(newPos);
    stage.batchDraw();
  }

  /**
   * Method to serialize canvas to JSON.
   *
   * TODO: Improve serialization process to create json with images and callbacks.
   */
  save(): any {
    if (this.stage) {
      const json = this.stage.toJSON();
      let children: any = this.stage.children[0]?.getLayer().children;

      let jsonDataObject = JSON.parse(json);

      children?.forEach((child: any) => {
        if (child["attrs"]["image"]) {
          let url = child["attrs"]["image"].src;
          jsonDataObject["children"][0]["children"].forEach((obj: any) => {
            if (
              obj["attrs"]["x"] == child["attrs"]["x"] &&
              obj["attrs"]["y"] == child["attrs"]["y"]
            ) {
              obj["attrs"]["imageSrc"] = url;
            }
          });
        }
      });

      return jsonDataObject;
    } else {
      return {};
    }
  }
  /**
   * Keyboard control to delete the element.
   *
   * @param shape
   */
  private addDeleteListener(shape: any): void {
    const component = this;
    window.addEventListener("keydown", function (e) {
      if (e.keyCode === 46) {
        component.transformers.forEach((t) => {
          t.detach();
        });
        shape.remove();
        e.preventDefault();
      }
      component.layer.batchDraw();
    });
  }

  // undo(shape?: any): void {
  //   const component = this;
  //   window.addEventListener('keydown', function (e) {
  //     if (e.keyCode ===  90) {
  //       alert('undo')
  //       e.preventDefault();
  //     }
  //     component.layer.batchDraw();
  //   });
  // }

  // redo(shape?: any): void {
  //   const component = this;
  //   window.addEventListener('keydown', function (e) {
  //     if (e.keyCode === 89) {
  //       alert('redo')
  //       e.preventDefault();
  //     }
  //     component.layer.batchDraw();
  //   });
  // }
  /**
   * Method to save from canvas to image.
   */
  saveImage(): void {
    const uri = this.stage.toDataURL({ pixelRatio: 3 });
    const name = `image-${new Date().getTime()}.png`;
    this.downloadAsFile(uri, name);
  }

  /**
   * Method to download as file.
   * @param uri
   * @param name
   */
  private downloadAsFile(uri: string, name: string): void {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Method for ZOOM In and Out layer
   */

  zoomInOut(event: any) {
    const stage = event.areaName === "workspace" ? this.stage : this.stage_copy;
    const [scaleX, scaleY] = [stage.scale().x, stage.scale().y];
    let newScale: any = event.action === "zoomin" ? scaleX * this.scaleChange : scaleX / this.scaleChange;
    newScale = newScale < this.minScale ? this.minScale : newScale;
    newScale = newScale > this.maxScale ? this.maxScale : newScale;
    this.changeStageScale(newScale, stage);
    if (event.areaName === "workspace") {
      let container = document.getElementById("container");
      if (container != null) {
        container.style.transform = `scale(${newScale})`;
      }
    }
  }

  /**Method to create tootltip configuration */
  configTooltip() {
    const tooltip = new Konva.Label({ opacity: 0.85, visible: false });
    tooltip.add(
      new Konva.Tag({
        fill: "#FFF",
        pointerDirection: "down",
        pointerWidth: 10,
        pointerHeight: 10,
        lineJoin: "round",
        shadowColor: "#333",
        shadowBlur: 10,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        shadowOpacity: 0.5,
      })
    );
    return tooltip
  }

  /**Method to create tootltip text configuration */
  configTooltipText() {
    const tooltipText = new Konva.Text({
      text: "",
      fontFamily: "Calibri",
      fontSize: 18,
      fontWeight: 500,
      padding: 10,
      fill: "black",
    });
    return tooltipText
  }

  /**Show tooltip on mouseover on pinlist */
  showTooltip(circle: any, show: boolean) {
    const global = this;
    const tooltip = global.configTooltip();
    const tooltipText = global.configTooltipText();
    const layer = new Konva.Layer({
      id: `tooltip-${circle.attr?.tooltipId}`
    });
    tooltip.add(tooltipText);
    if (show) {
      if (circle && circle["attrs"].type === "overlay") {
        tooltip.position({
          x: circle["attrs"].x,
          y: circle["attrs"].y - 5,
        });
        const text = circle["attrs"].display || circle["attrs"].name;
        if (text !== "" || undefined) {
          tooltipText.text(text);
          tooltip.show();
          layer.add(tooltip);
          global.stage.add(layer);
        }
      }
    } else {
      global.stage?.findOne(`#tooltip-${circle.attrs.tooltipId}`)?.remove();
    }
  }


  /**on Anchor move show tooltip of pins */
  onAnchorMove(event: any) {
    const global = this;
    if (event) {
      const xp = event.target.attrs.x;
      const yp = event.target.attrs.y;
      // const wid=event.target.attrs.wid;
      let flag: boolean = false;
      const pins = global.stage.find('Circle').toArray();
      const requiredPins = pins.filter((node: any) => {
        if (node["attrs"].type === 'overlay') {
          return node
        }
      })
      requiredPins.forEach((node: any) => {
        global.showTooltip(node, false)
      })
      for (let i = 0; i < requiredPins.length; i++) {
        const xi = requiredPins[i].attrs.x;
        const yi = requiredPins[i].attrs.y;
        if (Math.abs(xp - xi) < 4 && Math.abs(yp - yi) < 4) {
          flag = true;
          global.showTooltip(requiredPins[i], true);
        }
      }
    }
    else {
      global.showTooltip(event.target, false)
    }
    this.saveStageToLocalStorage(this.onAnchorMove.name);
  }


  isOnAnchrMoveShowTooltip(xp: any, yp: any, xi: any, yi: any) {
    if (Math.abs(xp - xi) < 4 && Math.abs(yp - yi) < 4) {
      return true;
    }
    else {
      return false;
    }
  }

  // Event registration methods
  public registerGlobalEvents(calledFrom: string) {
    // this.consoleLog("registerGlobalEvents called from " + calledFrom);
    const container = this.stage.container();
    container.tabIndex = 1;
    container.focus();
    container.addEventListener("keydown", (e: any) => {
      e.stopImmediatePropagation();
      if (e.keyCode == 67) { // ctrl + c
        this.copiedComponent = this.activeComponent;
      }
      if (e.keyCode == 86) { // ctrl + v
        this.currentSensorId = this.copiedComponent.sensorId;
        let selectedSensorInfo: any = this.filterSelectedSensors(this.copiedComponent.sensorId);
        this.addSensorsOnWorkspaceAsync(selectedSensorInfo, e)
      }
    })
  }

  private registerComponentClickEvent(node: any) {
    node.on("click", (e: any) => {
      this.activeComponent = e.target.attrs;
    });
  }

  private circuitImageDragEvent(payload: { global: any, e: any, darthNode: any, labelX: any, labelY: any, doesUpdateStore: boolean }) {
    let { global, e, darthNode, labelX, labelY, doesUpdateStore } = payload;

    const [id, x, y, title] = [
      e.target.attrs.id,
      e.target.attrs.x,
      e.target.attrs.y,
      e.target.attrs.title,
    ];
    const target = global.targets.find((el: any) => el.t_id === id);
    const currentOverlays = target.current.overlays;
    // Add object for Tag/Label
    let updatePoints = currentOverlays.concat([{ x: labelX, y: labelY }]);
    // update the new position of the target on stage
    darthNode.x(x);
    darthNode.y(y);
    global.updateTargetPosition(id, x, y, title, updatePoints, doesUpdateStore);
    global.saveStageToLocalStorage(`${global.createImage.name}-darthNode-dragmove`);
  }

  public filterSelectedSensors(sensorId: string) {
    let filteredSensors: any = [];
    this.allSensorsList.sensors.forEach((category: any) => {
      let filteredSensor = category.components.filter((sensor: any) => { return sensorId === sensor.id });
      if (filteredSensor.length > 0)
        filteredSensors.push(filteredSensor[0]);
    });
    let originalElement: any[] = []
    let element: any = {
      "item": filteredSensors[0],
      "newElement": filteredSensors[0].image
    }
    originalElement.push(element);
    return originalElement;
  }

  saveStageToLocalStorage(calledFromMethod: string) {
    // console.log("SET STAGE TO LOCAL STORAGE: " + calledFromMethod);
    let tempStage = this.save();
    this.setChildren(tempStage);
    tempStage = Object.assign({ targets: this.targets }, { wires: this.wires }, { undoableData: this.undoableData }, tempStage);
    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(tempStage));
    }
    this.updateWorkspaceEvent(tempStage);
  }

  updateWorkspaceEvent(circuitData: any) {
    this.tempStage.next(circuitData);
  }

  // Methods for Undoable data access
  addPinInUse(pinId: string) {
    if (!this.undoableData.pinsInUse.includes(pinId)) {
      this.undoableData.pinsInUse = [...this.undoableData.pinsInUse, pinId];
    }
  }

  removePinInUse(pinId: string) {
    if (this.undoableData.pinsInUse.includes(pinId)) {
      let pinsInUse = [...this.undoableData.pinsInUse];
      let index = pinsInUse.indexOf(pinId);
      if (index >= 0) {
        pinsInUse.splice(index, 1);
      }
      this.undoableData.pinsInUse = pinsInUse;
    }
  }
  
  clearPinInUse() {
    this.undoableData.pinsInUse = [];
  }

  setChildren(children?: any) {
    if (!children) {
      children = this.save();
    }
    this.undoableData.children = children;
  }

  clearChildren() {
    this.undoableData.children = [];
  }
  
  restoreStageFromLocalStorage(): any {
    let tempStage = null;
    let localStage = localStorage.getItem(this.localStorageKey) as string;
    let alternateStage = "null";
    if (this.alternateKey) { 
      alternateStage = localStorage.getItem(this.alternateKey) as string; 
      if (JSON.parse(alternateStage)) localStorage.removeItem(this.alternateKey);
    }
    tempStage = localStage ? localStage : alternateStage;
    let parsedStage = JSON.parse(tempStage);
    return parsedStage;
  }

  consoleLog(data: any) {
    if (environment.production === false) {
      console.log(data);
    }
  }

  /*
  * Purpose: we have created this function to send blank update to RxJs Store. this blank update will be useful to recover blank circuit screen in Last Undo.
  */
  resetChildren() {
    console.log("Resetting children");
    this.setChildren(BlankCircuitChildren);
    this.store.dispatch(
      circuitDesignActions.updateWholeData({
        payload: {
          wires: this.wires,
          targets: this.targets
        },
        undoableData: this.undoableData
      }) as unknown as { type: string }
    );
  }
}
