import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterDomTemplateDirective } from '../directives/register-dom-template.directive';
import { DomTemplateRendererPipe } from './pipes/dom-template-renderer.pipe';

@NgModule({
  declarations: [
    RegisterDomTemplateDirective,
    DomTemplateRendererPipe
  ],
  imports: [
    CommonModule
  ],
   exports: [
    RegisterDomTemplateDirective,
    DomTemplateRendererPipe
  ]
})
export class SharedModule { }
