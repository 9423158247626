import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApplicationCreatorComponent } from './application-creator.component';
import { ControlPanelModule } from './component/control-panel/control-panel.component';
import { CreateBasicAppModule } from './component/create-basic-app/create-basic-app.component';
import { CreateBlockProgramModule } from './component/create-block-program/create-block-program.component';
import { CreateCircuitModule } from './component/create-circuit/create-circuit.component';
import { SelectSensorModule } from './component/select-sensors/select-sensors.component';
import { StepTrackerModule } from './component/step-tracker/step-tracker.component';
import { CreateSimulatorModule } from './component/create-simulator/create-simulator.component'
import { ModalV2Module } from 'src/app/shared/components/modal-v2/modal-v2.module';
import { PythonDirectModule } from '../python-direct/python-direct.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

@NgModule({
  declarations: [
    ApplicationCreatorComponent
  ],
  imports: [
    CommonModule,
    StepTrackerModule,
    CreateBasicAppModule,
    SelectSensorModule,
    CreateCircuitModule,
    ControlPanelModule,
    CreateBlockProgramModule,
    CreateSimulatorModule,
    ModalV2Module,
    PythonDirectModule,
    LoaderModule
  ],
  exports: [
    ApplicationCreatorComponent
  ]
})
export class ApplicationCreatorModule { }
