import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output
} from "@angular/core";
import { KonvaUtils, CircuitLoadOptions } from "../../utils/konva.utils";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { UserSessionService } from "../../services/user.session.service";
import { SessionStorageService } from "../../services/session-storage.service";
import { TimeTrackingService } from "../../services/time-tracking.service";

@Component({
  selector: "app-prebuild-circuit",
  templateUrl: "./prebuild-circuit.component.html",
  styleUrls: ["./prebuild-circuit.component.scss"],
})
export class PrebuildCircuitComponent implements OnInit, OnChanges {
  @Input() loadExistingCircuitJSON!: JSON;
  @Input() element!: any;
  @Input() zoom = false;
  @Input() isAdmin = true;
  @Input() isFullCircuit!: boolean;
  @Input() isMenuOpen!: boolean;
  @Input() tutorialsData: any;
  @Input() circuitMismatchCount: number = 0;
  @Output() emitWindowSize = new EventEmitter();
  @Input() ieeeEvent: string;
  @Output() emitCircuitComparisonTrigger = new EventEmitter(false);
  konvaUtils!: KonvaUtils;
  videoUrl: any;
  video: boolean = false;
  isCircuit: boolean = false;
  isVideoBtn: boolean = false;
  isTemplate: boolean;

  constructor(private store: Store<IAppState>,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
    private timeTrackingService: TimeTrackingService) {
    this.isTemplate = window.location.href.includes('/template/');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.konvaUtils) {
      this.konvaUtils = new KonvaUtils(this.store, null, `${PrebuildCircuitComponent.name}-${this.ngOnChanges.name}`);
    }
    const hasJSONValue = changes?.loadExistingCircuitJSON?.currentValue;
    if (hasJSONValue) {
      this.loadExisting();
    }

    const isZoom = changes?.zoom?.currentValue;
    const isAdmin = changes?.isAdmin?.currentValue;
    if (!isAdmin && !isZoom) {
      this.resize();
    }
    this.isVideoUrl();
  }

  compareCircuits() {
    this.emitCircuitComparisonTrigger.emit(true);
  }

  async ngOnInit() {
    this.timeTrackingService.startTracking();
    this.loadExisting();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }
  
  ngOnDestroy(): void {
    this.timeTrackingService.stopTracking();
  }

  loadExisting(): void {
    let circuitLoadOption = this.zoom ? CircuitLoadOptions.READ_ONLY : CircuitLoadOptions.WRITABLE; //true when called from circuit builder; false from simulator dashboard
    this.konvaUtils.loadExisting(
      this.loadExistingCircuitJSON,
      circuitLoadOption,
      PrebuildCircuitComponent.name
    );
  }

  private resize() {
    const elements: any = document.getElementById("container-main");
    elements.style.width = "100px";
    elements.style.height = "100px";
  }

  // get tutorial video url on page load
  isVideoUrl() {
    if (this.tutorialsData?.circuit || this.tutorialsData?.default) {
      //checking if url include vimeo or youtube
      let tutorialUrl=this.tutorialsData?.circuit || this.tutorialsData?.default;
      if(tutorialUrl.includes('youtube')|| tutorialUrl.includes('vimeo')){
        this.isVideoBtn = true;
        var url = this.tutorialsData.circuit
          ? this.tutorialsData.circuit.split('=')
          : this.tutorialsData.default.split('=');
        // to check url from youtube or vimeo for convert into embed url
        if (url[0] === 'https://www.youtube.com/watch?v') {
          this.videoUrl = 'https://www.youtube.com/embed/' + url[1];
        }
        else {
          const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
          var url = this.tutorialsData.circuit ? this.tutorialsData.circuit : this.tutorialsData.default;
          const matches = re.exec(url);
          var vidUrl = matches && "https://player.vimeo.com/video/" + matches[1];
          this.videoUrl = vidUrl;
        }
      }
      else {
        this.isVideoBtn = false;
      }
    } else {
      this.isVideoBtn = false;
    }
  }

  videoPlay() {
    this.video = !this.video;
  }
}
