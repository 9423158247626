import { Directive, Input, TemplateRef } from "@angular/core";

/**
 * A directive to register and manage DOM templates based on a template type.
 */
@Directive({
    selector: '[registerDomTemplate]'
})
export class RegisterDomTemplateDirective {
    /** The type of the template to be registered and managed. */
    @Input('registerDomTemplate') templateType!: string;
    
    /**
     * Initializes a new instance of the RegisterDomTemplateDirective class.
     * @param template The template reference to be managed.
     */
    constructor(public template: TemplateRef<any>) {}
}
