import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';
import { searchUsers } from 'src/app/shared/models/users';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  dropdownStatus: string[] = ['Active', 'In Active', 'Deleted'];
  is_active: boolean = null;
  is_deleted: boolean = false;
  searchControl = new FormControl("");
  usersList: searchUsers[] = [];
  user: string;
  currentUser: any;
  isLoading: boolean = false;
  showDeletedColumns: boolean;
  showUpdatedColumns: boolean = true;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private toasterService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.searchUsers();
    this.user = sessionStorage.getItem('user');
    this.currentUser = JSON.parse(this.user)?.username;
  }

  addUsers() {
    this.router.navigateByUrl('add-users');
  }
  onChangeStatus(event: any) {
    if (event.target.value === "Active") {
      this.is_active = true;
      this.is_deleted = false;
      this.showDeletedColumns = false;
      this.showUpdatedColumns = true;
    }
    else if (event.target.value === "In Active") {
      this.is_active = false;
      this.is_deleted = false;
      this.showDeletedColumns = false;
      this.showUpdatedColumns = true;
   }
    else if (event.target.value === "") {
      this.is_active = null;
      this.is_deleted = false;
      this.showDeletedColumns = false;
      this.showUpdatedColumns = true;
    }
    else if (event.target.value === "Deleted") {
      this.is_deleted = true;
      this.is_active = null;
      this.showDeletedColumns = true;
      this.showUpdatedColumns = false;
    }
    this.searchUsers();
  }

  applyFilter(event: Event) {
    const filterValue = ((event.target as HTMLInputElement).value).toLowerCase();
    this.searchUsers(filterValue)
  }

  async searchUsers(filterValue?: string) {
    this.isLoading = true;
    try {
      const payload = {
        searchString: filterValue ? filterValue : this.searchControl.value,
        isActive: this.is_active,
        isDeleted: this.is_deleted
      }
      const result: any = await this.usersService.searchUsers(payload).toPromise();
      this.usersList = result.IDEUsers;
      this.isLoading = false;
    }
    catch (err: any) {
      console.log(err);
      this.toasterService.error(err.error.message);
      this.isLoading = false;
    }
  }

 

  async updateUsers(item: any) {
      this.isLoading = true;

      try {
        const payload = {
          userId: item.userName,
          isActive: !item.isActive,
          loggedInUser: this.currentUser
        }
      
        const result: any = await this.usersService.updateUsers(payload).toPromise();
        this.toasterService.success(result.message)
        this.isLoading = false;
        this.searchUsers();

      }
      catch (err: any) {
        console.log(err);
        this.toasterService.error(err.error.message);
        this.isLoading = false;
      }
    
  }
  async deleteUser(item: any, isDeleted: boolean) {
    this.isLoading = true;
  
    try {
      const payload = {
        userId: item.userName,
        isDeleted: isDeleted,
        loggedInUser: this.currentUser
      };
  
      const result: any = await this.usersService.deleteUser(payload).toPromise();
      this.toasterService.success(result.message);
      this.isLoading = false;
      this.searchUsers();
  
    } catch (err: any) {
      console.log(err);
      this.toasterService.error(err.error.message);
      this.isLoading = false;
    }
  
}
  
}
