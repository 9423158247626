<div class="p-8">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="bg-white rounded-lg p-8">
        <p class="text-xl font-bold mb-5 text-gray-800">
            <span>Add Users</span>
        </p>
        <div class="w-full">
            <textarea type="text" id="simple-search"
                class="br-1 text-area btn-1 border-gray-200 org-name rounded-lg block"
                [ngClass]="outlineUserName ? 'outline' : ''" (focus)="onFocus()"
                placeholder="Enter User Names separated by comma. Eg., username1, username2"
                [(ngModel)]="userName"></textarea>
        </div>
        <div class="mt-8 flex justify-end space-x-4">
            <button (click)="onCancel()">
                Cancel
            </button>
            <button (click)="onSubmit()" class="btn-3 bg-greenish-blue text-white p-2 rounded-lg shadow-xl hover:bg-red-600">
                Submit
            </button>
        </div>
    </div>
</div>