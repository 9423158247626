import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from '@angular/common';
import { PrebuildCircuitModule } from "src/app/shared/components/prebuild-circuit/prebuild-circuit.module";
import { DraggablePrebuildCircuitModule } from "src/app/shared/components/draggable-prebuild-circuit/draggable-prebuild-circuit.module";
import { WorkspaceModule } from "src/app/shared/components/workspace/workspace.module";
import { AngularSplitModule } from 'angular-split';
import { IotDevicesSectionModule } from 'src/app/shared/components/iot-devices-section/iot-devices-section.module';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { InfoPanelModule } from 'src/app/shared/components/info-panel/info-panel.module';
import { SensorService } from "src/app/shared/services/sensor.service";
import { KonvaUtils } from "src/app/shared/utils/konva.utils";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { showSidePanel } from "src/app/store/layout/layout.action";
import { sidePanelSelector } from "src/app/store/layout/layout.selector";
import { LoaderModule } from "../../../shared/components/loader/loader.module";
import { ToastrService } from 'ngx-toastr';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { IDEService } from 'src/app/shared/services/ide.service';
import { environment } from 'src/environments/environment';





@Component({
  selector: "app-circuit-section",
  templateUrl: "./circuit-section.component.html",
  styleUrls: ["./circuit-section.component.scss"],
})
export class CircuitSectionComponent implements OnInit, OnChanges {
  @Input() ieeeEvent: any;
  @Input() selectedApplication: any;
  @Input() loadExistingCircuitJSON!: JSON;
  @Input() applicationIdentifier!: string;
  @Input() konvaUtils!: KonvaUtils;
  @Input() isEdit!: boolean;
  @Input() isNewWorkspace!: boolean;
  @Input() applicationData: any;
  @Output() emitSavedJSON = new EventEmitter();
  @Output() emitLoadState = new EventEmitter();
  @Output() circuitLoaded: EventEmitter<void> = new EventEmitter<void>();


  @Input() sensors: any = [];
  selectedNavMenu!: any;
  draggedElement!: any;
  isMenuOpen: boolean = true;
  sensorInfo!: any;
  showSidePanel$ = this.store.select(sidePanelSelector);
  isLoading: boolean = false;
  isAdmin: boolean;

  constructor(
    private store: Store<IAppState>,
    private sensorService: SensorService,
    private toasterService: ToastrService,
    private applicationTemplateService: ApplicationService,
    private ideService: IDEService,
  ) { }

  ngOnInit(): void {
    if (!this.sensors.length) {
      this.getSensors();
      this.circuitLoaded.emit();
    }
  }

  async getS3URL() {
    const payload = {
      configurationDefinition: true
    }

    let s3_urls: any;
    if (this.isAdmin) {
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    }
    else if(this.ieeeEvent){
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    } else {
      s3_urls = await this.applicationTemplateService.updateApplicationV2(payload, this.applicationIdentifier).toPromise();
    }
    return s3_urls;
  }

  private getCircuitData(): any {
    let targets: any, wires: any, circuitJsonData: any;
    this.store.select("circuitDesign").subscribe((data) => {
      targets = data.targets;
      wires = data.wires;
    });
    circuitJsonData = this.konvaUtils.save();
    circuitJsonData = Object.assign({ targets, }, { wires, }, circuitJsonData);
    return circuitJsonData;
  }


    async save(): Promise<any> {
      let s3_urls: any = await this.getS3URL();
      const circuitDefinition = this.getCircuitData();
      const configurationDefinition = {
        circuitDefinition,
        sensors: this.sensors.ids
      };
      if (s3_urls?.data?.configurationDefinition) {
        const configString = JSON.stringify(configurationDefinition).replace(/(http:\/\/localhost:4200\/)/g, environment.domainUrl);
        const blob = new Blob([configString], {
          type: "application/json",
        });
        const file = new File([blob], "circuit.json", {
          type: "application/json",
        });
        this.toasterService.warning("Saving circuit data...");
        const result = await this.ideService.uploadToS3(file, s3_urls.data.configurationDefinition).toPromise()
          .then(() => {
            if (!this.selectedApplication) {
              this.selectedApplication = {};
            }
            this.selectedApplication.configurationDefinition = configString;
            this.toasterService.success("Circuit saved successfully");
            return s3_urls;
          })
          .catch((err) => {
            console.log(err);
            this.toasterService.error("Could not save circuit data.");
            return;
          });
        return result;
      }
      else {
        this.toasterService.error("Upload storage is unavailable at the moment. Please try again later.");
        return;
      }
    }
  

  ngOnChanges(changes: SimpleChanges): void {
    const hasSensors = changes?.sensors?.currentValue;
    if (!hasSensors) {
      this.getSensors();
    }
    if (!this.konvaUtils) {
      this.konvaUtils = new KonvaUtils(this.store, this.applicationIdentifier, `${CircuitSectionComponent.name}-${this.ngOnChanges.name}`);
    }
  }

  async getSensors() {
    this.isLoading = true;
    const result: any = await this.sensorService
      .getAllSensors()
      .toPromise();

    // Temp code to add deviceType in sensors list, until we start getting this data from DB
    await result.sensors.forEach((devices: any) => {
      let title = devices.title;
      let components = devices.components;
      components.forEach((device: any) => {
        device['deviceType'] = title;
      });
    });
    if (result) {
      this.sensors = result.sensors;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  setDraggedElement(element: any) {
    this.draggedElement = element;
    this.isMenuOpen = false;
  }
  toggleSideMenu(event: boolean): void {
    this.isMenuOpen = event;
  }
  viewInformation(sensorData: any) {
    if (event) {
      this.sensorInfo = sensorData;
      this.store.dispatch(showSidePanel({ value: true }));
    }
  }
  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
    }
  }
}


@NgModule({
  declarations: [CircuitSectionComponent],
  imports: [CommonModule, WorkspaceModule, PrebuildCircuitModule, DraggablePrebuildCircuitModule, AngularSplitModule, IotDevicesSectionModule, SidePanelModule, InfoPanelModule, LoaderModule],
  exports: [CircuitSectionComponent],
})
export class CircuitSectionModule { }

