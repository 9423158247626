<div class="p-8">
  <app-loader *ngIf="isLoading"></app-loader>
  <div class="device-mg">Lab Users</div>
  <div class="box">
    <div class="relative w-full btn-1 my-6">
      <label
        class="flex absolute inset-y-0 items-center pl-3 pointer-events-none"
      >
        <svg
          aria-hidden="true"
          class="w-5 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </label>
      <input
        type="text"
        id="simple-search"
        class="btn-1 border-gray-200 rounded-lg org-name block p-2.5"
        placeholder="Search by username"
        (keyup)="applyFilter($event)"
        style="padding-left: 35px"
        [formControl]="searchValue"
      />
    </div>
    <div class="push my-6 mr-2">
      <select
        *ngIf="isGrokCloudMember"
        name="labNames"
        id=""
        class="btn-2 border-gray-200 rounded-lg mb-5 mr-2 input-style org-name"
        (change)="onChange($event)"
      >
        <option value="" class="org-name">All Organizations</option>
        <ng-container *ngFor="let org of orgList">
          <option class="input-style" [value]="org.orgName">
            {{ org.orgName }}
          </option>
        </ng-container>
      </select>
      <select
        *ngIf="!isGrokCloudMember"
        name="labNames"
        id=""
        class="btn-2 border-gray-200 rounded-lg mb-5 mr-2 input-style org-name"
      >
        <option value="" disabled selected [value]="defaultOrganizationName">
          {{ defaultOrganizationName }}
        </option>
      </select>

      <select
        name="labNames"
        id=""
        class="btn-2 border-gray-200 rounded-lg mr-2 input-style lab-name"
        (change)="onChangeLab($event)"
        [ngModel]="selectedLab" 
      >
        <option value="" class="org-name input-style">Select Lab</option>
        <ng-container *ngFor="let lab of labList">
          <option class="input-style" [value]="lab?.labIdentifier">
            {{ lab?.labName }}
          </option>
        </ng-container>
      </select>
      <select
        name="roleType"
        id=""
        class="btn-2 border-gray-200 rounded-lg input-style lab-name"
        (change)="onChangeRole($event)"
        [formControl]="roleSelect"
      >
        <option value="" class="org-name input-style">Select Role</option>
        <ng-container *ngFor="let role of roleTypes">
          <option class="input-style" [value]="role">{{ role }}</option>
        </ng-container>
      </select>
    </div>
    <div class="relative mr-2">
      <div class="dropdown my-6 relative justify-center">
        <button
          style="width: 155px; height: 55px"
          class="bg-greenish-blue text-white rounded-lg shadow-xl"
          (click)="navigateToAddUser()"
        >
          Add Lab Users
        </button>
      </div>
    </div>
  </div>

  <div class="min-h-screen flex table-wrapper ">
    <div class="overflow-x-auto w-full">
      <table
        class="mx-auto max-w-full w-full whitespace-nowrap bg-white divide-y divide-gray-300 overflow-hidden"
      >
        <thead class="bg-gray-200">
          <tr class="text-gray-60 text-left">
            <!-- <th class="font-semibold text-sm uppercase px-2 py-2">Mac Address</th> -->
            <th class="font-semibold sr-no text-sm uppercase px-4 py-4">
              <div class="flex inline-flex items-center">
                <span> Sr.No </span>
              </div>
            </th>
            <th class="font-semibold text-sm username uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span> User Name </span>
                <span (click)="changeOrduserName('DESC')" *ngIf="isUserName">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-90-deg"
                  />
                </span>
                <span (click)="changeOrduserName('ASC')" *ngIf="!isUserName">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-270-deg"
                  />
                </span>
              </div>
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span> Org Name </span>
                <span (click)="changeOrdOrgName('DESC')" *ngIf="isOrgName">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-90-deg"
                  />
                </span>
                <span (click)="changeOrdOrgName('ASC')" *ngIf="!isOrgName">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-270-deg"
                  />
                </span>
              </div>
            </th>
            <th class="font-semibold text-sm uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span> Lab Name </span>
                <span (click)="changeOrdLab('DESC')" *ngIf="isLab">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-90-deg"
                  />
                </span>
                <span (click)="changeOrdLab('ASC')" *ngIf="!isLab">
                  <img
                    src="/assets/img/icons/arrow.svg"
                    class="rotate-270-deg"
                  />
                </span>
              </div>
            </th>
            <th class="font-semibold text-center uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span>is Student</span>
              </div>
            </th>
            <th class="font-semibold text-center uppercase px-2 py-4">
              <div class="flex inline-flex items-center">
                <span>is Instructor</span>
              </div>
            </th>

            <!-- <th class="font-semibold text-sm uppercase px-2 py-4 text-center"> -->
            <!-- <div class="flex inline-flex items-center">
                  <span>
                    Created By
                  </span>
                  <span (click)="changeOrdCrtBy('DESC')" *ngIf="isCreatedBy">
                      <img src="/assets/img/icons/arrow.svg" class="rotate-90-deg">
                  </span>
                  <span (click)="changeOrdCrtBy('ASC')" *ngIf="!isCreatedBy">
                      <img src="/assets/img/icons/arrow.svg" class="rotate-270-deg">
                  </span>
                </div>  -->
            <!-- </th> -->
            <th class="font-semibold text-sm uppercase px-2 py-4">
              Created Time
            </th>
            <th class="font-semibold text-sm text-center uppercase px-2 py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody id="tableBody">
          <tr
            class="divide-y divide-gray-200 cursor-pointer"
            *ngFor="let item of searchedUsers; index as i"
          >
            <td class="py-3 px-4 sr-no text-left whitespace-nowrap">{{ i + 1 }}</td>
            <td class="py-3 px-2 username text-left">
              <div class="username" title="{{ item.userName }}">{{ item.userName }}</div>
              <div class="light-text">{{ item.deviceIdentifier }}</div>
            </td>
            <td class="py-3 px-2 text-left whitespace-nowrap">
              <div title="{{ item.organizationName }}">{{ item.organizationName }} </div>
            </td>
            <td class="py-3 px-2 text-left whitespace-nowrap">
              <div title="{{ item.labName }}">{{ item.labName }} </div>
            </td>
            <td class="py-3 px-2 text-center whitespace-nowrap">
              <input
                type="checkbox"
                [checked]="item.isStudent"
                (change)="onStudentChange($event,item)"
              />
            </td>
            <td class="py-3 px-2 text-center whitespace-nowrap">
              <input
                type="checkbox"
                [checked]="item.isInstructor"
                (change)="onInstructorChange($event,item)"
              />
            </td>
            <td class="py-3 px-2 text-left whitespace-nowrap">
              {{ item.updatedTime | date : "dd/MM/yyyy" }}
              {{ item.updatedTime | date : "hh:mm a" }}
            </td>
            <td class="py-3 px-2 text-center flex item-center justify-center whitespace-nowrap">
              <div class="action-item mx-2" (click)="updateLabUser(item)">
                <img src="../../../../assets/img/icons/edit.svg" alt="Edit" title="Edit">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="!hasLabUsers">
        <p class="text-xl font-bold m-5 p-10 text-gray-800">No Users Added</p>
      </div>
    </div>
  </div>
</div>
