import { HttpClient } from "@angular/common/http";
import { calcPossibleSecurityContexts } from "@angular/compiler/src/template_parser/binding_parser";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";



@Injectable({
    providedIn: 'root',
})

export class UsersService{
    sessionId: string = "";
    user: string;
    userName: string;

    constructor(private http: HttpClient){
        this.user = sessionStorage.getItem('user');
        this.userName = JSON.parse(this.user).username;
        this.sessionId = localStorage.getItem('sessionId');
    }

    createUsers(payload: any){
        const url = `${environment.apiURL}/admin/ide-users/create-ide-user`;
        return this.http.post(url, payload,{
            headers : { "cookie-header" : `${this.sessionId}:${this.userName}` }
        })
    }

    searchUsers(payload: any){
        const url = `${environment.apiURL}/admin/ide-users/search-ide-user`;
        return this.http.post(url, payload,{
            headers : { "cookie-header" : `${this.sessionId}:${this.userName}` }
        })
    }

    updateUsers(payload: any){
        const url = `${environment.apiURL}/admin/ide-users/update`;
        return this.http.put(url, payload,{
            headers : { "cookie-header" : `${this.sessionId}:${this.userName}` }
        })
    }  

    deleteUser(payload: any){
        const url = `${environment.apiURL}/admin/ide-users/delete`;
        return this.http.put(url, payload,{
            headers : { "cookie-header" : `${this.sessionId}:${this.userName}` }
        })
    }  
}




