<div class="p-2 flex flex-row justify-end items-center" *ngIf="isCreateApplication; else ieeeContent">
    <span style="color:#0788FF" class="clickable-link  font-semibold text-base" (click)="switchToBlock()">Switch to Block Program</span>
    <button class="grey-btn float-right custom-lg-btn" (click)="goBack()">Back</button>
    <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">
        <span *ngIf="isDataScience || isArtificialIntelligence"> Save & Exit </span>
        <span *ngIf="!isDataScience || !isArtificialIntelligence"> Save & Next </span>
    </button>
    <button class="grey-btn float-right custom-lg-btn" (click)="onExit()">Exit</button>
</div>
<ng-template #ieeeContent>
    <div class="p-2">
        <button class="blue-btn float-right custom-lg-btn" (click)="onNext()">
            Save
        </button>
    </div>
</ng-template>
<ng-container>
    <div class="w-full h-screen">
        <as-split 
            direction="horizontal" 
            [gutterSize]="5" 
            [useTransition]="true"
            (dragStart)="onDrag($event);" 
            (dragEnd)="onDrag($event);"
            (transitionEnd)="onDrag($event);"
        >
            <as-split-area [size]="isCollapsed ? 50 : 100" class="bg-color w-full h-screen" (transitionEnd)="onDrag($event);">
                <app-editor-screen (isCollaspedEvent)="isScreenCollaps($event)" (codeGenaerated)="generatedCode($event)"
                    [blockCode]="blockGeneratedCode" [isClose]="onClose ? onClose : isCollapsed" [userName]="userName" [ieeeEvent]="ieeeEvent"
                    [selectedApplication]="selectedApplication" [isPythonSwitch]="isCreateApplication" [isBackSimulator]="isBackSimulator">
                </app-editor-screen>                      
            </as-split-area>
            <as-split-area [size]="isCollapsed ? 50 : 0" class="bg-color" (transitionEnd)="onDrag($event);">                 
                <app-output-screen (isCollaspedEvent)="isScreenCollaps($event)" [codeGenetared]="codeGenetared"></app-output-screen>
            </as-split-area>
        </as-split>
    </div>
</ng-container> 

