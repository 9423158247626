import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocklySectionComponent, BlocklySectionModule } from 'src/app/admin/components/blockly-section/blockly-section.component';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { ToastrService } from 'ngx-toastr';
import { IDEService } from 'src/app/shared/services/ide.service';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-create-block-program',
  templateUrl: './create-block-program.component.html',
  styleUrls: ['./create-block-program.component.scss'],
})
export class CreateBlockProgramComponent implements OnInit {
  @Input() ieeeEvent: any;
  @Input() applicationIdentifier!: string;
  @Input() isAdmin!: boolean;
  @Input() ieeeSave!: boolean;
  @Input() moduleKey!: string;
  @Input() selectedApplication!: any;
  @Input() isSaveAndExitInitiated: boolean = false;
  @Input() isDiscardInitiated: boolean = false;
  
  @Output() onExitPressed = new EventEmitter();
  @Output() onUnsavedChanges = new EventEmitter();
  @Output() onSavePressed = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();
  @Output() refreshApplication = new EventEmitter();
  @Output() onPopupOpen = new EventEmitter();

  @Output() getGeneratedCode = new EventEmitter();
  @Output() loader = new EventEmitter();

  @ViewChild(BlocklySectionComponent)
  blocklySectionComponent!: BlocklySectionComponent;

  private xml!: string;
  blockName: string = 'BLOCK';
  codeName: string = 'CODE';
  blockType: string = 'BLOCK';
  readonly with_mobile_xml: string = "applicationDefinition";
  readonly without_mobile_xml: string = "toolboxDefinition";
  isBlockWithSimulator: boolean = false;

  constructor(
    private applicationTemplateService: ApplicationService,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(this.ieeeSave){
      await this.save()
    }
    const isSaveAndExitInitiated = changes?.isSaveAndExitInitiated?.currentValue;
    const isDiscardInitiated = changes?.isDiscardInitiated?.currentValue;

    if (isSaveAndExitInitiated === true) {
      this.onSaveAndExit();
    }

    if (isDiscardInitiated === true) {
      this.removeAutoSavedDiagram();
      this.onExit();
    }
  }

  saveXML(xml: string) {
    this.xml = xml;
  }

  toggleBlockState(state: boolean) {
    this.isBlockWithSimulator = state;
    // this.getApplicationData();
  }

  // private async getApplicationData() {
  //   let data: any;
  //   try {
  //     if (this.isAdmin) {
  //       data = await this.applicationTemplateService.getApplicationTemplateV3(this.applicationIdentifier);
  //     } else {
  //       data = await this.applicationTemplateService.getApplication(this.applicationIdentifier).toPromise();
  //     }
  //     if (data?.Application) {
  //       this.selectedApplication = data.Application;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async copyFromOtherDiagram() {
    this.xml = this.getCurrentDiagramXML(true);

    // Updating auto-saved block diagram to avoid redundancy
    const localStorageKey = this.blocklySectionComponent.blocklyComponent.getLocalStorageKey();
    localStorage.setItem(localStorageKey, this.xml);

    await this.save();
  }

  async getS3URL() {
    let payload: any = {};
    if (this.isBlockWithSimulator) {
      payload['applicationDefinition'] = true;
    } else {
      payload['toolboxDefinition'] = true;
    }
    let s3_urls: any;
    if(this.ieeeEvent){
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    }else{
      if (this.isAdmin) {
        s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
      } else {
        s3_urls = await this.applicationTemplateService.updateApplicationV2(payload, this.applicationIdentifier).toPromise();
      }
    }

    return s3_urls;
  }

  async updateBlockDataToS3(s3_urls: any) {
    const blockDefinition = this.xml;
    const blob = new Blob([blockDefinition], {
      type: "application/xml",
    });
    const file = new File([blob], "blocks.xml", {
      type: "application/xml",
    });
    this.toasterService.warning("Saving block diagram...");
    if (s3_urls?.data?.applicationDefinition) {
      await this.ideService.uploadToS3(file, s3_urls.data.applicationDefinition).toPromise()
        .then(() => {
          this.toasterService.success("Block saved");
          this.refreshApplication.emit();
          this.loader.emit(false);
        })
        .catch((err) => {
          this.toasterService.error("Could not save block diagram.");
          console.error(err);
          this.loader.emit(false);
        });
    }
    if (s3_urls?.data?.toolboxDefinition) {
      this.ideService.uploadToS3(file, s3_urls.data.toolboxDefinition).toPromise()
        .then(() => {
          this.toasterService.success("Block saved");
          this.refreshApplication.emit();
          this.loader.emit(false);
        })
        .catch((err) => {
          this.toasterService.error("Could not save block diagram.");
          console.error(err);
          this.loader.emit(false);
        });
    }
  }

  async save() {
    this.loader.emit(true);
    let s3_urls: any = await this.getS3URL();
    await this.updateBlockDataToS3(s3_urls);

    if (!this.selectedApplication) {
      this.selectedApplication = {};
    }

    this.removeAutoSavedDiagram();
    this.setCurrentDiagramXML();

    return s3_urls;
  }

  // Save Block
  async onNext() {
    let s3_urls: any = await this.save();
    if (s3_urls) {
      this.onSavePressed.emit(s3_urls);
    }
  }

  goBack() {
    this.onBackPressed.emit();
  }

  onExit() {
    let isUnsavedChangesPresent = this.checkUnsavedChanges();
    this.onUnsavedChanges.emit(isUnsavedChangesPresent);
    this.onExitPressed.emit({});
  }

  getCurrentDiagramXML(reverse?: boolean) {
    let condition = reverse === true ? this.isBlockWithSimulator : !this.isBlockWithSimulator;

    if (condition) {
      return this.selectedApplication[this.without_mobile_xml].trim();
    } else {
      return this.selectedApplication[this.with_mobile_xml].trim();
    }
  }

  setCurrentDiagramXML() {
    this.xml = this.xml.trim();
    if (!this.isBlockWithSimulator) {
      this.selectedApplication[this.without_mobile_xml] = this.xml;
    } else {
      this.selectedApplication[this.with_mobile_xml] = this.xml;
    }
  }

  checkUnsavedChanges(): boolean {
    const currentBlockDefinition = this.xml.trim();
    const previousBlockDefinition = this.getCurrentDiagramXML();

    if (previousBlockDefinition != currentBlockDefinition) {
      return true;
    } else {
      return false;
    }
  }

  async onSaveAndExit() {
    await this.save();
    await this.onExit();
  }

  switch() {
    this.blockType =
      this.blockType?.toLowerCase() == this.blockName?.toLowerCase()
        ? this.codeName
        : this.blockName;
    // this.repairBlockly();
  }

  // repairBlockly() {
  //   window.dispatchEvent(new Event("resize"));
  // }


  /**Zoom in workspace onclick zoom in button*/
  zoomIn(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.zoom(event.x, event.y, 1);
  }

  /**Zoom out workspace onclick zoom out button */
  zoomOut(event: any) {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly.mainWorkspace?.zoom(event.x, event.y, -1);
  }

  /**recenter  */
  focus() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    blockly?.mainWorkspace?.scrollCenter();
  }

  undo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
    mainWorkspace?.undo(false);
  }

  redo() {
    let mainWorkspace =
      this.blocklySectionComponent?.blocklyComponent?.workspace?.workspace;
    mainWorkspace?.undo(true);
  }

  /**delete selected block  or clear workspace*/
  delete() {
    let blockly = this.blocklySectionComponent?.blocklyComponent?.blockly;
    if (blockly?.selected && !blockly?.selected?.workspace?.isFlyout) {
      blockly.Events.setGroup(true);
      blockly.hideChaff();
      blockly.selected.dispose(/* heal */ true, true);
      blockly.Events.setGroup(false);
    }
    // else {
    //   blockly.mainWorkspace.clear(); // clear the workspace
    // }
  }

  //switch to python interface
  switchToPython() {
    this.onPopupOpen.emit({ data: 'switchToPython' })
  }

  getGeneratedCodeBlock(event: any) {
    if (event) {
      this.getGeneratedCode.emit(event)
    }
  }
  
  // Remove/ Discard auto-saved block diagram
  removeAutoSavedDiagram() {

    // Remove data from localStorage
    const localStorageKey = this.blocklySectionComponent.blocklyComponent.getLocalStorageKey();
    localStorage.removeItem(localStorageKey);

    // Reset XML diagram on blockly and discard unsaved changes
    this.xml = this.getCurrentDiagramXML();
  }
}

@NgModule({
  declarations: [CreateBlockProgramComponent],
  imports: [CommonModule, BlocklySectionModule, ModalModule],
  exports: [CreateBlockProgramComponent],
})
export class CreateBlockProgramModule { }