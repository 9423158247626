import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenListComponent } from './screen-list.component';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoboticsVisualizationComponent } from './robotics-visualization/robotics-visualization.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ScreenListComponent, RoboticsVisualizationComponent],
  imports: [
    CommonModule,
    SidePanelModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [ScreenListComponent],
})
export class ScreenListModule {
  constructor() {}
}
