import { Injectable } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';

const DATA_SEND_INTERVAL = 15000; // Interval for sending data (15 seconds)
const IDLE_TIMEOUT = 90000; // Timeout period for idle state (90 seconds)
const MAX_ACCUMULATED_TIME = 15500; // Maximum accumulated active time (15.5 seconds with buffer)
const THROTTLE_TIMEOUT = 200; // Throttle timeout for handling rapid events (200ms)
const UNIQUE_HASH = "a1aa9802656697a0903a39f076d383d43a25e39140fe391af373766181749c7f"; // Unique payload identifier

// Interface for the payload structure sent during tracking user activity
interface Payload {
  uniqueHash: string; // Unique identifier for the payload
  moduleName: string; // Name of the associated module
  status: boolean; // Indicates if tracking is active (true) or inactive (false)
  data: {
    timespent: number; // Total time spent in seconds
    unit: string; // Unit of measurement (e.g., "seconds")
  };
}


@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private moduleName: string = "None"; // Default module name
  private lastUpdateTime: number | null = null; // Timestamp of last active update
  private activeTimeSeconds: number = 0; // Accumulated active time in seconds
  private isActive: boolean = false; // Current active state flag
  private dataSendSubscription: Subscription; // Subscription for data sending interval
  private idleTimeoutSubscription: Subscription; // Subscription for idle timeout
  private throttleTimeout: ReturnType<typeof setTimeout> | undefined; // Timeout for throttling rapid events

  // Mapping of event targets and their corresponding active/inactive events
  private readonly targets = {
    window: {
        active: ['focus', 'scroll', 'resize', 'load'], // Events that indicate activity
        inactive: ['blur', 'unload', 'beforeunload'] // Events that indicate inactivity
    },
    document: {
        active: ['mousemove', 'click', 'keydown', 'touchstart', 'touchmove'], // Document activity events
        inactive: ['mouseleave', 'touchend', 'touchcancel', 'visibilitychange'] // Document inactivity events
    }
  };

  // Flattened list of event handlers for active and inactive states
  private readonly events = this.createEventHandlers();

  constructor() {}

  // Getter and setter for moduleName
  get modulename(): string {
    return this.moduleName;
  }

  set modulename(value: string) {
    this.moduleName = value;
  }

  // Creates event handlers for active and inactive events
  private createEventHandlers() {
    return Object.entries(this.targets).flatMap(([targetName, eventTypes]) => {
        const target = targetName === 'window' ? window : document;
        return Object.entries(eventTypes).flatMap(([state, types]) => {
            const eventHandler = state === 'active' ? this.handleActiveEvent.bind(this) : this.handleInactiveEvent.bind(this);
            return types.map(type => ({ target, type, eventHandler }));
        });
    });
  }

  // Handles events indicating user activity
  private handleActiveEvent(): void {
    if (this.throttleTimeout) {
      clearTimeout(this.throttleTimeout);
    }
    this.throttleTimeout = setTimeout(() => {
      if (document.hasFocus() && !this.isPageHidden()) {
        // Start timer if not already active
        if (!this.isActive) {
          this.startTimer();
        }
        this.updateActiveTime(); // Update active time tracking
        this.resetIdleTimer(); // Reset the idle timer
      }
    }, THROTTLE_TIMEOUT);
  }

  // Handles events indicating user inactivity
  private handleInactiveEvent(): void {
    if (this.isActive && (this.isPageHidden() || !document.hasFocus())) {
      this.isActive = false; // Set active state to false
      this.clearIdleTimer(); // Clear any existing idle timer
    }
  }

  // Checks if the page is currently hidden (not visible)
  private isPageHidden(): boolean {
    return document.hidden || document.visibilityState !== 'visible';
  }

  // Starts tracking user activity by adding event listeners and starting the timer
  public startTracking(): void {
    this.handleEventListeners('add');
    this.startTimer();
  }

  // Stops tracking user activity by removing event listeners and stopping the timer
  public stopTracking(): void {
    this.stopTimer();
    this.handleEventListeners('remove');
  }

  // Start the active time tracking timer
  private startTimer(): void {
    if (!this.isActive) {
      this.isActive = true; // Mark as active
      this.lastUpdateTime = performance.now(); // Record the current time
      this.startDataSendInterval(); // Start sending data at intervals
      this.resetIdleTimer(); // Reset the idle timer
    }
  }

  // Stops the active time tracking timer
  private stopTimer(): void {
    this.isActive = false; // Mark as inactive
    this.clearIntervals(); // Clear data send intervals
    this.clearIdleTimer(); // Clear any existing idle timer
    clearTimeout(this.throttleTimeout); // Clear the throttle timeout
    this.sendData(); // Send accumulated data before stopping
  }

  // Update the active time based on the elapsed time since the last update
  private updateActiveTime(): void {
    const now = performance.now(); // Current timestamp
    if (this.lastUpdateTime !== null && this.isActive) {
      let elapsedMilliseconds = now - this.lastUpdateTime;

      // Cap the elapsed time to prevent over-accumulation
      if (this.activeTimeSeconds * 1000 + elapsedMilliseconds > MAX_ACCUMULATED_TIME) {
        elapsedMilliseconds = MAX_ACCUMULATED_TIME - (this.activeTimeSeconds * 1000);
      }

      // Convert milliseconds to seconds and accumulate active time
      this.activeTimeSeconds += elapsedMilliseconds / 1000;
    }
    this.lastUpdateTime = now; // Update the last update time
  }

  // Send the accumulated active time data to the parent window
  private sendData(): void {
    // Calculate the time spent in seconds, ensuring it does not exceed the maximum allowed time
    const timespentSeconds = Math.min(
      Math.round(this.activeTimeSeconds),
      Math.round(MAX_ACCUMULATED_TIME / 1000)
    );
    const payload: Payload = {
      uniqueHash: UNIQUE_HASH,
      moduleName: this.modulename, // Get the module name from the class property
      status: true, // Set status to true indicating active data
      data: {
        timespent: timespentSeconds, // Include the calculated time spent
        unit: "seconds" // Define the unit of measurement
      }
    };
    console.log(`Sending data: ${JSON.stringify(payload)}`);

    // Send the payload to the parent window using postMessage
    window.parent.postMessage(JSON.stringify(payload), '*');

    // Reset active time after sending data, ensuring it does not go negative
    this.activeTimeSeconds = Math.max(0, this.activeTimeSeconds - timespentSeconds);
  }

  // Starts the interval for periodically sending data
  private startDataSendInterval(): void {
    if (!this.dataSendSubscription) {
      this.dataSendSubscription = interval(DATA_SEND_INTERVAL).subscribe(() => {
        this.sendData(); // Send data at specified intervals
      });
    }
  }

  // Clears the data send interval
  private clearIntervals(): void {
    if (this.dataSendSubscription) {
      this.dataSendSubscription.unsubscribe(); // Unsubscribe to stop sending data
      this.dataSendSubscription = undefined; // Clear the subscription
    }
  }

  // Resets the idle timer to keep track of user activity
  private resetIdleTimer(): void {
    this.clearIdleTimer(); // Clear existing idle timer
    if (this.isActive) {
      this.idleTimeoutSubscription = timer(IDLE_TIMEOUT).subscribe(() => {
        this.isActive = false; // Set active state to false
        this.sendData(); // Send data before going idle
      });
    }
  }

  // Clears the idle timer if it exists
  private clearIdleTimer(): void {
    if (this.idleTimeoutSubscription) {
        this.idleTimeoutSubscription.unsubscribe(); // Unsubscribe from idle timer
        this.idleTimeoutSubscription = undefined; // Clear the subscription
    }
  }

  // Add or remove event listeners for active and inactive events
  public handleEventListeners(action: 'add' | 'remove'): void {
    this.events.forEach(({ target, type, eventHandler }) => {
      action === 'add' ? target.addEventListener(type, eventHandler) : target.removeEventListener(type, eventHandler);
    });
  }
}