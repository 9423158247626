import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  HostListener,
  ElementRef,
  ViewChild
} from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { IDEService } from 'src/app/shared/services/ide.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { Subscription } from 'rxjs';
import { WsApplicationService } from 'src/app/shared/services/ws-application.service';
import { toJpeg } from 'html-to-image';
@Component({
  selector: 'app-editor-screen',
  templateUrl: './editor-screen.component.html',
  styleUrls: ['./editor-screen.component.scss'],
})
export class EditorScreenComponent implements OnInit {
  @Output() isCollaspedEvent: any = new EventEmitter();
  @Output() codeGenaerated: any = new EventEmitter();

  @Input() ieeeEvent: any;
  @Input() userName: any;
  @Input() isClose!: any;
  @Input() blockCode: any;
  @Input() selectedApplication: any;
  @Input() isBackSimulator: boolean = false;
  @Input() isPythonSwitch: boolean = false;
  @Input() moduleName!: string;
  @Input() isAdmin: boolean = false;
  @ViewChild('dropdownElement', { static: false }) dropdownElement: ElementRef;
  
  @HostListener('window:click', ['$event'])
  onClick(event: any) {
    if (!this.dropdownElement.nativeElement.contains(event.target)) {
      this.showSelectOptions = false;
    }
  }
  isCollapsed: boolean = false;
  generatedCode: any;
  code: any = `print("Hello World!")`;
  downloadOptions!: any;
  isCodePresent: boolean = false;
  downloadTo: string = '';
  codeModel: CodeModel = {
    language: 'python',
    uri: 'main.json',
    value: this.code,
  };
  public fileString: any;
  public deviceId!: string;
  isDeviceOnline!: boolean;
  private statusCollected = false;
  private lastStoreTimeStamp!: number;
  current_username: string;
  showSelectOptions: boolean = false;
  isChangesInCode: boolean = false;
  isArrowChange: any;
  isRPIConnected = false;
  isPythonModule = false;
  
  hostName: string = '';
  wsSubscription!: Subscription;
  level: any = 'beginner';
  dependencies: string[] = [
    '@ngstack/translate@0.2.4',
    '@ngstack/code-editor@0.1.7',
  ];
  options = {
    contextmenu: true,
    minimap: {
      enabled: true,
    },
  };

  constructor(
    private ideService: IDEService,
    private fileSaverService: FileSaverService,
    private toasterService: ToastrService,
    private sessionStorageService: SessionStorageService,
    private wsService: WsApplicationService
  ) {
    this.current_username = this.sessionStorageService.getUsername();
  }

  ngOnInit(): void {
    this.toggleMenu();
    this.onCodeChanged(this.code);
    this.level = sessionStorage.getItem('module_level');
    this.getExistingPythoncode();
    this.isOnline();
    this.getRPIStatus();
    if(window.location.href.includes("/admin/python-direct")) {
      this.isPythonModule = true;
    }
    else {
      this.isPythonModule = false;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.getExistingPythoncode();
    this.getBlockGeneratedcode();
    if (changes.selectedApplication.currentValue.moduleName) {
      await this.getRPIStatus();
      this.setDownloadOptions();
      this.moduleName = changes.selectedApplication.currentValue.moduleName;
    }
  }

  // get existing python code from selected application data and show on screen
  getExistingPythoncode() {
    if (this.selectedApplication?.pythonInterfaceDefinition) {
      let codeData = JSON.parse(
        this.selectedApplication.pythonInterfaceDefinition
      ).join(',');
      this.codeModel = {
        language: 'python',
        uri: 'main.json',
        value: codeData,
      };
      this.codeGenaerated.emit({ code: codeData });
    }
  }

  toggleMenu() {
    //this if conditon only for onclose button
    if (!this.isClose?.onClose) {
      this.isCollapsed = this.isClose?.onClose;
    }
    this.isCollapsed = !this.isCollapsed;
    this.isCollaspedEvent.emit(this.isCollapsed);
  }

  // get updated python code from editor screenS
  onCodeChanged(event: any) {
    this.generatedCode = event;
    this.codeGenaerated.emit({ code: event });
  }

  // open folder and upload python file
  openFile() {
    document.querySelector('input')?.click();
  }

  handle(e: any) {
    this.readFile(e.target);
  }

  // read uploaded file
  readFile(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    var fileType = inputValue.parentElement.id;
    myReader.onloadend = (e) => {
      this.fileString = myReader.result as string;
      this.code = [this.fileString].join('\n');
      this.codeGenaerated.emit({ code: this.code });
      this.codeModel = {
        language: 'python',
        uri: 'main.json',
        value: this.code,
      };
    };
    myReader.readAsText(file);
  }

  // Download python code
  downloadPythonCode() {
    this.toasterService.success('Code is Downloaded');
    this.fileSaverService.saveText(this.generatedCode, 'code.py');
  }

  // get block generated python code and show on editor screen when switch from block to python direct interface
  getBlockGeneratedcode() {
    if (this.blockCode) {
      this.codeModel = {
        language: 'python',
        uri: 'main.json',
        value: this.blockCode.join('\n'),
      };
    }
  }

  showOptions(data: boolean) {
    this.showSelectOptions = data;
    this.setDownloadOptions();
  }

  private setDownloadOptions() {
    let options: any;
     if(this.ieeeEvent){
      switch (this.moduleName) {
        case 'robotics':
        case 'iot':
        case 'aiot':
        case 'iiot':
          options = [
            {
              key: 'device',
              display: 'On Laptop/Desktop',
            },
            {
              key: 'device-as-img',
              display: 'On Laptop/Desktop as Image',
            }
          ];
          this.isCodePresent = false;
          break;
        case 'data_science':
        case 'artificial_intelligence':
          options = [
            {
              key: 'script',
              display: 'Python Script',
            },
          ];
          this.isCodePresent = true;
          break;
      }
    }else{
    switch (this.moduleName) {
      case 'robotics':
      case 'iot':
      case 'aiot':
      case 'iiot':
        options = [
          {
            key: 'device',
            display: 'On Laptop/Desktop',
          },
          {
            key: 'device-as-img',
            display: 'On Laptop/Desktop as Image',
          },
          {
            key: 'raspberry-pi',
            display: 'On Grok IoT Gateway',
          },
        ];
        this.isCodePresent = false;
        break;
      case 'data_science':
      case 'artificial_intelligence':
        options = [
          // {
          //   key: "pdf",
          //   display: "As PDF"
          // },
          // {
          //   key: "notebook",
          //   display: "Jupyter Notebook"
          // },
          {
            key: 'script',
            display: 'Python Script',
          },
        ];
        this.isCodePresent = true;
        break;
    }
  }
    this.downloadOptions = options;
  }

  private async downloadToRaspberry() {
    if (!this.isDeviceOnline) {
      this.toasterService.error('Raspberry Pi is offline');
      return;
    }
    try {
      const blob = new Blob([this.generatedCode], {
        type: 'text/x-python-script',
      });
      const file = new File([blob], 'code.py', {
        type: 'text/x-python-script',
      });
      let result = await this.initiateUpload(file);
      if (result) {
        const appName = result['applicationName'];
        let toast_msg = `The code is downloaded. \n
        Location: /Desktop/Grok-Downloads/${appName}/code.py`;
        this.toasterService.success(toast_msg);
      } else {
        let toast_msg = `Download failed`;
        this.toasterService.error(toast_msg);
      }
    } catch (error) {
      // Nothing to do here...
    }
  }

  private async initiateUpload(file: File) {
    try {
      const initiationResult: any = await this.ideService
        .initiateUpload(file, this.current_username)
        .toPromise();
      const link = initiationResult['link']; // S3 URL to upload
      const key = initiationResult['key']; // Filename
      await this.ideService.uploadToS3(file, link).toPromise();

      const appId: any =
        sessionStorage.getItem('app_id') ||
        this?.selectedApplication?.application_identifier ||
        new Date().getTime();
      let response: any = await this.ideService
        .generateCodeDownloadLink(appId, key, this.current_username)
        .toPromise();
      return response;
    } catch (error: any) {
      this.toasterService.error(error.error.message);
    }
  }

  async getRPIStatus() {
    if(this.ieeeEvent){
      this.current_username = this.userName;
    }else{
      this.current_username = this.sessionStorageService.getUsername();
    }
    if (!this.isAdmin && ['data_science', 'artificial_intelligence'].indexOf(this.moduleName) === -1) {
      this.ideService
        .getRPIStatus(this.current_username)
        .then((result: any) => {
          this.isRPIConnected = !!result?.result?.length;
          this.hostName = result.result[0].hostname;
          this.deviceId = result.result[0].deviceIdentifier;
          this.createWebSocketConnection();
        })
        .catch((err) => console.log(err));
    }
  }

  createWebSocketConnection() {
    const appId = this.deviceId;
    if (appId && !this.isAdmin) {
      this.wsSubscription = this.wsService.createObservableCircuit(appId).subscribe(async (data: any) => {
          let parsedData = JSON.parse(data);
          if (parsedData) {
            this.lastStoreTimeStamp = parsedData.timestamp;
          }
        });
    }
  }
  
  downloadCode(downloadTo: any) {
    this.downloadTo = downloadTo
    if(this.downloadTo === ("device" || "script")) {
      this.downloadPythonCode();
      this.showOptions(false);
    }
    else if(this.downloadTo === "raspberry-pi"){
      this.downloadToRaspberry();
      this.showOptions(false);
    }
    else if(this.downloadTo === "device-as-img"){
      this.downloadCodeAsImg();
      this.showOptions(false);
    }
}
  private isOnline() {
    setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDiff = (currentTime - this.lastStoreTimeStamp) / 1000;
      this.isDeviceOnline = timeDiff < 15;
    }, 1000);
  }
  
  async downloadCodeAsImg() {
    var para = document.getElementById('codeEditor');
    para.innerText = this.generatedCode;
    const dataUrl: string = await toJpeg(document.getElementById('codeEditor'), { quality: 0.50});
    var link = document.createElement('a');
    link.download = `${this?.selectedApplication?.applicationName}-code.png`;
    link.href = dataUrl;
    link.click();
    para.innerText = "";
  }
}
