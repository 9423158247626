import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from '../shared/services/auth.interceptor';
import { BlockProgrammingModule } from './containers/block-programming/block-programming.module';
import { CircuitBuilderModule } from './containers/circuit-builder/circuit-builder.module';
import { DashboardModule } from './containers/dashboard/dashboard.module';
import { MobileAppModule } from './containers/mobile-app/mobile-app.module';
import { routes } from './ide.routes';
import { ConnectDeviceComponent } from './containers/connect-device/connect-device.component';
import { DownloadFilesModule } from './containers/download-files/download-files.module';
import { SimulatorAppModule } from './containers/simulator-app/simulator-app.module';
import { CredentialsWarningComponent } from './containers/credentials-warning/credentials-warning.component';
import { DeviceSessionComponent } from './containers/device-session/device-session.component';
import { ActionPopupComponent } from './containers/device-session/action-popup/action-popup.component';
import { UserSectionComponent } from './containers/user-section/user-section.component';
import { BlankBackgroundComponent } from './containers/blank-background/blank-background.component';
import { ReferencePythonModule } from './containers/reference-python/reference-python.module';

@NgModule({
  declarations: [
    ConnectDeviceComponent,
    CredentialsWarningComponent,
    DeviceSessionComponent,
    ActionPopupComponent,
    UserSectionComponent,
    BlankBackgroundComponent
  ],
  imports: [
    CommonModule,
    CircuitBuilderModule,
    BlockProgrammingModule,
    DashboardModule,
    ReferencePythonModule,
    DownloadFilesModule,
    MobileAppModule,
    SimulatorAppModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class IDEModule { }
