<div class="p-8">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="bg-white rounded-lg p-8">
      <nav class="text-black font-bold my-5 px-8" aria-label="Breadcrumb">
      </nav>
      <div class="flex justify-between">
        <div class="text-xl font-bold text-center py-2 mt-4">Register Lab users and roles</div>
        <div class="text-center px-4 py-2 m-2"><button class="btn-2" (click)="cancel()">Back</button></div>
      </div>
      <form #basicForm="ngForm" [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="floating-input relative mb-10 flex flex-col ">
          <label class="block text-sm font-medium text-gray-500 p-3">Organization Name*</label>
          <select *ngIf="isGrokCloudMember" class="" name="orgName" formControlName="orgName" 
          [ngClass]="{
            'input-error': myForm.get('orgName')?.invalid && (myForm.get('orgName')?.touched || myForm.get('orgName')?.dirty)
          }"
          (change)="handleOrgChange($event)">
            <option value="" selected>select organization</option>
            <ng-container *ngFor="let org of orgList">
              <option class="input-style" [value]="org.orgName">{{org.orgName}}</option>
            </ng-container>
          </select>
        <select *ngIf="!isGrokCloudMember" name="labNames" id="" class="" formControlName="orgName"
        [ngClass]="{
            'input-error': myForm.get('orgName')?.invalid && (myForm.get('orgName')?.touched || myForm.get('orgName')?.dirty)
          }"
            (change)="handleOrgChange($event)">
            <option value="" selected>Select organization</option>
            <ng-container *ngFor="let org of orgList">
              <ng-container *ngIf="org.orgName === organizationName">
                <option [value]="org.orgName">{{ org.orgName }}</option>
              </ng-container>
            </ng-container>
          </select>
        </div>
        <div class="floating-input relative mb-10 flex flex-col">
        <label class="block text-sm font-medium text-gray-500 p-3">Lab Name*</label>
        <select name="labName" class="" formControlName="labName"
        [ngClass]="{
            'input-error': myForm.get('labName')?.invalid && (myForm.get('labName')?.touched || myForm.get('labName')?.dirty)
          }"
            (change)="handleLabChange($event)">
            <option value="" selected>select lab</option>
            <ng-container *ngFor="let lab of lablist">
              <option class="input-style" [value]="lab.labIdentifier">{{lab.labName}}</option>
            </ng-container>
          </select>
        </div>
        <div class="mb-10 flex flex-col">
          <label class="block text-sm font-medium text-gray-500 p-3">Students</label>
          <input class="" type="text" name="app-name" formControlName="studentList"  
          placeholder="Enter Student usernames separated by a comma.  Example: username1,username2"
          [ngClass]="{'input-error': myForm.hasError('atLeastOneRequired') && (myForm.get('studentList')?.touched || myForm.get('studentList')?.dirty)}"
          >
        </div>
        <div class="mb-10 flex flex-col">
            <label class="block text-sm font-medium text-gray-500 p-3">Instructors</label>
            <input class="" type="text" name="app-name" formControlName="instructorList"  
            placeholder="Enter Instructor usernames separated by a comma.  Example: username1,username2"
            [ngClass]="{'input-error': myForm.hasError('atLeastOneRequired') && (myForm.get('instructorList')?.touched || myForm.get('instructorList')?.dirty)}" >
          </div>
        <div class="flex">
          <button class="bg-greenish-blue p-4 text-md font-bold text-white rounded-lg" >Submit</button>
        </div>
        
      </form>
    </div>
  </div>
  
 