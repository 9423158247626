import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  NgxBlocklyComponent,
  NgxBlocklyConfig,
  NgxBlocklyGeneratorConfig,
} from 'ngx-blockly';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as BlocklyNew from 'ngx-blockly';
import { UserSessionService } from '../../services/user.session.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ToastrService } from 'ngx-toastr';
import { BlockComparisonResult } from '../../models/block';

declare var Blockly: any;

@Component({
  selector: 'app-blockly',
  templateUrl: './blockly.component.html',
  styleUrls: ['./blockly.component.scss'],
})
export class BlocklyComponent implements OnInit, OnChanges {

  @Input() ieeeEvent: any;
  @Input() toggle: any;
  @Input() sizeChanged: any;
  @Input() selectedApplication: any;
  @Input() applicationIdentifier!: string;
  @Input() toolbox: string = '';
  @Input() isReadOnly: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isBlock: boolean = true;
  @Input() parentComponent: string = BlocklyComponent.name;
  @Output() emitGeneratedCode = new EventEmitter();
  @Output() emitXMLEvent = new EventEmitter();
  @Output() isBlockWithSimulator = new EventEmitter();
  @Output() tutorials = new EventEmitter();
  @Output() moduleNameEmitter = new EventEmitter();
  @Output() videoTutorial = new EventEmitter();
  @Output() applicationdata = new EventEmitter();
  
  // Block comparison variables
  isCompareRequested: boolean = false;
  @Input() isCompareResult: BlockComparisonResult = BlockComparisonResult.Default;
  @Output() leftBlockCodeEmitter = new EventEmitter();
  @Output() compareBlockEmitter = new EventEmitter();
  @Output() resetCompareBlockResultEmitter = new EventEmitter();

  toggleBtnState: boolean = false;
  disableToggleBtn: boolean = false;
  isNotebook: boolean = false;
  // application!: any;
  moduleName!: string;
  notebook_url!: SafeUrl;
  notebook_download_url!: SafeUrl;
  notebook_download_name!: string;
  tutorialData: any;
  video: boolean = false;
  isVideoBtn: boolean = false;
  localStorageKeyWithMobile!: string;
  localStorageKeyWithoutMobile!: string;
  alternateKeyWithMobile!: string;
  alternateKeyWithoutMobile!: string;
  isTemplate: boolean;

  @ViewChild(NgxBlocklyComponent, { static: false }) public workspace!: NgxBlocklyComponent;

  xml!: any;

  private theme = Blockly.Theme.defineTheme(
    `ide-theme-${Math.floor(100000000 + Math.random() * 900000000)}`,
    {
      base: Blockly.Themes.Classic,
      blockStyles: {
        colour_blocks: {
          colourPrimary: '#CF63CF',
          colourSecondary: '#C94FC9',
          colourTertiary: '#BD42BD',
        },
        list_blocks: {
          colourPrimary: '#9966FF',
          colourSecondary: '#855CD6',
          colourTertiary: '#774DCB',
        },
        logic_blocks: {
          colourPrimary: '#4C97FF',
          colourSecondary: '#4280D7',
          colourTertiary: '#3373CC',
        },
        loop_blocks: {
          colourPrimary: '#0fBD8C',
          colourSecondary: '#0DA57A',
          colourTertiary: '#0B8E69',
        },
        math_blocks: {
          colourPrimary: '#59C059',
          colourSecondary: '#46B946',
          colourTertiary: '#389438',
        },
        procedure_blocks: {
          colourPrimary: '#FF6680',
          colourSecondary: '#FF4D6A',
          colourTertiary: '#FF3355',
        },
        text_blocks: {
          colourPrimary: '#FFBF00',
          colourSecondary: '#E6AC00',
          colourTertiary: '#CC9900',
        },
        variable_blocks: {
          colourPrimary: '#FF8C1A',
          colourSecondary: '#FF8000',
          colourTertiary: '#DB6E00',
        },
        variable_dynamic_blocks: {
          colourPrimary: '#FF8C1A',
          colourSecondary: '#FF8000',
          colourTertiary: '#DB6E00',
        },
        hat_blocks: {
          colourPrimary: '#4C97FF',
          colourSecondary: '#4280D7',
          colourTertiary: '#3373CC',
          hat: 'cap',
        },
      },
      categoryStyles: Blockly.Themes.Modern,
      componentStyles: {
        workspaceBackgroundColour: '#fff',
        toolboxBackgroundColour: '#fff',
        toolboxForegroundColour: '#324d67',
        flyoutBackgroundColour: '#fff1f5ad',
        flyoutForegroundColour: '#ccc',
        flyoutOpacity: 1,
        scrollbarColour: '#fff',
        insertionMarkerColour: '#000',
        insertionMarkerOpacity: 0.3,
        scrollbarOpacity: 0.2,
        cursorColour: '#d0d0d0',
        blackBackground: '#333',
      },
      fontStyle: Blockly.Themes.Modern,
      startHats: true,
    }
  );

  private themeForPredefined = Blockly.Theme.defineTheme(
    `predef-theme-${Math.floor(100000000 + Math.random() * 900000000)}`,
    {
      base: Blockly.Themes.Classic,
      blockStyles: {
        colour_blocks: {
          colourPrimary: '#CF63CF',
          colourSecondary: '#C94FC9',
          colourTertiary: '#BD42BD',
        },
        list_blocks: {
          colourPrimary: '#9966FF',
          colourSecondary: '#855CD6',
          colourTertiary: '#774DCB',
        },
        logic_blocks: {
          colourPrimary: '#4C97FF',
          colourSecondary: '#4280D7',
          colourTertiary: '#3373CC',
        },
        loop_blocks: {
          colourPrimary: '#0fBD8C',
          colourSecondary: '#0DA57A',
          colourTertiary: '#0B8E69',
        },
        math_blocks: {
          colourPrimary: '#59C059',
          colourSecondary: '#46B946',
          colourTertiary: '#389438',
        },
        procedure_blocks: {
          colourPrimary: '#FF6680',
          colourSecondary: '#FF4D6A',
          colourTertiary: '#FF3355',
        },
        text_blocks: {
          colourPrimary: '#FFBF00',
          colourSecondary: '#E6AC00',
          colourTertiary: '#CC9900',
        },
        variable_blocks: {
          colourPrimary: '#FF8C1A',
          colourSecondary: '#FF8000',
          colourTertiary: '#DB6E00',
        },
        variable_dynamic_blocks: {
          colourPrimary: '#FF8C1A',
          colourSecondary: '#FF8000',
          colourTertiary: '#DB6E00',
        },
        hat_blocks: {
          colourPrimary: '#4C97FF',
          colourSecondary: '#4280D7',
          colourTertiary: '#3373CC',
          hat: 'cap',
        },
      },
      categoryStyles: Blockly.Themes.Modern,
      componentStyles: {
        workspaceBackgroundColour: 'transparent',
        scrollbarColour: '#fff',
        insertionMarkerOpacity: 0.3,
        scrollbarOpacity: 0.2,
        cursorColour: '#d0d0d0',
        blackBackground: '#333',
      },
      fontStyle: Blockly.Themes.Modern,
      startHats: true,
    }
  );

  code: string = '';

  public config: NgxBlocklyConfig = {
    move: {
      scrollbars: true,
      drag: true,
      wheel: false,
    },
  };

  public generatorConfig: NgxBlocklyGeneratorConfig = {
    dart: false,
    javascript: false,
    lua: false,
    php: false,
    python: true,
    xml: false,
  };

  public blockly: any;
  constructor(
    private applicationService: ApplicationService,
    private domSanitizer: DomSanitizer,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService,
    private toasterService: ToastrService,
  ) {
    this.isTemplate = window.location.href.includes('/template/');
  }

  async ngOnInit() {
    this.blockly = Blockly;
    if (!this.applicationIdentifier && this.isReadOnly) {
      this.getTemplate();
    }
    this.setLocalStorageKeys();
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

  }

  private setAlternateKeys() {
    this.alternateKeyWithMobile = this.localStorageKeyWithMobile.replace("edit", "create");
    this.alternateKeyWithoutMobile = this.localStorageKeyWithoutMobile.replace("edit", "create");
  }

  private getAlternateKey() {
    const alternateKey = this.toggleBtnState ? this.alternateKeyWithMobile : this.alternateKeyWithoutMobile;
    return alternateKey;
  }

  setLocalStorageKeys() {
    const isCreate = window.location.href.includes("/create");
    const isEdit = window.location.href.includes("/edit/") || window.location.href.includes("/ieee/");
    const isWorkarea = window.location.href.includes("/ide/");
    const keyPrefix = "blocklyXML";
    const isBlocklySection = this.parentComponent === 'BlocklySectionComponent';
    const platformType = isBlocklySection ? this.isAdmin ? "admin" : "ide" : null;
    const ieeePlatform = "admin"
    const isDirect = window.location.href.includes("block-direct");
    const isAdminBlock = window.location.href.includes("/admin/block");
    const app_id = isBlocklySection ? this.isAdmin ? null : sessionStorage.getItem('app_id') : null;


    let blockSectionIdentifier = '';

    if (this.applicationIdentifier) {
      let keyType = 'unknown';
      if (isEdit) { keyType = "edit"; }
      else if (isCreate) { keyType = "create"; } //create flow is triggered just once; upon exiting midway we will enter edit flow
      else if (isWorkarea) { keyType = "workarea"; }

      blockSectionIdentifier = `${keyType}-${this.applicationIdentifier}`;
    }
    else if (isDirect) blockSectionIdentifier = 'direct';
    else if (isAdminBlock) blockSectionIdentifier = 'admin-block';
    else if (app_id) {
      let keyType = 'unknown';
      if (isWorkarea) { keyType = "workarea"; }
      blockSectionIdentifier = `${keyType}-${app_id}`;
    }
    if(this.ieeeEvent){
      this.localStorageKeyWithMobile = `${keyPrefix}-${ieeePlatform}-withMobile-${blockSectionIdentifier}`
      this.localStorageKeyWithoutMobile = `${keyPrefix}-${ieeePlatform}-withoutMobile-${blockSectionIdentifier}`
    }else if (blockSectionIdentifier) {
      this.localStorageKeyWithMobile = `${keyPrefix}-${platformType}-withMobile-${blockSectionIdentifier}`
      this.localStorageKeyWithoutMobile = `${keyPrefix}-${platformType}-withoutMobile-${blockSectionIdentifier}`
      if (isEdit) { this.setAlternateKeys(); }
    };
  }

  getLocalStorageKey() {
    const localStorageKey = this.toggleBtnState ? this.localStorageKeyWithMobile : this.localStorageKeyWithoutMobile;
    return localStorageKey;
  }

  ngAfterViewInit() {
    this.setXml(this.ngAfterViewInit.name);
  }

  async ngOnChanges(changes: SimpleChanges) {
    const hasToolBox = changes?.toolbox?.currentValue;
    const isReadOnly = changes?.isReadOnly?.currentValue;
    const hasApplicationIdentifier =
      changes?.applicationIdentifier?.currentValue;

    if (hasApplicationIdentifier) {
      this.getTemplate(this.applicationIdentifier);
    }

    if (changes?.isCompareResult?.currentValue) {
      if (this.isCompareRequested === true) {
        await this.comparisonResult();
        this.resetCompareBlockResultEmitter.emit({});
      }
    } else {
      if (this.selectedApplication?.appUserID || this.selectedApplication?.applicationIdentifier) {
        this.setXml(this.ngOnChanges.name);
      } else if (this.selectedApplication == '') {
        this.workspace.clear();
      }
    }

    /**
     * Setting up workarea for block diagrams.
     * Below written code should be executed only while init time.
     */
    if (isReadOnly) {
      if (hasToolBox) {
        this.setupPreBuild();
        Blockly.ContextMenuRegistry.registry.unregister('workspaceDelete');
        Blockly.Flyout.prototype.dragAngleRange_ = 360;
      }
    } else {
      this.setupWorkplace();
      Blockly.Flyout.prototype.dragMode_ = Blockly.DRAG_STICKY;
    }
  }

  private setupPreBuild() {
    this.config = {
      ...this.config,
      zoom: {
        startScale: 0.9,
      },
      theme: this.themeForPredefined,
      trashcan: false,
      css: true,
    };
  }

  private setupWorkplace() {
    this.config = {
      ...this.config,
      toolbox: this.toolbox,
      zoom: {
        controls: false, //to show zoom in & zoomout buttons
        wheel: false,
        startScale: 0.9,
        maxScale: 3,
        minScale: 0.5,
        scaleSpeed: 1.2,
      },
      readOnly: true,
      scrollbars: true,
      trashcan: false, // for trash can or delete
      grid: { spacing: 20, length: 3, colour: '#ccc', snap: true },
      theme: this.theme,
    };
  }

  private async getTemplate(id?: string) {
    console.log(this.ieeeEvent);
    if(this.ieeeEvent){
      this.isBlock = true;
    }
    
    const app_id: any = id || sessionStorage.getItem('app_id');
    let data: any;
    try {
      if (this.isAdmin) {
        data = await this.applicationService
          .getApplicationTemplateV3(app_id);
      } else {
        if (this.isBlock) {
          data = await this.applicationService
            .getApplicationTemplateV3(app_id);
        } else {
          data = await this.applicationService
            .getApplication(app_id)
            .toPromise();
        }
      }
      if (data) {
        this.applicationdata.emit({ data: data });
        this.selectedApplication = data.Application;
        this.updateData();
        this.tutorialData = this.selectedApplication;
        this.ifTutorialData(this.selectedApplication); //get tutorial video urls
      }
    } catch (error) {
      this.disableToggleBtn = true;
      console.log(error);
    }
  }

  private updateData() {
    this.moduleName = this.selectedApplication?.moduleName;
    this.updateNotebookLink();
    this.tutorials.emit(this.selectedApplication?.tutorials);
    this.moduleNameEmitter.emit(this.moduleName);
    this.setXml(this.updateData.name);
  }

  private updateNotebookLink(): void {
    if (this.selectedApplication.applicationIdentifier) {
      const url =
        '/assets/notebooks/' + this.selectedApplication.applicationIdentifier;
      this.notebook_url = this.domSanitizer.bypassSecurityTrustResourceUrl(
        url + '.html'
      );
      this.notebook_download_name =
        this.selectedApplication.applicationName + '.ipynb';
      this.notebook_download_url =
        this.domSanitizer.bypassSecurityTrustResourceUrl(url + '.ipynb');
    }
  }

  downloadNotebook() { }

  private setXml(access: string) {
    const applicationDefinition =
      this.selectedApplication?.applicationDefinition;
    const toolboxDefinition = this.selectedApplication?.toolboxDefinition;

    let localStorageXml = this.loadExistingWorkspace();
    if (localStorageXml) {
      this.updateXml(localStorageXml, `${this.setXml.name}-${access}-localStorage`);
    } else {
      if (this.toggleBtnState) {
        this.updateXml(applicationDefinition, `${this.setXml.name}-${access}-applicationDefinition`);
      } else {
        this.updateXml(toolboxDefinition, `${this.setXml.name}-${access}-toolboxDefinition`);
      }
    }
    this.setupBasicCode();
  }

  private setupBasicCode() {
    if (!this.workspace) {
      return;
    }

    if (!this.xml) {
      this.workspace.clear();
      return;
    }

    this.workspace.toXml();
    const parsed = this.parseXMLForPosition(this.xml);
    if (parsed) {
      this.workspace.fromXml(parsed);
    }
  }

  private createXML() {
    const xml = this.workspace.toXml();
    const localStorageKey = this.getLocalStorageKey();
    if (localStorageKey) this.saveXMLToLocalStorage();
    this.emitXMLEvent.emit(xml);
  }

  saveXMLToLocalStorage() {
    const xml = this.workspace.toXml();
    if (xml != `<xml xmlns="https://developers.google.com/blockly/xml"></xml>`) {
      const localStorageKey = this.getLocalStorageKey();
      localStorage.setItem(localStorageKey, xml);
    } else {
      const localStorageKey = this.getLocalStorageKey();
      localStorage.removeItem(localStorageKey);
    }
  }

  restoreXMLFromLocalStorage(): any {
    const localStorageKey = this.getLocalStorageKey();
    const alternateKey = this.getAlternateKey();
    let xml;
    let alternateXml;
    let localXml;
    if (localStorageKey) {
      localXml = localStorage.getItem(localStorageKey) as string;
    }
    if (alternateKey) {
      alternateXml = localStorage.getItem(alternateKey) as string;
      if (alternateXml) localStorage.removeItem(alternateKey);
    }
    xml = localXml ? localXml : alternateXml;
    return xml;
  }

  loadExistingWorkspace() {
    if (!this.workspace) return;
    const localStorageXml = this.restoreXMLFromLocalStorage();
    return localStorageXml;
  }

  onCode(code: string) {
    this.emitGeneratedCode.emit(code);
    this.createXML();
    this.emitLeftBlock();
  }

  private parseXMLForPosition(xmlString: string) {
    const domParser = new DOMParser();
    const parsedXML = domParser.parseFromString(xmlString, 'text/xml');
    const element = parsedXML.getElementsByTagName('block');
    // element[0]?.setAttribute('x', '98');
    // element[0]?.setAttribute('y', '20');

    const serializer = new XMLSerializer();
    const xmlStr = serializer.serializeToString(parsedXML);
    return xmlStr;
  }

  reload() {
    if (!this.isReadOnly) {
      return;
    }
    this.getTemplate();
  }

  toggleState() {
    this.toggleBtnState = !this.toggleBtnState;
    this.setXml(this.toggleState.name);
    this.isBlockWithSimulator.emit(this.toggleBtnState);
  }

  toggleNotebook() {
    this.isNotebook = !this.isNotebook;
    if (!this.isNotebook) this.reload();
  }

  ifTutorialData(data: any) {
    if (data?.tutorials?.block || data?.tutorials?.default) {
       //checking if url include vimeo or youtube
      let tutorialUrl=data.tutorials?.block || data.tutorials?.default;
      if(tutorialUrl.includes('youtube')|| tutorialUrl.includes('vimeo')){
        this.isVideoBtn = true;
      }
      else{
        this.isVideoBtn = false;
      }
    } else {
      this.isVideoBtn = false;
    }
  }

  videoPlay() {
    this.video = !this.video;
    this.videoTutorial.emit({
      isVideo: this.video,
      isData: this.selectedApplication,
    });
  }

  // compareBlock() {
  //   this.isCompareTriggered = true;
  //   let leftBlock = this.workspace.workspace.getAllBlocks(true);
  //   this.compareBlocks.emit({
  //     isCompare: true,
  //     leftBlock: leftBlock
  //   })
  // }

  public getBlocks(): BlocklyNew.Block[] {
    return this.workspace.workspace.getAllBlocks(true);
  }

  private updateXml(xml: string, calledFrom: string) {
    this.xml = xml;
  }

  async emitLeftBlock(event?: any) {
    let leftBlock = this.workspace.workspace.getAllBlocks(true);
    await this.leftBlockCodeEmitter.emit({ leftBlock })
  }

  async emitCompareBlock(event?: any){
    this.isCompareRequested = true;
    await this.compareBlockEmitter.emit({});
  }

  async comparisonResult() {
    if (this.isCompareResult === BlockComparisonResult.Success) {
      this.toasterService.success("There are no differences found between the reference diagram and work diagram");
    } 
    // else if (this.isCompareResult === BlockComparisonResult.Error) {
    //   // this.toasterService.error("Blocks Mismatched");
    // }

    // Resetting flags
    this.isCompareResult = BlockComparisonResult.Default;
    this.isCompareRequested = false;
  }
}