
<div class="logo" *ngIf="showNav">
  <img src="assets/img/logo.png">
</div>
<div class="menus" *ngIf="showNav">
  <div [routerLink]="'/admin/dashboard'" [routerLinkActive]="['active']">Dashboard</div>
  <div [routerLink]="['/admin/applications']" [routerLinkActive]="['active']">Applications</div>
  <div [routerLink]="['/sensors']" [routerLinkActive]="['active']">Sensors</div>
  <div [routerLink]="'/admin/labs'"  [routerLinkActive]="['active']">Labs</div>
  <div [routerLink]="'/admin/lab-users'"  [routerLinkActive]="['active']">Lab Users</div>
  <div [routerLink]="'/admin/users'"  [routerLinkActive]="['active']">IDE Admin Users</div>
  <div [routerLink]="['/admin/template/create']" [routerLinkActive]="['active']">Create Application</div>
  <!-- <div [routerLink]="['/admin/users-session']" [routerLinkActive]="['active']">User Sessions</div> -->
  <div [routerLink]="'/admin/devices'" [routerLinkActive]="['active']">Devices</div>
  <div [routerLink]="'/admin/circuit'" [routerLinkActive]="['active']">Circuit Design</div>
  <div [routerLink]="'/admin/block'" [routerLinkActive]="['active']">Block Design</div>
  <div  [routerLink]="['/admin/python-direct']" [routerLinkActive]="['active']">Python Direct</div>
</div>
<div class="logout" *ngIf="showNav">
  <img  src="assets/img/sidenav/exit.svg" class="h-10" (click)="logout()">
</div>
<!-- <div class="logout flex flex-row items-center">
  <span class="v1"></span>
  <span class="p-2 justify-center"> 
    <img style="width:48px;height:25px;" src="assets/img/sidenav/Logout.svg" (click)="logout()">
  </span> 
  <span class="text-red-600 p-2 justify-center font-normal text-lg" (click)="logout()">
    Logout
  </span>
</div> -->

