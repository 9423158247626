import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { NgSelectModule } from '@ng-select/ng-select';
import { IDEService } from 'src/app/shared/services/ide.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { ToastrService } from 'ngx-toastr';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import {
  initialSimulatorDefinition,
  simulatorDefinition,
} from 'src/app/shared/models/configDef';
import { map } from 'rxjs/operators';
import {
  domConfigurations
} from 'src/app/shared/constants/sensors';
import { DOMConfiguration } from 'src/app/shared/models/sensor';

declare var $: any;
@Component({
  selector: 'app-create-simulator',
  templateUrl: './create-simulator.component.html',
  styleUrls: ['./create-simulator.component.scss'],
})
export class CreateSimulatorComponent implements OnInit, OnChanges {
  @Input() applicationIdentifier!: string;
  @Input() isAdmin: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() selectedApplication: any;
  @Output() onSavePressed = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();

  @Output() onBackGetPycode = new EventEmitter();

  sensors: any[] = [];
  sensorCustomNames: string[] = [];
  input_fields: any = [];
  logic_options: any[] = [
    { display: 'Equal', key: 'eq' },
    { display: 'Not Equal', key: 'not_eq' },
    { display: 'From', key: 'from' },
    { display: 'To', key: 'to' },
  ];
  colors: any[] = [
    { name: 'Red', key: '#FF7878' },
    { name: 'Green', key: '#B1E693' },
    { name: 'White', key: '#FFFFFF' },
    { name: 'Yellow', key: '#FFFF00' },
  ];
  sensor_options!: any;
  conditions: any[] = [];

  circuit_definition: any = {};
  simulator_def: simulatorDefinition = initialSimulatorDefinition;
  completeData: any = [];
  fieldForm!: FormGroup;
  logicForm!: FormGroup;
  sensorForm!: FormGroup;
  colorForm!: FormGroup;
  currentFieldIndex!: number;

  public deviceDOMMap: Record<string, DOMConfiguration[]> = domConfigurations;

  constructor(
    private store: Store<IAppState>,
    private fb: FormBuilder,
    private applicationTemplateService: ApplicationService,
    private toasterService: ToastrService,
    private ideService: IDEService,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) {}

  async ngOnInit() {
    this.initializeForm();
    this.setSortable();
    await this.userSessionService
      .extendToken(this.sessionStorageService.getUsername())
      .toPromise();
  }

  ngOnChanges(changes: SimpleChanges) {
    const hasSelectedApplication = changes?.selectedApplication?.currentValue;
    if (this.isEdit && hasSelectedApplication) {
      this.refreshSimulatorCreator();
    }
  }

  ngAfterViewInit() {
    this.refreshSimulatorCreator();
  }

  private async refreshSimulatorCreator() {
    if (!!this.selectedApplication) {
      const { simulatorDefinition, configurationDefinition } =
        this.selectedApplication;
      this.simulator_def = JSON.parse(simulatorDefinition);
      this.circuit_definition = JSON.parse(configurationDefinition);
      this.presetSimulatorDefinition();
      this.setSensors();
    }
  }

  private setSensors() {
    let inputSensors: any =
      this.circuit_definition.circuitDefinition.targets.filter(
        (ele: any) => ele.sensorType === 'INPUT'
      );
    this.sensors = inputSensors;
    this.sensorCustomNames = [];
    this.sensors.forEach((sensor) => {
      const deviceType = sensor.deviceType;
      // Get the suffixes based on deviceType
      const suffixes = this.deviceDOMMap[deviceType]?.map(sensorDOM => sensorDOM.suffix) || [''];
    
      // Append each customName with its corresponding suffix
      this.sensorCustomNames.push(...suffixes.map(suffix => `${sensor.customName}${suffix}`));
    }); 
  }

  private presetSimulatorDefinition() {
    let allData: any = [];
    this.simulator_def = JSON.parse(
      this.repairSimulatorDefinition(JSON.stringify(this.simulator_def))
    );
    (this.simulator_def?.mobile_controller_logic || []).forEach((el: any) => {
      if (!el._id) {
        el._id = this.uuidv4();
      }
      let inputs: any = [];
      Object.keys(el.condition).map((key: any) => {
        let input_target: any;
        const logic = Object.keys(el.condition[key])[0];
        input_target = {
          _id: this.uuidv4(),
          sensor: key,
          logic,
          value: el.condition[key][logic],
        };
        inputs.push(input_target);
      });
      const item = {
        inputs,
        messages: el.messages,
        _id: el._id,
      };
      allData.push(item);
    });
    let item: any,
      allItems: any = [];
    allData.forEach((el: any) => {
      item = {
        conditions: [...el.inputs],
        messages: el.messages,
        _id: el._id,
      };
      allItems = [...allItems, item];
    });
    this.completeData = allItems;
  }
  addNewCondition() {
    if (this.logic_options.length == 0) {
      console.log('logic_options list empty!');
      this.toasterService.error('Logic options list is empty.');
      return;
    }
    if (this.sensors.length == 0) {
      console.log('Sensors list empty!');
      this.toasterService.error('Sensors list is empty.');
      return;
    }

    const condition = {
      _id: this.uuidv4(),
      value: '',
      sensor: this.sensorCustomNames[0],
      logic: this.logic_options[0].key,
    };
    this.conditions = [...this.conditions, condition];
  }
  removeConditionField(id: string) {
    this.conditions = this.conditions.filter((el: any) => el._id !== id);
  }
  saveLogic(logic: any, id: any) {
    const condition: any = this.conditions.find((el: any) => el._id === id);
    condition.logic = logic;
  }
  saveSensor(sensor: any, id: any) {
    const condition: any = this.conditions.find((el: any) => el._id === id);
    condition.sensor = sensor;
  }
  saveColor(color: any, id: string) {
    const field = this.input_fields.find((el: any) => el._id === id);
    field.color = color;
  }

  private initializeForm() {
    this.fieldForm = this.fb.group({
      fieldId: 'text',
      agree: null,
    });

    if (this.logic_options.length > 0) {
      this.logicForm = this.fb.group({
        logicId: this.logic_options[0].display,
        agree: null,
      });
    }

    if (this.sensors.length > 0) {
      this.sensorForm = this.fb.group({
        sensorId: this.sensorCustomNames[0],
        agree: null,
      });

      this.colorForm = this.fb.group({
        colorId: this.colors[0].name,
        agree: null,
      });
    }
  }

  toggleSelectMenu(item: any) {
    let sensorItem = this.sensors.find((el: any) => el._id === item._id);
  }

  selectType(item: any, value: string) {
    let sensorItem = this.sensors.find((el: any) => el._id === item._id);
    sensorItem.value.eq = value;
  }

  addNext() {
    this.updateSimulatorDefinition();
    this.sensors = [];
    this.input_fields = [];
    this.conditions = [];
    this.setSensors();
    this.initializeForm();
  }
  private uuidv4() {
    return 'xxxx-yxyx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  private updateSimulatorDefinition(): void {
    this.input_fields = this.input_fields.filter((el: any) => el.value !== '');
    if (this.input_fields.length == 0) return;
    const _id = this.uuidv4();
    let payload: any,
      item: any = {};
    this.conditions.forEach((el: any) => {
      if (item.hasOwnProperty(el.sensor)) {
        item[el.sensor][el.logic] = el.value;
      } else {
        item[el.sensor] = { [el.logic]: el.value };
      }
    });
    payload = {
      _id,
      condition: item,
      messages: this.input_fields,
    };
    this.completeData = [
      ...this.completeData,
      {
        conditions: [...this.conditions],
        messages: [...this.input_fields],
        _id,
      },
    ];

    this.simulator_def.mobile_controller_logic = [
      ...(this.simulator_def?.mobile_controller_logic || []),
      payload,
    ];
  }

  removeCondition(item: any) {
    let tempAllSensors: any = [];
    let tempMobileControllerLogic: any = [];
    this.completeData.forEach((el: any) => {
      if (el._id != item._id) {
        tempAllSensors.push(el);
      }
    });
    this.completeData = tempAllSensors;
    this.simulator_def?.mobile_controller_logic.forEach((el: any) => {
      if (el._id != item._id) {
        tempMobileControllerLogic.push(el);
      }
    });
    this.simulator_def.mobile_controller_logic = tempMobileControllerLogic;
  }

  addNewField() {
    if (this.logic_options.length == 0) {
      console.log('logic_options list empty!');
      this.toasterService.error('Logic options list is empty.');
      return;
    }
    if (this.sensors.length == 0) {
      console.log('Sensors list empty!');
      this.toasterService.error('Sensors list is empty.');
      return;
    }

    const type = this.fieldForm?.value?.fieldId || 'text';
    let field: any = { _id: this.uuidv4(), value: '', type };
    if (type === 'text') field = { ...field, color: '#FF7878' };
    this.input_fields = [...this.input_fields, field];
  }

  saveImage(event: any, id: string) {
    const file_obj: File = event.target.files[0];
    this.generateS3Url()
      .then(([upload_url, file_path]: string[]) => {
        this.uploadToS3(file_obj, upload_url);
        return file_path;
      })
      .then((file_path: string) => {
        const current_field = this.input_fields.find((e: any) => e._id === id);
        current_field.value = file_path;
        current_field.color = '#FFF';
      })
      .catch((error) => console.log(error));
  }

  private uploadToS3(file: File, upload_url: string) {
    this.ideService
      .uploadToS3(file, upload_url)
      .toPromise()
      .then((result) => {
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }

  private async generateS3Url() {
    const upload_type = 'simulator-image';
    let upload_url: string = '';
    let file_path: string = '';
    try {
      const result: any = await this.ideService
        .uploadStaticFile(upload_type)
        .toPromise();
      if (result) {
        upload_url = result?.upload_url;
        file_path = environment.staticFilesUrl + result.Key;
      }
    } catch (error) {
      console.log(error);
    }
    return [upload_url, file_path];
  }

  private sortObject(toIndex: number, object: string) {
    const array =
      object === 'sensors'
        ? this.conditions
        : object === 'messages'
        ? this.input_fields
        : this.sensors;
    const element = array[this.currentFieldIndex];
    array.splice(this.currentFieldIndex, 1);
    array.splice(toIndex, 0, element);
  }

  private setSortable() {
    const g = this;
    $('.sortable').sortable({
      containment: 'parent',
      handle: '.move-container',
      start: function (e: any, ui: any) {
        const index = ui.item.index();
        g.currentFieldIndex = index;
      },
      update: function (e: any, ui: any) {
        const index = ui.item.index();
        const object = ui.item.context.id;
        g.sortObject(index, object);
      },
    });
    $('.sortable').disableSelection();
  }

  removeField(field: any) {
    this.input_fields = this.input_fields.filter(
      (el: any) => el._id !== field._id
    );
  }

  selectField(type: any, id: string) {
    const index = this.input_fields.findIndex((el: any) => el._id === id);
    this.input_fields[index].type = type;
  }

  private async getS3URL(payload: any) {
    let s3_urls: any;
    if (this.isAdmin) {
      s3_urls = await this.applicationTemplateService
        .updateAppTemplateV2(payload, this.applicationIdentifier)
        .toPromise();
    } else {
      s3_urls = await this.applicationTemplateService
        .updateApplicationV2(payload, this.applicationIdentifier)
        .toPromise();
    }
    return s3_urls;
  }

  async updatesimulatorDataToS3() {
    const s3_urls: any = await this.getS3URL({ simulatorDefinition: true });
    if (s3_urls?.data?.simulatorDefinition) {
      const unsafeConfigString = JSON.stringify({
        visualization: this.simulator_def.visualization,
        visualization_panel: this.simulator_def.visualization_panel,
        mobile_console: this.simulator_def.mobile_console,
        mobile_controller_logic: this.simulator_def.mobile_controller_logic,
      });
      const safeConfigString =
        this.repairSimulatorDefinition(unsafeConfigString);
      const blob = new Blob([safeConfigString], {
        type: 'application/json',
      });
      const file = new File([blob], this.applicationIdentifier + '.json', {
        type: 'application/json',
      });
      this.toasterService.warning('Saving simulator definition...');
      this.ideService
        .uploadToS3(file, s3_urls.data.simulatorDefinition)
        .toPromise()
        .then(() => {
          this.toasterService.success('Simulator Definition Saved!');
          this.onSavePressed.emit(this.simulator_def);
        })
        .catch((err) => {
          this.toasterService.error('Could not save simulator definition.');
          console.error(err);
        });
    } else {
      this.toasterService.error(
        'Upload storage is unavailable at the moment. Please try again later.'
      );
    }
  }

  repairSimulatorDefinition(simulatorDefinition: string): string {
    if (simulatorDefinition == '{}') {
      return '[]';
    } else {
      return simulatorDefinition;
    }
  }

  onNext() {
    if ((this.simulator_def?.mobile_controller_logic || []).length < 1)
      this.updateSimulatorDefinition();
    this.updatesimulatorDataToS3();
  }
  goBack() {
    this.onBackPressed.emit();
    this.onBackGetPycode.emit({ isSimulatorBack: true });
  }
}

@NgModule({
  declarations: [CreateSimulatorComponent],
  imports: [CommonModule, FormsModule, NgSelectModule, ReactiveFormsModule],
  exports: [CreateSimulatorComponent],
})
export class CreateSimulatorModule {}
