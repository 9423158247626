<div class="p-8">
  <div class="menus" *ngIf="!isFromGCC">
    <div [class.active]="templateFlag" style="border-radius:5px 0 0 5px;" (click)="template()">Templates
    </div>
    <div *ngIf="isAdmin" [class.active]="userAppFlag" style="border-radius: 0 5px 5px 0;" (click)="userApplication()">
      User Solutions
    </div>
    <div *ngIf="!isAdmin" [class.active]="userAppFlag" style="border-radius: 0 5px 5px 0;" (click)="userApplication()">
      My Solutions
    </div>
  </div>
  <div class="menus1" *ngIf="isFromGCC">
    <div *ngIf="isAdmin" [class.active]="userAppFlag" style="border-radius: 0 5px 5px 0;" (click)="userApplication()">
      Participant Solutions
    </div>
  </div>
  <div class="flex mr-2 justify-end">
    <div class="my-6 text-right flex"  *ngIf="isAdmin && isFromGCC">
      <button class="bg-greenish-blue text-white p-2 rounded-lg shadow-xl hover:bg-red-600 flex justify-center" (click)="backtoGCC()">
        <svg stroke="currentColor" class="" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1.35em" width="1.35em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></svg>Back to GIAC
      </button>
    </div>
    <div *ngIf="isAdmin && !isFromGCC" class="dropdown my-6 text-right relative">
      <button class="bg-greenish-blue text-white p-2 rounded-lg shadow-xl inline-flex items-center"
        (click)="toggleMenu()">Module Name
        <i class="p-4 m-2 arrow down text-white"></i>
      </button>
      <div *ngIf="isMenuOpen"
        class="origin-top-right absolute -right-5 mt-2 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1 cursor-pointer">
          <a class="drop-down-links" (click)="onSelectModule('all')">All</a>
          <a class="drop-down-links" (click)="onSelectModule('iot')">IOT</a>
          <a class="drop-down-links" (click)="onSelectModule('robotics')">Robotics</a>
          <a class="drop-down-links" (click)="onSelectModule('data_science')">Data Science</a>
          <a class="drop-down-links" (click)="onSelectModule('aiot')">AIoT</a>
          <a class="drop-down-links" (click)="onSelectModule('artificial_intelligence')">Artificial Intelligence</a>
          <a class="drop-down-links" (click)="onSelectModule('iiot')">IIoT</a>
        </div>
      </div>
    </div>


    <div class="my-6 text-right">
      <button *ngIf="isAdmin && !isFromGCC" class="bg-greenish-blue text-white p-2 rounded-lg shadow-xl hover:bg-red-600"
        (click)="navigateToUrlFrom('create')">New Solution</button>
    </div>
  </div>
  <div class="min-h-screen flex">
    <div class="overflow-x-auto w-full table-wrapper">
      <div class="min-w-screen min-h-screen flex justify-center font-sans">
        <div class="w-full">
          <div class="bg-white shadow-md rounded my-6">
            <table class="w-full table-auto">
              <thead class="bg-greenish-blue text-white">
                <tr class="text-white-600 uppercase text-sm leading-normal">
                  <th class="text-left">#</th>
                  <th class="text-left">Name</th>
                  <th class="text-center">Module Name</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Created By</th>
                  <th class="text-center" *ngIf="isAdmin && !isFromGCC">Status</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light"  id="tableBody">
                <tr class="border-b border-gray-200 hover:bg-gray-100"
                  *ngFor="let item of filteredApplications; let i = index">
                  <td class="text-left dark-text">
                    <span class="dark-text">{{i+1}}</span>
                  </td>
                  <td class="text-left">
                    <!-- <div class="clickable-link" (click)="openApplication(item)">{{item.applicationName}}</div> -->
                    <div [class.non-clickable-link]= "(!isAdmin && templateFlag)"  [class.clickable-link]= "(isAdmin) ||(!isAdmin && userAppFlag)"(click)="((isAdmin) ||(!isAdmin && userAppFlag) === true)?openApplication(item):null">{{item.applicationName}}</div>
                    <div class="light-text">{{item.applicationIdentifier}}</div>
                  </td>
                  <td class="text-center dark-text" [style.text-transform]='"uppercase"'>{{item.moduleName}}</td>
                  <td class="text-left light-text">
                    {{(item.applicationDesc && item.applicationDesc.length > 40) ? (item.applicationDesc |
                    slice:0:40)+'..' : (item.applicationDesc)}}
                  </td>
                  <td class="text-left light-text">{{item.createdBy}}</td>
                  <td class="text-center" *ngIf="isAdmin && !isFromGCC">
                    <span *ngIf="item.is_published " class="green-tag" (click)="changePublishedFlag(item)">
                      Published
                    </span>
                    <!-- <span *ngIf="item.is_published && item.isInternallyPublished" class="green-tag" (click)="changePublishedFlag(item)">
                      Published Internally
                    </span> -->
                    <span *ngIf="!item.is_published" class="red-tag" (click)="changePublishedFlag(item)">
                      Unpublished
                    </span>
                  </td>
                  <td class="text-center">
                    <div class="flex item-center justify-center">

                      <div class="action-item" *ngIf="item.moduleName != 'data_science' && userAppFlag">
                        <a
                        (click)="navigateToReference(item.applicationIdentifier, 'application', 'circuit')">
                          <img src="../../../../assets/img/admin/view-circuit.svg" alt = "View Circuit"
                            title="View Circuit">
                        </a>
                      </div>
                      <div class="action-item" *ngIf="item.moduleName != 'data_science' && !userAppFlag">
                        <a
                        (click)="navigateToReference(item.applicationIdentifier, 'template', 'circuit')">
                          <img src="../../../../assets/img/admin/view-circuit.svg" alt = "View Circuit"
                            title="View Circuit">
                        </a>
                      </div>
                      <div class="action-item" *ngIf="userAppFlag">
                        <a
                        (click)="navigateToReference(item.applicationIdentifier, 'application', 'block')">
                          <img src="../../../../assets/img/admin/view-block.svg" alt = "View Block Programming"
                            title="View Block Programming">
                        </a>
                      </div>
                      <div class="action-item" *ngIf="!userAppFlag">
                        <a
                        (click)="navigateToReference(item.applicationIdentifier, 'template', 'block')">
                          <img src="../../../../assets/img/admin/view-block.svg" alt = "View Block Programming"
                            title="View Block Programming">
                        </a>
                      </div>
                      <div class="action-item" *ngIf="!userAppFlag">
                        <a (click)="navigateToReference(item.applicationIdentifier, 'template', 'reference-python')">
                          <img src="../../../../assets/img/admin/python_icon.svg" alt = "View Python Program"
                            title="View Python Program">
                        </a>  
                      </div>
                      <div class="action-item" *ngIf="userAppFlag">
                        <a (click)="navigateToReference(item.applicationIdentifier, 'application', 'reference-python')">
                          <img src="../../../../assets/img/admin/python_icon.svg" alt = "View Python Program"
                            title="View Python Program">
                        </a>
                      </div>
                      <div *ngIf="(isAdmin) ||(!isAdmin && userAppFlag)" class="action-item"
                        (click)="openApplication(item)">
                        <img src="../../../../assets/img/icons/edit.svg" alt="Edit" title="Edit">
                      </div>
                      <div *ngIf="(isAdmin && !isFromGCC) ||(!isAdmin && userAppFlag)" class="action-item"
                        (click)="clone(item)">
                        <img src="../../../../assets/img/icons/duplicate.svg" alt="Clone" title="Clone">
                      </div>
                      <div *ngIf="(isAdmin && !isFromGCC) && userAppFlag" class="action-item" (click)="saveAsTemplate(item)">
                        <img src="../../../../assets/img/icons/save-as-template.svg"
                          alt="Create Template From Solution" title="Create Template From Solution">
                      </div>
                      <div *ngIf="(isAdmin ) ||(!isAdmin && userAppFlag)" class="action-item"
                        (click)="confirmAndDelete(item)">
                        <img src="../../../../assets/img/icons/delete.svg" alt="Delete" title="Delete">
                      </div>
                      <div *ngIf="!isAdmin && !userAppFlag" class="action-item" (click)="saveAsApplication(item)">
                        <img src="../../../../assets/img/icons/save-as-application.svg"
                          alt="Create New Solution From Template" title="Create New Solution From Template">
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="filteredApplications.length===0" style="justify-content: center;">No Record Found</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isModalOpen">
  <app-modal [modalType]="'delete'" [isAdmin]="isAdmin" [selectedApplication]="selectedApplication"
    (emitCloseEvent)="closeModal($event)" (emitDeleteAction)="delete($event)"></app-modal>
</ng-container>
<!-- <ng-container *ngIf="isDialogOpen">
  <app-modal [modalType]="'dialog'" (emitCloseEvent)="closeDialog($event)" [selectedApplication]="selectedApplication"></app-modal>
</ng-container> -->
