import { Component, OnInit,Input,Output,EventEmitter,NgModule, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule,Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { IDEService } from 'src/app/shared/services/ide.service';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { UpdateDevice } from 'src/app/shared/models/device';
import { ToastrService } from 'ngx-toastr';
import { LabService } from 'src/app/shared/services/lab.service';
import { serachLab } from 'src/app/shared/models/lab';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { OrganizationsService } from 'src/app/shared/services/organizations.service';
import { searchOrganization } from 'src/app/shared/models/oarganization';



@Component({
  selector: 'app-add-lab-users',
  templateUrl: './add-lab-users.component.html',
  styleUrls: ['./add-lab-users.component.scss']
})
export class AddLabUsersComponent implements OnInit {

  orgName!: string;
  token!: any;
  showInfoPanel: boolean = false;
  savedDeviceName!: string;
  organizationName: string;
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  orgList: Array<any> = [];
  lablist: Array<any> = [];
  isLoading: boolean = false;
  @Input() organizationId: string = "";
  @Input() labId: string = "";
  userPermissions: string[];
  currentOrganization!: string;
  myForm: FormGroup;
  errorMessage: string = '';
  students:[]=[];
  instructors:[]=[];

  constructor(
    private router: Router,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private sessionStorageService: SessionStorageService,
    private organizationservice: OrganizationsService,
    private labService: LabService,
    private loaderService: LoaderService,
    private formbuilder:FormBuilder
  ) {
    this.setDefaultOrganization();
  }


  setDefaultOrganization() {
    this.currentOrganization = this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization !== environment.organizationID) {
      this.isGrokCloudMember = false;
      this.organizationName = this.currentOrganization;
    }
  }

  ngOnInit(): void {
    // this.userPermissions = sessionStorage.getItem('userPermissions').split(",");
    this.isSuperAdmin = window.location.href.includes('/admin/');
    this.searchorganization();
    this.myForm = this.formbuilder.group({
      studentList: [''],
      instructorList: [''],
      orgName: ['', Validators.required],
      labName: ['', Validators.required]
    }, { validators: this.atLeastOneRequiredValidator });
  }
  atLeastOneRequiredValidator(control: AbstractControl): ValidationErrors | null {
    if (!(control instanceof FormGroup)) {
      return null;
    }
    
    const studentList = control.get('studentList')?.value?.trim();
    console.log("students",studentList)
    const instructorList = control.get('instructorList')?.value?.trim();

    if (!studentList && !instructorList) {
      return { atLeastOneRequired: true };
    }
    return null;
  } 

  async onSubmit() {
    
    const username = this.sessionStorageService.getUsername();
    if (this.myForm.invalid) {
      this.myForm.markAllAsTouched();
      if (this.myForm.hasError('atLeastOneRequired')) {
        this.errorMessage = 'At least one optional field must contain a value.';
      } else {
        this.errorMessage = 'Please fill in all required fields.';
      }
      return;
    }
    else {
      this.errorMessage = '';
      console.log("myform",this.myForm)
      try {
        const alphaCharRegex = /[a-zA-Z]/;
  
        const studentList = this.myForm.get('studentList')?.value?.trim().split(',')
          .map((s: string) => s.trim())
          .filter((s: string) => s && alphaCharRegex.test(s));
  
        const instructorList = this.myForm.get('instructorList')?.value?.trim().split(',')
          .map((s: string) => s.trim())
          .filter((s: string) => s && alphaCharRegex.test(s));
        
        this.labId=this.myForm.get('labName')?.value?.trim();
        console.log("labid",this.labId)
        const payload = {
          organizationId: this.orgName,
          labId: this.labId,
          loggedInUser: username,
          students:studentList,
          instructors:instructorList
  
        }
        console.log("payload",payload)
        this.isLoading = true;
        const result: any = await this.labService.addLabUsers(payload).toPromise()
        if (result) {
          console.log("result from backend",result);
          this.token = result?.token;
          this.showInfoPanel = true;
          this.toasterService.success(`Lab Users created successfully`);
          this.isLoading = false;
          this.router.navigateByUrl('/admin/lab-users')
        }
      } catch (err: any) {
        console.log(err);
        this.isLoading = false;
        if (err.status == 403) {
          this.toasterService.error("You don’t have permission to perform this action")
        }
        else {
          this.toasterService.error(`${err.error.message}`);
        }
      }
      
    }
    
  }

 

 
  async searchorganization() {
    this.isLoading = true;
    try{
      const payload: searchOrganization = {
        isActive: true
      }
      const result: any = await this.organizationservice.search(payload).toPromise();
      this.isLoading = false
      if(result && result.organizations.length > 0 ){
        this.orgList = result.organizations;
      }
    }
    catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }
  async labsearch(orgName: any) {
    const getlabs: any = {} as serachLab;
    if (orgName !== "") {
      getlabs.orgName = orgName;
      getlabs.pageSize = 5000;
      this.isLoading = true;
      this.labService.search(getlabs).subscribe((response: any) => {
        this.lablist = response.labs;
        this.isLoading = false;
      })
    }
    else {
      this.lablist = [];
    }
  }
  async handleOrgChange(event: Event) {
    this.labsearch(((event.target as HTMLInputElement).value));
    const orgArray = this.orgList.filter((item: any) => item.orgName === (event.target as HTMLInputElement).value);
    const org = orgArray[0];

    this.orgName = org.orgId;

  }
  async handleLabChange(event: Event) {
    const labName = (event.target as HTMLInputElement).value;
    const labArray = this.lablist.filter((item: any) => item.labName === labName);
    const lab = labArray[0];
    this.labId = lab ? lab.labIdentifier : '';
  }

  cancel() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/lab-users')
    }  
  }

}



