import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CodeEditorModule } from "@ngstack/code-editor";
import { AngularSplitModule } from "angular-split";
import { SafePipesModule } from "src/app/shared/pipes/safe-pipe.module";
import { EditorScreenComponent } from './editor-screen/editor-screen.component';
import { OutputScreenComponent } from './output-screen/output-screen.component';
import { ReferencePythonComponent } from './reference-python.component';

@NgModule({
  declarations:[ReferencePythonComponent, EditorScreenComponent, OutputScreenComponent],
    imports:[CommonModule,FormsModule,AngularSplitModule,CodeEditorModule.forRoot(),SafePipesModule],
    exports:[ReferencePythonComponent,OutputScreenComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class ReferencePythonModule { }
