import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DeviceSessionsService } from 'src/app/shared/services/device-sessions.service';
import {
  AssociateUser,
  DissociateUser,
  ExtendAssociation,
  DeviceSessionsList
} from "src/app/shared/models/device-sessions";
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { ToastrService } from 'ngx-toastr';


export enum PopupActionTypes {
  ASSOCIATE = 'associate',
  DISSOCIATE = 'dissociate',
  EXTEND = 'extend'
}

enum Headers {
  ASSOCIATE = 'Associate Device to User',
  DISSOCIATE = 'Dissociate User',
  EXTEND = 'Extend Time'
}

enum ButtonNames {
  ASSOCIATE = 'Associate',
  DISSOCIATE = 'Yes',
  EXTEND = 'Extend'
}

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss']
})
export class ActionPopupComponent implements OnInit,OnChanges {

  @Input() actionType!: PopupActionTypes;
  @Input() sessionData!: DeviceSessionsList;
  @Output() emitCloseEvent = new EventEmitter();

  headerName:string='';
  btnName:string='';
  associatedUser:string='';
  timeLeft:any;
  messagePopup:any;
  message:any;
  showpopupforassociate:boolean = false;
  lmsUserId:any;
  requireduser:boolean;
  requiredtime:boolean;
  associateresponse:any;
  timeInMin:any;
  isDissociate:boolean=false;
  errorMessage: any;
  deviceForm=new FormGroup({
    lmsUser:new FormControl(''),
    timeInMin:new FormControl('')
  });
  currentUser!: string;

  constructor(
    private deviceSessionsService: DeviceSessionsService,
    private toasterService: ToastrService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUsername();
    this.associatedUser = this.sessionData.associatedUser;
    this.timeLeft = this.sessionData.timeLeft;

    switch (this.actionType) {
      case PopupActionTypes.ASSOCIATE: 
        this.headerName = Headers.ASSOCIATE;
        this.btnName = ButtonNames.ASSOCIATE;
        break;
      case PopupActionTypes.DISSOCIATE:
        this.isDissociate = true;
        this.headerName = Headers.DISSOCIATE;
        this.btnName = ButtonNames.DISSOCIATE;
        break;
      case PopupActionTypes.EXTEND:
        this.headerName = Headers.EXTEND;
        this.btnName = ButtonNames.EXTEND;
        break;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {}

  closeModal(actionType: string) {
    document.getElementById(actionType).style.display = 'none';
    this.emitCloseEvent.emit(false);
  }
  // deleteData(event: any) {
  //   this.emitDeleteAction.emit(true);
  // }

  onActionClick (name: string) {
    switch (name) {
      case ButtonNames.ASSOCIATE:
        if(!this.deviceForm.value.lmsUser && !this.deviceForm.value.timeInMin){   
          this.requireduser = true;
          this.requiredtime = true;
        }
        else if(!this.deviceForm.value.lmsUser){
          this.requireduser = true;
        }
          else if(!this.deviceForm.value.timeInMin){
            this.requiredtime = true;
          }
          else{
          this.associateUser();
          this.requiredtime = false;
          this.requireduser = false;
        }
        break;
      case ButtonNames.DISSOCIATE:
        this.dissociateUser();
        break;
      case ButtonNames.EXTEND:
        this.extendSession();
        break;
    }
  }
  

  focustime(){
    this.requiredtime = false;
  }

  focususer(){
    this.requireduser = false;
    this.errorMessage = "";
  }

  async associateUser() {
      try {
        let registeredUsernames = this.sessionData.registeredUsernames || [];
        if (!registeredUsernames.includes(this.deviceForm.value.lmsUser)) registeredUsernames.push(this.deviceForm.value.lmsUser);
        const payload: AssociateUser = {
          username: this.deviceForm.value.lmsUser,
          timeAllottedInMins: parseInt(this.deviceForm.value.timeInMin),
          createdBy: this.currentUser,
          registeredUsernames: registeredUsernames
        }
        this.associateresponse = await this.deviceSessionsService.associate(payload, this.sessionData.deviceToken).toPromise();
        if(this.associateresponse.success){
          console.log(this.associateresponse.message);
          this.toasterService.success("User successfully associated with device");
          this.closeModal(this.actionType);
        }
        
      } catch (e: any) {
        console.log(e);
        if(e.error.message  == "Mandatory fields missing"){
          console.log(e.error.message);
          this.toasterService.error('All fields are Required');
        }else if(e.status == 422 || e.status == 401){
          this.requireduser = true;
          this.errorMessage = e.error.message;
        }else {
          this.toasterService.error(`${e.error.message}`);
          this.closeModal(this.actionType);
        }
      }
      
  }

  dissociateUser() {
    try {
      const payload: DissociateUser = {
        username: this.deviceForm.value.lmsUser,
        updatedBy: this.currentUser
      }
      this.deviceSessionsService.dissociate(payload, this.sessionData.deviceToken).toPromise();
    } catch (e) {
      console.log(e);
    }
    setTimeout(()=>{                           
      this.closeModal(this.actionType);}, 250);
  }

  extendSession() {
    try {
      const payload: ExtendAssociation = {
        username: this.deviceForm.value.lmsUser,
        additionalTimeAllottedInMins: parseInt(this.deviceForm.value.timeInMin),
        updatedBy: this.currentUser
      }
      this.deviceSessionsService.extend(payload, this.sessionData.deviceToken).toPromise();
    } catch (e) {
      console.log(e);
    }
    setTimeout(()=>{                           
      this.closeModal(this.actionType);}, 250);
  }
}
