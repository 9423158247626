
<div [id]="actionType" class="d">
    <div class="overlay bg-black w-full h-full fixed top-0 left-0 opacity-50 z-50 text-4xl">
    </div>
    <div class="popup transition duration-700 ease-in-out fixed right-0 top-0  overflow-auto z-50 h-full bg-white shadow-xl modal" [class.action-dissociate]="isDissociate">
        <!-- dialog header start -->
        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white paragraph">
                {{headerName}}
            </h3>
            <button (click)="closeModal(actionType)" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>
        </div>
        <!-- dialog header end -->
        <div class="p-4 relative" [formGroup]="deviceForm">
            <!-- dialog Body For creating New Variable  for associate -->
            <div *ngIf="actionType==='associate'">
                <form action=""></form>
                <div>
                    <h4 class="p-4 text-md font-medium">Enter LMS User ID <span  style="color: rgb(250, 0, 50);">*</span></h4>
                    <input class="input-style" type="text" formControlName="lmsUser" (focus)="focususer()" [ngClass]="{'is-invalid': requireduser}" required>
                    <span *ngIf="requireduser" class="invalid-feedback" style="color: rgb(250, 0, 50);">{{errorMessage}}</span>
                </div>
                <div >
                    <h4 class="p-4 text-md font-medium">Enter Time in Minutes <span style="color: rgb(250, 0, 50);">*</span></h4>
                    <input class="input-style" type="number" formControlName="timeInMin" (focus)="focustime()" [ngClass]="{'is-invalid': requiredtime}" required>
                </div>
            </div>
            <!-- dialog Body For creating New Variable  for extend -->
              <div *ngIf="actionType==='extend'">
                <div>
                    <h4 class="p-4 text-md font-medium">
                        Looks like, {{associatedUser}} has {{timeLeft}} Minutes left in his session.       
                        <br>How much would you like to extend his time by?
                    </h4>
                </div>
                <div >
                    <h4 class="p-4 text-md font-medium">Enter Time in Minutes</h4>
                    <input class="input-style" type="text" id="varible_name" formControlName="timeInMin">
                </div>
            </div>
            <!-- dialog Body For creating New Variable  for dissociate -->
            <div *ngIf="actionType==='dissociate'">
                <div class="p-4">
                    <span class="dissociate">
                        Are you sure you want to dissociate the user?
                    </span>
                </div>
            </div>
            <!-- dialog Action buttons  -->
            <div class="p-4 flex justify-center btn-mt">
                <button class="bg-greenish-blue btn-1-wh text-white p-2 rounded-md mr-2" id="create-button" (click)="onActionClick(btnName)">
                    <span class="btn-1">{{btnName}}</span>
                </button>
                <button class="text-gray-600 btn-2-wh p-2 rounded-md mr-2" type="button" (click)="closeModal(actionType)">
                    <span class="btn-1">CANCEL</span>
                </button>
            </div>
        </div>
    </div>
</div>
