import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthService } from "src/app/shared/services/auth.service";
import { setUser } from "src/app/store/auth/auth.action";
import { IAppState } from "src/app/store/index.state";
import { showLayout } from "src/app/store/layout/layout.action";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  username!: string;
  password!: string;
  // showLoader$ = this.store.select(spinnerSelector);
  showLoader = false;
  showErrorMessage = false;
  errorMessage = "";
  isValid: boolean;


  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private router: Router,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(showLayout({ value: false }));
  }
  /**
   *  @return
   * true if Administrator OR Content Manager
   */
async onSubmit() {
    const payload = {
      username: this.username,
      password: this.password,
    };

    try {
      this.showLoader = true;
      let data: any = await this.authService
        .authenticateLMS(payload.username, payload.password)
        .toPromise();
      data['username'] = this.username;

      const isValidUserPayload = {
        userId : this.username
      }

      const result: any = await this.userSessionService.isValidUser(isValidUserPayload).toPromise();
      this.isValid = result.isValidUser;

      if (data.status && this.isValid) {
        sessionStorage.clear();
        // localStorage.clear();
        sessionStorage.setItem("affiliate_name", data.affiliate_name);
        sessionStorage.setItem("token", data.session_id);
        sessionStorage.setItem("user", JSON.stringify(data));
        this.store.dispatch(setUser({ user: data }));
        this.store.dispatch(setUser({ user: data }));
        
        let verifyUserResponse: any = await this.userSessionService.verifyUserLogin(data.username, this.sessionStorageService.getCurrentOrganization()).toPromise();
        localStorage.setItem('sessionId', verifyUserResponse.sessionId);
        this.router.navigateByUrl("/admin");
      } else {
        this.showErrorMessage = true;
        this.errorMessage = "Invalid username or password.";
      }
    } catch (err) {
      this.showErrorMessage = true;
      this.errorMessage = "Some error occurred, we are looking into it";
    } finally {
      this.showLoader = false;
    }
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
  }
}