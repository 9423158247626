import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDEService } from 'src/app/shared/services/ide.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormsModule } from '@angular/forms';
import { LabService } from 'src/app/shared/services/lab.service';
import { SearchDevices } from 'src/app/shared/models/device';
import {
  PAGE_NUMBER,
  PAGE_SIZE,
  DESC,
  ASC,
} from 'src/app/shared/constants/utils.constants';
import { OrganizationsService } from 'src/app/shared/services/organizations.service';
import { searchOrganization } from 'src/app/shared/models/oarganization';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DevicesService } from 'src/app/shared/services/devices.service';
import { Lab, searchLabByOrg, SearchLabUsers, serachLab, updateLabUsers } from 'src/app/shared/models/lab';

@Component({
  selector: 'app-lab-users',
  templateUrl: './lab-users.component.html',
  styleUrls: ['./lab-users.component.scss'],
})
export class LabUsersComponent implements OnInit {
  originalDeviceData: any;
  hasLabUsers: boolean = false;
  showSidePanel!: boolean;
  selectedUser!: any;
  isModalOpen: boolean = false;
  isLoading: boolean = false;
  isActive: boolean;
  setLabDropDown: boolean = false;
  changeLabValueToEmpty: boolean = false;
  changeRoleValueToEmpty: boolean = false;
  deleteselectedUser: any = null;
  searchedUsers: any;
  orgList: searchOrganization[] = [];
  roleTypes: string[] = ['Student', 'Instructor'];
  searchControl = new FormControl(null);
  SortByTypes = {
    USER_NAME: 'lu.user_id',
    ORG_NAME: 'o.organization_name',
    LAB_NAME: 'l.lab_name',
    updatedTime: 'lu.updated_at',
    isInstructor: 'lu.is_instructor',
    isStudent: 'lu.is_student',
  };
  searchValue = new FormControl(null);
  organizationName: any;
  currentOrganization!: string;
  isSuperAdmin: boolean = true; //used to determine navigation urls
  isGrokCloudMember: boolean = true;
  alert: string = '';
  isDevicesDeleted: boolean = false;
  moment: any = moment;
  userPermissions: string[];
  labSelect = new FormControl('');
  labIdentifier: string;
  roleType: string;
  roleSelect = new FormControl('');
  // isDeleted:false;
  toSendNullValue: boolean;
  defaultOrganizationName: string;
  labList: Lab[] = [];
  selectedLab: string;
  // isStudent: boolean;
  // isInstructor: boolean;
  constructor(
    private router: Router,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private labService: LabService,
    private orgService: OrganizationsService,
    private sessionStorageService: SessionStorageService,
    private deviceService: DevicesService
  ) {
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization =
      this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization !== environment.organizationID) {
      this.isGrokCloudMember = false;
      this.toSendNullValue = true;
      this.defaultOrganizationName = this.currentOrganization;
    }
  }

  ngOnInit(): void {
    // this.userPermissions = sessionStorage.getItem('userPermissions').split(',');
    this.searchOrganizations();
    this.searchAndSortLabUsers();
    this.getLabs();
    this.labSelect.setValue('');

    // this.getDevices();
    this.selectedLab = "";
  }

  navToInstructions() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/connect-device');
    } else {
      this.router.navigateByUrl('/ide/connect-device');
    }
  }

  async searchOrganizations() {
    try {
      this.isLoading = true;
      const payload: searchOrganization = {
        isActive: true,
      };
      const result: any = await this.orgService.search(payload).toPromise();
      this.isLoading = false;
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }
  onStudentChange(event: Event, item: any) {
    const input = event.target as HTMLInputElement;
    item.isStudent = input.checked;
  }
  onInstructorChange(event: Event,item:any) {
    const input = event.target as HTMLInputElement;
    item.isInstructor = input.checked;
  }

  async updateLabUser(item:any) {
    const updatedUser = {
      userId: item.userName,
      isStudent: item.isStudent,
      isInstructor: item.isInstructor,
      labUserId: item.labUserId,
      loggedInUser: this.sessionStorageService.getUsername(),
      
    };
    this.isLoading = true;
  try {
    // Perform the update operation
    await this.labService.updateLabUser(updatedUser).toPromise();
    this.toasterService.success("User updated successfully");

    // Refresh the user data by calling searchAndSortLabUsers
    await this.searchAndSortLabUsers();

  } catch (error) {
    this.toasterService.error("Failed to update user");
  } finally {
    this.isLoading = false;
  }
    // this.isLoading = true;
    // this.labService.updateLabUser(updatedUser).subscribe(
    //   (response) => {
    //     this.toasterService.success('User updated successfully');
    //     this.searchAndSortLabUsers();
    //     console.log("response",response)
    //     // item.isStudent = response.isStudent;
    //     // item.isInstructor = response.isInstructor;
    //     this.isLoading = false;
    //   },
    //   (error) => {
    //     this.toasterService.error('Failed to update user');
    //     this.isLoading = false;
    //   }
    // );
  }

  copyToken(token: string) {
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = token;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.toasterService.success(`${token} is copied!`);
  }

  openselectedUser(selectedUser: any) {
    this.showSidePanel = true;
    this.selectedUser = selectedUser;
  }

  async delete(selectedUser: any) {
    if (selectedUser) {
      if (this.deleteselectedUser.deviceIdentifier) {
        this.deleteUserDevice(selectedUser);
      }
    }
  }

  async deleteUserDevice(selectedUser: any) {
    const { deviceIdentifier, deviceName } = this.deleteselectedUser;
  }

  closeModal(event: any) {
    this.deleteselectedUser = null;
    this.isModalOpen = false;
  }

  async confirmAndDelete(item: any) {
    this.isLoading = true;
    this.deleteselectedUser = item;
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.showSidePanel = false;
      this.selectedUser = null;
      this.searchAndSortLabUsers();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.searchAndSortLabUsers('', '', filterValue, PAGE_NUMBER, PAGE_SIZE);
  }

  isUserName: boolean = true;
  changeOrduserName(order: any) {
    this.isUserName = !this.isUserName;
    this.searchAndSortLabUsers(
      this.SortByTypes.USER_NAME,
      order,
      '',
      PAGE_NUMBER,
      PAGE_SIZE
    );
  }

  isUpdatedTime: boolean = true;
  changeUpdateTime(order: any) {
    this.isUpdatedTime = !this.isUpdatedTime;
    this.searchAndSortLabUsers(
      this.SortByTypes.updatedTime,
      order,
      '',
      PAGE_NUMBER,
      PAGE_SIZE
    );
  }

  isOrgName: boolean = true;
  changeOrdOrgName(order: any) {
    this.isOrgName = !this.isOrgName;
    this.searchAndSortLabUsers(
      this.SortByTypes.ORG_NAME,
      order,
      '',
      PAGE_NUMBER,
      PAGE_SIZE
    );
  }

  isLab: boolean = true;
  changeOrdLab(order: any) {
    this.isLab = !this.isLab;
    this.searchAndSortLabUsers(
      this.SortByTypes.LAB_NAME,
      order,
      '',
      PAGE_NUMBER,
      PAGE_SIZE
    );
  }

  onChange(event: any) {
    if (event.target.value != '') {
      this.organizationName = event.target.value;
      console.log("org name onchnage",this.organizationName)
      this.getLabs();
      this.labSelect.setValue('');
      this.selectedLab = "";
      this.changeLabValueToEmpty = true;
      // this.searchAndSortLabUsers();
    } else {
      this.organizationName = null;
      this.changeLabValueToEmpty = true;
      this.labSelect.setValue('');
      this.selectedLab = "";
      this.getLabs();
      this.labIdentifier = null;
      // this.searchAndSortLabUsers();
    }
  }

  onChangeLab(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue !== '') {
      this.labIdentifier = selectedValue ? selectedValue : '';
      this.changeLabValueToEmpty = false;
      this.labSelect.setValue(selectedValue);
    } else {
      this.labIdentifier = null;
    }
    this.searchAndSortLabUsers();
  }
  onChangeRole(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue !== '') {
      this.roleType = selectedValue ? selectedValue : '';
      this.changeRoleValueToEmpty = false;
      this.roleSelect.setValue(selectedValue);
    } else {
      this.roleType = null;
    }
    this.searchAndSortLabUsers();
  }

  async getLabs() {
    try {
      this.isLoading = true;
      const payload: searchLabByOrg = {
        orgName: this.toSendNullValue ? this.defaultOrganizationName : (this.organizationName ? this.organizationName : null)
      }
      const result: any = await this.labService.searchLabByOrg(payload).toPromise();
      if (result && result.labs.length == 1) {
        this.labList = result.labs;
        this.selectedLab = result.labs[0].labIdentifier;
        this.labIdentifier = result.labs[0].labIdentifier;
      } else if (result && result.labs.length > 0) {
        this.labList = result.labs;
        this.selectedLab = "";
        this.labSelect.setValue('');
        this.labIdentifier = null;
      } else if (result && result.labs.length == 0) {
        //this.toasterService.error("No Labs Found");
        this.labList = [];
        this.selectedLab = "";
        this.labSelect.setValue('');
        this.labIdentifier = null;
      } else {
        this.labList = [];
        this.selectedLab = "";
        this.labSelect.setValue('');
        this.labIdentifier = null;
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
    this.searchAndSortLabUsers();
  }

  async searchAndSortLabUsers(
    sortBy?: string,
    sortOrder?: string,
    searchVal?: string,
    pageNumber?: number,
    pageSize?: number
  ) {
    
    try {
      this.isLoading = true;
      const getAllUsers = {} as SearchLabUsers;
      (getAllUsers.orgName = this.toSendNullValue
        ? this.defaultOrganizationName
        : this.organizationName
        ? this.organizationName
        : null),
        (getAllUsers.labId =  this.labIdentifier);
      getAllUsers.roleType = this.changeRoleValueToEmpty ? null : this.roleType;
      getAllUsers.searchString = searchVal ? searchVal : this.searchValue.value;
      getAllUsers.orderBy = [
        {
          sortBy: sortBy ? sortBy : this.SortByTypes.USER_NAME,
          sortOrder: sortOrder ? sortOrder : ASC,
        },
      ];
      
      const result: any = await this.labService
        .searchUsers(getAllUsers)
        .toPromise();
      if (result) {
        this.searchedUsers = result.labUsers;
        this.originalDeviceData = this.searchedUsers;
        this.hasLabUsers = result.labUsers.length === 0 ? false : true;
        this.isLoading = false;
      }
    } catch (error: any) {
      if (error.status === 403) {
        this.toasterService.error("You don’t have permission to perform this action")
        // showForbiddenError(this.toasterService);
      } else {
        this.toasterService.error(error.error.message);
      }
      this.isLoading = false;
      console.log(error);
      this.searchedUsers = [];
    }
  }

  // isMenuOpen: boolean = false;
  // toggleMenu() {
  //   this.isMenuOpen = !this.isMenuOpen
  // }

  // isDeviceMenuOpen: boolean = false;
  // toggleDeviceMenu() {
  //   this.isDeviceMenuOpen = !this.isDeviceMenuOpen;
  // }
  // onSelectModule(flag: string) {
  //   if (flag === 'lab') {
  //     if (this.isSuperAdmin) {
  //       this.router.navigateByUrl("/admin/device/lab/add");
  //     } else {
  //       this.router.navigateByUrl("/ide/device/lab/add");
  //     }
  //   }
  //   else {
  //     this.showSidePanel = false;
  //     if (this.isSuperAdmin) {
  //       this.router.navigateByUrl("/admin/device/user/add");
  //     } else {
  //       this.router.navigateByUrl("/ide/device/user/add");
  //     }
  //   }
  // }

  navigateToAddUser() {
    this.router.navigateByUrl('/admin/lab-users/user/add');
  }

  async getDevices() {}
}
