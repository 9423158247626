<div class="p-8">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="users-mng">
        Manage Users
    </div>
    <div class="box">
        <div class="relative w-full btn-1 my-6">
            <label class="flex absolute inset-y-0  items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                        stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </label>
            <input type="text" id="simple-search" class="br-1 btn-1 border-gray-200 users-name block p-2.5"
                placeholder=" Search by User Name" style="padding-left: 35px;" (keyup)="applyFilter($event)"
                [formControl]="searchControl">
        </div>
        <div class="push my-6 mr-2">
            <select name="usersNames" id="" class="btn-2 border-gray-200 rounded-lg mb-5 mx-2 input-style users-name"
                (change)="onChangeStatus($event)">
                <option value="" class="users-name">All</option>
                <ng-container>
                    <option *ngFor="let option of dropdownStatus" [value]="option">{{ option }}</option>
                </ng-container>
            </select>
        </div>
        <div class="my-6 text-right">
            <button class="btn-3 bg-greenish-blue text-white p-2 rounded-lg shadow-xl hover:bg-red-600"
                title="Create User" (click)="addUsers()">
                Add Users
            </button>
        </div>
    </div>
    <div class="min-h-screen flex">
        <div class="overflow-x-auto w-full">
            <div class="min-w-screen min-h-screen table-wrapper flex justify-center font-sans">
                <div class="w-full">
                    <div class="bg-white shadow-md rounded my-6">
                        <table
                            class="mx-auto max-w-full w-full whitespace-nowrap bg-white divide-y divide-gray-300 overflow-hidden">
                            <thead class="bg-greenish-blue text-white">
                                <tr class="text-white-600 uppercase text-sm leading-normal">
                                    <th class="text-left font-semibold text-sm uppercase px-2 py-4" style="width: 30%;">
                                        <div class="flex inline-flex items-center">
                                            <span>
                                                User Name
                                            </span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase px-2 py-4" *ngIf="showUpdatedColumns">
                                        <div class="flex inline-flex items-center">
                                            <span>Updated By</span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase px-2 py-4" *ngIf="showUpdatedColumns" >
                                        <div class="flex inline-flex items-center">
                                            <span>Updated At</span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase px-2 py-4" *ngIf="showDeletedColumns">
                                        <div class="flex inline-flex items-center">
                                            <span>Deleted By</span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase px-2 py-4" *ngIf="showDeletedColumns">
                                        <div class="flex inline-flex items-center">
                                            <span>Deleted At</span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase px-2 py-4">
                                        <div class="flex inline-flex items-center">
                                            <span>Status</span>
                                        </div>
                                    </th>
                                    <th class="text-center font-semibold text-sm uppercase">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                <tr class="border-b border-gray-200 hover:bg-gray-100"
                                    *ngFor="let item of usersList; let i = index">
                                    <td class="text-left  whitespace-nowrap py-3 px-2 cursor-pointer"
                                        style="width: 30%;">
                                        <div class="font-bold">{{item.userName}}</div>
                                    </td>
                                    <td class="text-center light-text" *ngIf="showUpdatedColumns">
                                        <div [class.non-clickable-link]="">{{item.updatedUser}}</div>
                                    </td>
                                    <td class="text-center whitespace-nowrap py-3 px-2" *ngIf="showUpdatedColumns"
                                        [style.text-transform]='"uppercase"' title="dd/MM/yyyy hh:mm">
                                        <span>{{ item.updatedTime | date:'dd/MM/yyyy'}} </span>
                                        <span class="ml-2">{{ item.updatedTime | date:'hh:mm a'}}</span>
                                    </td>
                                    <td class="text-center light-text" *ngIf="showDeletedColumns">
                                        <div [class.non-clickable-link]="">{{item.deletedUser}}</div>
                                    </td>
                                    <td class="text-center whitespace-nowrap py-3 px-2"
                                        [style.text-transform]='"uppercase"' title="dd/MM/yyyy hh:mm" *ngIf="showDeletedColumns">
                                        <span>{{ item.deletedTime | date:'dd/MM/yyyy'}} </span>
                                        <span class="ml-2">{{ item.deletedTime | date:'hh:mm a'}}</span>
                                    </td>
                                    <td class="text-center whitespace-nowrap py-4 px-2">
                                        <button (click)="updateUsers(item)" [disabled]="item.isDeleted" [ngClass]="item.isDeleted ? 'cursorNone' : ''">
                                        <span class="green-tag" style="padding: 5px 22px;" title="Inactivate user"
                                            *ngIf="item.isActive">
                                            Active
                                        </span>
                                        <span class="red-tag" *ngIf="!item.isActive" title="Activate user">
                                            In Active
                                        </span>
                                    </button>
                                    </td>
                                    <td *ngIf="!item.isDeleted" class="text-center whitespace-nowrap delete_btn_icon py-4 px-2">
                                        <div class="action-item cursor-pointer mx-2" (click)="deleteUser(item, true)">
                                          <img src="../../../../assets/img/icons/delete.svg" alt="Delete" title="Delete">
                                        </div>
                                      </td>
                                      <td *ngIf="item.isDeleted" class="text-center whitespace-nowrap delete_btn_icon py-4 px-2">
                                        <div class="action-item  cursor-pointer mx-2" (click)="deleteUser(item, false)">
                                          <img class="revoke_icon" src="../../../../assets/img/icons/delete.revoke.png" title="Revoke Delete">
                                        </div>
                                      </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>