import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AngularSplitModule } from 'angular-split';
import { FileSaverModule, FileSaverService } from 'ngx-filesaver';
import { BlocklyModule } from 'src/app/shared/components/blockly/blockly.module';
import { getToolbox } from 'src/app/shared/utils/toolbox';
import { ToastrService } from 'ngx-toastr';
import { IDEService } from 'src/app/shared/services/ide.service';
import { WsApplicationService } from 'src/app/shared/services/ws-application.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/index.state';
import { showSidePanel } from 'src/app/store/layout/layout.action';
import { sidePanelSelector } from 'src/app/store/layout/layout.selector';
import { Subscription } from 'rxjs';
import { SidePanelModule } from 'src/app/components/side-panel/side-panel.module';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { BlocklyComponent } from 'src/app/shared/components/blockly/blockly.component';
import { PythonDirectModule } from '../../container/python-direct/python-direct.module';
import { BlockComparisonResult } from 'src/app/shared/models/block';

@Component({
  selector: 'app-blockly-section',
  templateUrl: './blockly-section.component.html',
  styleUrls: ['./blockly-section.component.scss'],
})
export class BlocklySectionComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() ieeeEvent : any;
  @Input() applicationIdentifier!: string;
  @Input() selectedApplication: any;
  @Input() switchType: any = 'block';
  @Input() code: any;
  @Input() toggle: any;
  @Input() isAdmin: boolean = false;
  @Input() moduleKey!: string;
  @Input() parentComponent: string = BlocklySectionComponent.name;
  @Output() emitCodeEvent = new EventEmitter();
  @Output() emitXMLEvent = new EventEmitter();
  @Output() emitBlockState = new EventEmitter();
  @Output() switchTypeChange = new EventEmitter();

  // Block comparison variables
  isCompareRequested: boolean = false;
  @Input() isCompareResult: BlockComparisonResult =
    BlockComparisonResult.Default;
  @Output() rightBlockCodeEmitter = new EventEmitter();
  @Output() compareBlockEmitter = new EventEmitter();
  @Output() resetCompareBlockResultEmitter = new EventEmitter();

  @Output() getGeneratedCode = new EventEmitter();

  showSidePanel$ = this.store?.select(sidePanelSelector);
  isTemplate: boolean = window.location.href.includes('/template');

  wsSubscription!: Subscription;
  toolbox!: string;
  size: any;
  generatedCode: string = '';
  generatedCode_list: string[] = [];
  showSelectOptions: boolean = false;
  isCodePresent: boolean = false;
  buttonActive: string = 'opacity-100';
  buttonInActive: string = 'opacity-50';
  isDirect = false;
  isRPIConnected = false; // is RPI Registered
  isBlockWithSimulator: boolean = false;
  isDeviceOnline!: boolean;
  hostName: string = '';
  downloadTo: string = '';
  level: any = 'beginner';
  downloadOptions!: any;
  isBlock: boolean = false;
  current_username: string;
  isCompiled: boolean = false;
  public deviceId!: string;
  private statusCollected = false;
  private lastStoreTimeStamp!: number;

  @ViewChild(BlocklyComponent)
  blocklyComponent!: BlocklyComponent;

  constructor(
    private fileSaverService: FileSaverService,
    private toasterService: ToastrService,
    private ideService: IDEService,
    private store: Store<IAppState>,
    private wsService: WsApplicationService,
    private sessionStorageService: SessionStorageService
  ) {
    this.current_username = this.sessionStorageService.getUsername();
    this.isBlock = (
      window.location.href.includes('/ide/block') ||
      window.location.href.includes('/ide/application/block') ||
      window.location.href.includes('/ide/template/block')
    );
  }

  async ngOnInit(): Promise<void> {
    this.isDirect = window.location.href.includes('block-direct');
    this.level = sessionStorage.getItem('module_level');
  }

  ngAfterViewInit(): void {
    // console.log("bockly component ",this.blocklyComponent)
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.switchType?.currentValue === 'CODE') {
      this.isCompiled = true;
      if (!this.statusCollected && !this.isAdmin) {
        await this.getConnectedDevice();
      }
    } else if (changes?.switchType?.currentValue.toLowerCase() === 'block') {
      this.isCompiled = false;
    }
    if (changes.moduleKey?.currentValue) {
      this.toolbox = getToolbox(this.moduleKey);
      await this.getRPIStatus();
      this.setDownloadOptions();
    }
    if (
      this.isCompareRequested === true &&
      changes?.isCompareResult?.currentValue
    ) {
      await this.verifyAndDownloadPart2();
      this.resetCompareBlockResultEmitter.emit({});
    }
  }

  private setDownloadOptions() {
    let options: any;
    if(this.ieeeEvent){
      switch (this.moduleKey) {
        case 'robotics':
        case 'iot':
        case 'aiot':
        case 'iiot':
          options = [
            {
              key: 'device',
              display: 'On Laptop/Desktop',
            }
          ];
          this.isCodePresent = false;
          break;
        case 'data_science':
        case 'artificial_intelligence':
          options = [
            {
              key: 'script',
              display: 'Python Script',
            },
          ];
          this.isCodePresent = true;
          break;
        default:
          options = [
            {
              key: 'script',
              display: 'Python Script',
            },
          ];
          break;
      }
    }else{
      switch (this.moduleKey) {
        case 'robotics':
        case 'iot':
        case 'aiot':
        case 'iiot':
          options = [
            {
              key: 'device',
              display: 'On Laptop/Desktop',
            },
            {
              key: 'raspberry-pi',
              display: 'On Grok IoT Gateway',
            },
          ];
          this.isCodePresent = false;
          break;
        case 'data_science':
        case 'artificial_intelligence':
          options = [
            // {
            //   key: "pdf",
            //   display: "As PDF"
            // },
            // {
            //   key: "notebook",
            //   display: "Jupyter Notebook"
            // },
            {
              key: 'script',
              display: 'Python Script',
            },
          ];
          this.isCodePresent = true;
          break;
        default:
          options = [
            // {
            //   key: "device",
            //   display: "On Device"
            // },
            // {
            //   key: "raspberry-pi",
            //   display: "On Raspberry-Pi"
            // }
            {
              key: 'script',
              display: 'Python Script',
            },
          ];
          break;
      }
  }
    this.downloadOptions = options;
  }

  onCode(event: string) {
    this.generatedCode = '';
    this.generatedCode = event;
    if (this.generatedCode !== '') {
      this.isCodePresent = true;
      this.generatedCode_list = this.generatedCode.split('\n');
      // console.log("generated code from block",this.generatedCode_list)
      this.getGeneratedCode.emit(this.generatedCode_list);
    } else {
      this.isCodePresent = false;
      this.generatedCode_list = [];
      this.showOptions(false);
    }

    if (this.isAdmin || this.isDirect) {
      this.isCodePresent = true;
    }
    this.emitRightBlock();
  }
  toggleBlockState(state: boolean) {
    this.isBlockWithSimulator = state;
    this.emitBlockState.emit(this.isBlockWithSimulator);
  }

  sidePanelClosed(event: any) {
    if (event) {
      this.store.dispatch(showSidePanel({ value: false }));
      this.selectedApplication = null;
    }
  }

  async getRPIStatus() {
    if (!this.isAdmin && ['data_science', 'artificial_intelligence'].indexOf(this.moduleKey) === -1) {
      this.ideService
        .getRPIStatus(this.current_username)
        .then((result: any) => {
          this.isRPIConnected = !!result?.result?.length;
          this.hostName = result.result[0].hostname;
          this.deviceId = result.result[0].deviceIdentifier;
          this.createWebSocketConnection();
        })
        .catch((err) => console.log(err));
    }
  }

  createWebSocketConnection() {
    const appId = this.deviceId;
    if (appId && !this.isAdmin) {
      this.wsSubscription = this.wsService
        .createObservableCircuit(appId)
        .subscribe(async (data: any) => {
          let parsedData = JSON.parse(data);
          if (parsedData) {
            this.lastStoreTimeStamp = parsedData.timestamp;
          }
        });
    }
  }

  async getConnectedDevice() {
    try {
      const result: any = await this.ideService
        .getDeviceStatus(this.deviceId)
        .toPromise();
      if (result) {
        this.statusCollected = true;
        setInterval(() => {
          this.isDeviceOnline =
            (new Date().getTime() - this.lastStoreTimeStamp) / 1000 < 15;
        }, 1000);
      }
    } catch (error) {}
  }

  /**
   * @deprecated The method should not be used
   */
  convertCodeToDiv() {
    return this.generatedCode.split('\n');
  }

  onDrag(event: any) {
    this.size = event.sizes;
  }

  showOptions(data: boolean) {
    this.showSelectOptions = data;
  }

  private isCodeVerified(userCode: any): boolean {
    if (this.isAdmin || this.isDirect) {
      return true;
    }

    const code = JSON.stringify(this.code);
    if (userCode === null || undefined || '') {
      return false;
    }
    if (userCode === code) {
      return true;
    }

    return false;
  }

  async verifyAndDownloadPart1(downloadTo: any) {
    this.downloadTo = downloadTo;
    this.isCompareRequested = true;
    // Initiate request to compare blocks before downloading
    await this.emitCompareBlock();

    if (!this.isBlock) {
      this.isCompareResult = BlockComparisonResult.Success;
      this.verifyAndDownloadPart2();
    }
  }

  async verifyAndDownloadPart2() {
    // const userCode = JSON.stringify(this.generatedCode);
    if (this.isCompareResult === BlockComparisonResult.Error) {
      if (
        this.level === 'beginner' ||
        this.level == null ||
        this.level == 'null'
      ) {
        this.cancelDownload();
      } else {
        this.store.dispatch(showSidePanel({ value: true }));
      }
    } else if (this.isCompareResult === BlockComparisonResult.Success) {
      this.downloadCode();
    }
    this.showOptions(false);
    this.isCompareResult = BlockComparisonResult.Default;
    this.isCompareRequested = false;
  }

  downloadCode() {
    if (this.downloadTo === 'device' || this.downloadTo === 'script') {
      this.downloadPythonCode();
    } else {
      this.downloadToRaspberry();
    }
    this.switchType = 'BLOCK';
    this.switchTypeChange.emit(this.switchType);
    window.dispatchEvent(new Event('resize'));
  }

  cancelDownload() {
    const userCode = JSON.stringify(this.generatedCode);
    if (this.downloadTo === 'raspberry-pi' && (!this.isDeviceOnline || !this.isRPIConnected)) {
      if(!this.isRPIConnected) {
        this.toasterService.error('Raspberry Pi is not registered');
      } else if(!this.isDeviceOnline) {
        this.toasterService.error('Raspberry Pi is offline');
      }
    } else if (!this.isCodeVerified(userCode)) {
      this.toasterService.error("Code does not match");
      this.switchType = "BLOCK";
      this.switchTypeChange.emit(this.switchType);
      window.dispatchEvent(new Event("resize"));
    }
    this.switchType = 'BLOCK';
    this.switchTypeChange.emit(this.switchType);
    window.dispatchEvent(new Event('resize'));
    this.store.dispatch(showSidePanel({ value: false }));
  }

  private downloadPythonCode() {
    this.toasterService.success('Code is Downloaded');
    this.fileSaverService.saveText(this.generatedCode, 'code.py');
    this.store.dispatch(showSidePanel({ value: false }));
  }

  private async downloadToRaspberry() {
    if (!this.isDeviceOnline) {
      this.toasterService.error('Raspberry Pi is offline');
      this.store.dispatch(showSidePanel({ value: false }));
      return;
    }
    try {
      const blob = new Blob([this.generatedCode], {
        type: 'text/x-python-script',
      });
      const file = new File([blob], 'code.py', {
        type: 'text/x-python-script',
      });
      let result = await this.initiateUpload(file);
      if (result) {
        const appName = result['applicationName'];
        let toast_msg = `The code is downloaded. \n
        Location: /Desktop/Grok-Downloads/${appName}/code.py`;
        this.toasterService.success(toast_msg);
      } else {
        let toast_msg = `Download failed`;
        this.toasterService.error(toast_msg);
      }
    } catch (error) {
      // Nothing to do here...
    } finally {
      this.store.dispatch(showSidePanel({ value: false }));
    }
  }

  private async initiateUpload(file: File) {
    try {
      const initiationResult: any = await this.ideService
        .initiateUpload(file, this.current_username)
        .toPromise();
      const link = initiationResult['link']; // S3 URL to upload
      const key = initiationResult['key']; // Filename
      await this.ideService.uploadToS3(file, link).toPromise();

      const appId: any =
        sessionStorage.getItem('app_id') ||
        this?.selectedApplication?.application_identifier ||
        new Date().getTime();
      let response: any = await this.ideService
        .generateCodeDownloadLink(appId, key, this.current_username)
        .toPromise();
      return response;
    } catch (error: any) {
      this.toasterService.error(error.error.message);
    }
  }

  undo() {
    // this.workspace.undo();
    // this.buttonClicked.emit();
  }
  isScreenCollaps(event: any) {
    console.log('close', event);
    this.isCollasped = event;
    // this.isCollasped=!this.isCollasped;
  }

  isCollasped: boolean = true;
  onCollasped() {
    this.isCollasped = !this.isCollasped;
  }

  async emitRightBlock(event?: any) {
    let rightBlock =
      this.blocklyComponent.workspace.workspace.getAllBlocks(true);
    await this.rightBlockCodeEmitter.emit({ rightBlock });
  }

  async emitCompareBlock(event?: any) {
    await this.compareBlockEmitter.emit({});
  }

  retryConnection() {
    this.createWebSocketConnection();
  }
}

@NgModule({
  declarations: [BlocklySectionComponent],
  imports: [
    CommonModule,
    BlocklyModule,
    AngularSplitModule,
    SidePanelModule,
    FileSaverModule,
    PythonDirectModule,
  ],
  exports: [BlocklySectionComponent],
})
export class BlocklySectionModule {}