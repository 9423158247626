import { Component, OnInit } from '@angular/core';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { config } from './config';
@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
    ) { }

  showLoader:boolean = false;
  studentId: string = '';
  challengeId: string = '';
  studentName: string = '';
  gccToken: any = '';
  appIdentifier: any = '';
  
  ngOnInit(): void {
    this.onSubmit();
  }
  private isValidGroup(groups: any) {
    const validGroups = ["Administrator", "Content Manager"];
    return groups.every((el: any) => validGroups.indexOf(el) > -1);
  }
  
  async onSubmit() {

    const payload = {
      username: config.Username,
      password: config.Password,
    };

    try {
      this.showLoader = true;
      let data: any = await this.authService
        .authenticateLMS(payload.username, payload.password)
        .toPromise();
      // data['username'] = this.username;
      const groups = data.groups;
      // console.log(data);
      if (data.status && this.isValidGroup(groups)) {
        sessionStorage.clear();
        localStorage.clear();
        
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.challengeId = params.get('challengeId');
          this.studentId = params.get('studentId');
          this.studentName = params.get('studentName');
        })
        sessionStorage.setItem('challengeId', this.challengeId);
        sessionStorage.setItem('student', this.studentName);
        sessionStorage.setItem('studentId', this.studentId);

        sessionStorage.setItem("token", data.session_id);
        sessionStorage.setItem("user", JSON.stringify(data));

        this.route.queryParams.subscribe(params => {
          this.gccToken = window.atob(params['token']);
          this.appIdentifier = params['appIdentifier'];
        })
        sessionStorage.setItem("gccToken", this.gccToken);

        if(this.appIdentifier === undefined){
          this.router.navigateByUrl('admin/application/create');
        }
        else{
          this.router.navigateByUrl(`admin/application/edit/${this.appIdentifier}`);
        }
      }
    }  finally {
      this.showLoader = false;
    }
  }
}
