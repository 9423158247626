import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { updateLab } from 'src/app/shared/models/lab';
import { LabService } from 'src/app/shared/services/lab.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import {
  OrganizationsService,
  Organization,
} from 'src/app/shared/services/organizations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-lab',
  templateUrl: './edit-lab.component.html',
  styleUrls: ['./edit-lab.component.scss'],
})
export class EditLabComponent implements OnInit {
  labId!: number;
  formData: any;
  updateLabForm = new FormGroup({
    orgName: new FormControl(''),
    labName: new FormControl(''),
  });
  currentUser: any;
  isLoading: boolean = false;
  orgList: Organization[] = [];
  isSuperAdmin: boolean = true;
  isGrokCloudMember: boolean = true;
  currentOrganization!: string;
  organizationName!: string;
  selectedOrg: { orgId: number; orgName: string } | null = null;
  selectedOrgId: number;
  selectedOrgName: string;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private labService: LabService,
    private toasterService: ToastrService,
    private sessionStorageService: SessionStorageService,
    private orgService: OrganizationsService
  ) {
    this.setDefaultOrganization();
    this.isSuperAdmin = window.location.href.includes('/admin/');
  }

  setDefaultOrganization() {
    this.currentOrganization =
      this.sessionStorageService.getCurrentOrganization();
    if (this.currentOrganization === environment.organizationID) {
    } else {
      this.organizationName = this.currentOrganization;
      this.isGrokCloudMember = false;
      this.updateLabForm.patchValue({
        orgName: this.organizationName,
      });
    }
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params: any) => {
      this.labId = params.labid;
      if (this.labId) {
        this.getLabdata(this.labId);
      }
    });
    this.searchOrganizations();
    this.currentUser = this.sessionStorageService.getUsername();
  }

  async searchOrganizations() {
    try {
      this.isLoading = true;
      const result: any = await this.orgService.search().toPromise();
      this.isLoading = false;
      if (result && result.organizations.length > 0) {
        this.orgList = result.organizations;
      }
    } catch (e) {
      console.log(e);
    }
  }

  getLabdata(labId: any) {
    this.labService.get(labId).subscribe((labData: any) => {
      console.log(labData);
      this.formData = labData['lab'];
      console.log(this.formData);
      if (this.formData) {
        this.updateLabForm.patchValue({
          orgName: this.formData.orgName,
          labName: this.formData.labName,
        });
      }
    });
  }

  onOrgSelect(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedOrgId = Number(target.value);
    this.selectedOrgName = String(target.value);
    this.selectedOrg =
      this.orgList.find((org) => org.orgName === this.selectedOrgName) || null;
  }

  updateLab() {
    if (!this.updateLabForm.invalid) {
      const controls = this.updateLabForm.controls;
      const payload: updateLab = {
        labName: controls['labName'].value,
        orgName: this.selectedOrg?.orgName,
        orgId: this.selectedOrg?.orgId,
        registeredDevicesCount: parseFloat(
          this.formData.registeredDevicesCount
        ),
        isActive: this.formData.isActive,
        updatedBy: this.currentUser,
      };
      this.labService.update(this.labId, payload).subscribe((res: any) => {
        if (res?.success == true) {
          this.toasterService.success('Lab updated successfully');
          this.onCancel();
        } else {
          this.toasterService.error(
            'Can not update the lab currently.Please try again later.'
          );
        }
      });
    }
  }

  onCancel() {
    if (this.isSuperAdmin) {
      this.router.navigateByUrl('/admin/labs');
    } else {
      this.router.navigateByUrl('/ide/labs');
    }
  }
}
