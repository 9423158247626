import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {
  outlineUserName: boolean;
  userName: string = '';
  userNameArray: string[] = [];
  user: string;
  loggedInUser: string;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private toasterService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.user = sessionStorage.getItem('user');
    this.loggedInUser = JSON.parse(this.user)?.username;
  }

  onFocus() {
    this.outlineUserName = false;
  }

  onUserNameChange() {
    if (this.userName) {
      this.userNameArray = this.userName.split(',').map(name => name.trim()).filter(name => name.length > 0);
    } else {
      this.userNameArray = [];
    }
  }

  async onSubmit() {
    if (!this.userName) {
      this.outlineUserName = true;
      return;
    }
    this.isLoading = true;
    this.onUserNameChange();
    try {
      const payload = {
        userIds: this.userNameArray,
        loggedInUser: this.loggedInUser
      }
      let result: any = await this.usersService.createUsers(payload).toPromise();
      this.toasterService.success(result.message);
      this.router.navigateByUrl('/admin/users');
      this.isLoading = false;
    }
    catch (err: any) {
      console.log(err);
      this.toasterService.error(err.error.message);
      this.isLoading = false;
    }
  }

  onCancel() {
    this.router.navigateByUrl('/admin/users');
  }
}
