import { Component, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { IPageChangeEvent } from "src/app/components/pagination/pagination.component";
import { ViewApplication } from "src/app/models/applications";
import { ApplicationService } from "src/app/shared/services/application.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { UserSessionService } from "src/app/shared/services/user.session.service";
import { IAppState } from "src/app/store/index.state";
import { environment } from "src/environments/environment";

import {
  deleteApplication,
  deleteApplicationTemplate,
  loadApplications,
  loadApplicationTemplates,
  setPageNumber,
  setPageSize
} from "./store/application.actions";
import {
  getApplications,
  getApplicationTemplates,
  getPageNumber,
  getPageSize,
  getTotal
} from "./store/application.selectors";

@Component({
  selector: "app-application",
  templateUrl: "./application.component.html",
  styleUrls: ["./application.component.scss"],
})
export class ApplicationComponent implements OnInit, OnDestroy {
  selectedApplication!: any;
  selectedApplicationIdentifier!: string;
  selectedModuleName: string = '';
  validModuleNames: string[] = ['iot', 'robotics', 'data_science', 'aiot', 'artificial_intelligence', "iiot"];
  isEdit = !!this.selectedApplication;
  isMenuOpen: boolean = false;
  isModalOpen: boolean = false;
  isDialogOpen: boolean = false;
  isFromGCC: boolean = false;
  isAdmin: boolean = window.location.href.includes('/admin/application') || window.location.href.includes('/admin/template');
  templateFlag: boolean = true;
  userAppFlag: boolean = false;
  isUserTemplate: boolean = false;
  filteredApplications: ViewApplication[] = [];
  readonly domainUrl: string = environment.domainUrl;

  applications$ = this.store.select(getApplications);
  applicationTemplates$ = this.store.select(getApplicationTemplates);
  total$ = this.store.select(getTotal);
  pageNumber$ = this.store.select(getPageNumber);
  pageSize$ = this.store.select(getPageSize);


  @Output() editApplication = new EventEmitter();

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private sessionStorageService: SessionStorageService,
    private toasterService: ToastrService,
    private userSessionService: UserSessionService,
  ) { }

  async ngOnInit() {
    if(sessionStorage.getItem('challengeId')){
      this.isFromGCC = true;
      this.userApplication();
    }
    else {
      this.template();
    }
    await this.userSessionService.extendToken(this.sessionStorageService.getUsername()).toPromise();

    this.fetchCurrentModule();
  }

  fetchCurrentModule() {
    this.route?.queryParams?.subscribe((data: any) => {
      if (data['moduleName']) {
        const moduleName = data['moduleName'];
        if (this.validModuleNames.includes(moduleName)) {
          this.selectedModuleName = moduleName;
        } 
        console.log({ moduleName });
        this.filterApplicationByModuleName(this.filteredApplications);
      } 
    });
  }

  fetchApplications() {
    if (!this.isAdmin) {
      this.store.dispatch(loadApplications());
      this.applications$.subscribe((applications: ViewApplication[]) => {
        this.filteredApplications = applications;
      });
    } else {
      this.store.dispatch(loadApplicationTemplates());
      this.applicationTemplates$.subscribe((applications: ViewApplication[]) => {
        this.filteredApplications = applications;
      })
    }
    this.applications$.subscribe((applications: ViewApplication[]) => {
      this.filteredApplications = applications;
    });
  }

  async openApplication(item: ViewApplication) {
    const { applicationIdentifier } = item;
    if (applicationIdentifier) {
      this.navigateToUrlFrom("edit/" + applicationIdentifier, true)
    }
  }

  navigatetocircuit(app_id : any){
    sessionStorage.setItem("appid" , app_id )
    this.navigateToUrlFrom('circuit');
  }

  navigateToReference(appId: string, appType:string, route: string) {
    sessionStorage.setItem('app_id', appId);
    sessionStorage.setItem('app_type', appType);
    let url: string = ""
      if(this.isAdmin){
        url = `admin/${appType}/${route}`;
      } else {
        url = `ide/${appType}/${route}`;
      }
    this.router.navigateByUrl(url);
  }
  
  navigateToUrlFrom(route: string, isEdit?: boolean, isFromApplication?: boolean) {
    let url: string = ""
    if (isEdit && isFromApplication) {
      url = `admin/template/${route}`;
    }
    else if (isEdit && !isFromApplication && !this.isAdmin) {
      url = `ide/application/${route}`;
    }
    else if (isEdit) {
      if (this.isAdmin && this.templateFlag) {
        url = `admin/template/${route}`;

      } else if (this.isAdmin && this.userAppFlag) {
        url = `admin/application/${route}`;

      } else if (!this.isAdmin && this.userAppFlag) {
        url = `ide/application/${route}`;
      }
    }
    else {
      if (this.isAdmin && this.templateFlag) {
        url = `admin/template/${route}`;
      } else if (this.isAdmin && this.userAppFlag) {
        url = `admin/application/${route}`;
      }
    }
    this.router.navigateByUrl(url);
  }

  ngOnDestroy() { }

  onPageChange(event: IPageChangeEvent) {
    if (event) {
      this.store.dispatch(setPageNumber({ value: event.page }));
    }
  }

  onPageSizeChange(event: any) {
    if (event) {
      this.store.dispatch(setPageSize({ value: event }));
    }
  }

  removeApplication(id: string) {
    if (this.templateFlag) {
      this.store.dispatch(deleteApplicationTemplate({ id }));
    } else {
      this.store.dispatch(deleteApplication({ id }));
    }
  }

  changePublishedFlag(currentItem: ViewApplication): void {
    let flag = currentItem.is_published;
    let appId = currentItem.applicationIdentifier;
    let payload = {
      "is_published": !flag
    }
    this.applicationService.updateApplicationTemp(payload, appId)
      .toPromise()
      .then(result => {
        this.fetchApplications();
      });
  }

  // changePublishedFlag(currentItem: ViewApplication): void {
  //   let appId = currentItem.applicationIdentifier;
  //   let payload = {
  //     "is_published": false,
  //     "isInternallyPublished": false
  //   }

  //   this.applicationService.updateApplicationTemp(payload, appId)
  //     .toPromise()
  //     .then(result => {
  //       this.fetchApplications();
  //     });
  // }

  // openPublish(item:ViewApplication): void{
  //   this.selectedApplication = item;
  //   console.log(this.selectedApplication);
    
  //   this.selectedApplicationIdentifier = item.applicationIdentifier
  //   this.isDialogOpen = true;
  //   console.log(this.isDialogOpen);
  // }

  // closeDialog(event:any): void {
  //   this.isDialogOpen = false
  //   // this.selectedApplication = null
  // }

  confirmAndDelete(item: ViewApplication): void {
    this.selectedApplication = item;
    this.selectedApplicationIdentifier = item.applicationIdentifier;
    this.isModalOpen = true;
  }

  async clone(item: ViewApplication) {
    this.selectedApplication = item;
    let id = this.selectedApplicationIdentifier = item.applicationIdentifier;
    try {
      let result: any;
      const username = this.sessionStorageService.getUsername()
      // const username = JSON.parse(sessionStorage.getItem("user") || "{}")['name'];
      const payload = {
        reference_application_identifier: id,
        app_user_id: username
      }
      if (this.templateFlag) {
        result = await this.applicationService.cloneApplicationTemplate(payload).toPromise();
      } else if (this.userAppFlag) {
        result = await this.applicationService.cloneApplication(payload).toPromise();
      }

      if (result) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async delete(event: any) {
    if (!event) {
      return;
    }
    const id: string = this.selectedApplicationIdentifier;
    try {
      let result: any;
      if (this.templateFlag) {
        result = await this.applicationService.deleteApplicationTemp(id).toPromise();
      } else {
        result = await this.applicationService.deleteApplication(id).toPromise();
      }
      if (result) {
        this.removeApplication(id);
      }
    } catch (error: any) {
      console.log(error);
      if (error.status == "200") this.removeApplication(id);
    } finally {
      this.isModalOpen = false;
    }
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeModal(event: any): void {
    this.selectedApplication = null;
    this.isModalOpen = false;
  }

  onSelectModule(moduleName: string): void {
    let list;
    if (this.templateFlag) {
      list = this.applicationTemplates$
    } else {
      list = this.applications$;
    }
    list.subscribe((applications: any) => {
      if (moduleName === "all") {
        this.filteredApplications = applications;
      } else {
        this.filteredApplications = applications.filter((data: any) => data.moduleName === moduleName);
      }
    });
    this.toggleMenu();
  }


  template() {
    this.templateFlag = true;
    this.userAppFlag = false;
    if (this.isAdmin) {
      this.fetchApplications();
    }
    else {
      //this.isUserTemplate=true;
      this.store.dispatch(loadApplicationTemplates());
      this.applicationTemplates$.subscribe((applications: any) => {
        const publishedApp = applications.filter((app: any) => {
          if (app.is_published) {
            return app
          }
        })
        this.filteredApplications = this.filterApplicationByModuleName(publishedApp);
      });
      this.applications$.subscribe((applications: any) => {
        this.filteredApplications = this.filterApplicationByModuleName(applications);
      });
    }
  }

  async userApplication() {
    this.templateFlag = false;
    this.userAppFlag = true;
    this.filteredApplications = []
    let data: any;
    const username = this.sessionStorageService.getUsername();
    if (this.isAdmin) {
      data = await this.applicationService
        .getAllApplication(environment.organizationID, "*")
        .toPromise();
    } else {
      data = await this.applicationService
        .getAllApplication(environment.organizationID, username)
        .toPromise();
    }
    if (data) {
      this.filteredApplications = this.filterApplicationByModuleName(data?.applications);
      if (this.isFromGCC) {
        this.filteredApplications = this.filteredApplications.filter(item => item.createdBy == (sessionStorage.getItem('student')))
      }
    }
    this.store.dispatch(loadApplications());
  }

  filterApplicationByModuleName(applications: any[]) {
    let filteredApplications = applications;
    if (this.selectedModuleName.length > 0) {
      filteredApplications = applications.filter(app => app.moduleName == this.selectedModuleName);
    }
    return filteredApplications;
  }

  async saveAsTemplate(item: any) {
    try {
      let result: any;
      const username = this.sessionStorageService.getUsername();
      let payload: any = {
        applicationName: item.applicationName || "",
        applicationDesc: item.applicationDesc || "",
        boardIdentifier: environment.boardIdentifier,
        organizationID: environment.organizationID,
        moduleName: item.moduleName,
        controller_type: '',
        simulator_type: '',
        appUserID: username || "Anonymous",
        createdBy: username || "Anonymous",
        updatedBy: username || "Anonymous",
        referenceApplicationIdentifier: item.applicationIdentifier,
        operationType: "Copy"
      };
      payload = { ...payload };
      if (this.isAdmin) {
        result = await this.applicationService
          .createApplicationTemp(payload)
          .toPromise();
      }
      if (result) {
        this.toasterService.success("Application saved as template");
        this.navigateToUrlFrom("edit/" + result.applicationIdentifier, true, true)
      }
    } catch (error) {
      console.log(error);
    }

  }

  async saveAsApplication(item: any) {
    const username = this.sessionStorageService.getUsername();
    let payload: any = {
      applicationName: item.applicationName || "",
      applicationDesc: item.applicationDesc || "",
      boardIdentifier: environment.boardIdentifier,
      organizationID: environment.organizationID,
      moduleName: item.moduleName,
      controller_type: item.controller_type || '',
      simulator_type: item.simulator_type || '',
      appUserID: username || "Anonymous",
      createdBy: username || "Anonymous",
      updatedBy: username || "Anonymous",
      problemTryingToSolve: item.problemTryingToSolve || "",
      industryDesignedFor: item.industryDesignedFor?.industry_name || "",
      applicationCreationProcedure: item.applicationCreationProcedure || "",
      referenceApplicationIdentifier: item.applicationIdentifier,
      operationType: "Copy"
    };
    payload = { ...payload };
    let result: any = await this.applicationService
      .createApplication(payload)
      .toPromise();
    if (result) {
      this.toasterService.success("Template saved as application");
      this.navigateToUrlFrom("edit/" + result.applicationIdentifier, true, false)
    }
  }

  backtoGCC() {
      sessionStorage.removeItem('ChallengeId');
      sessionStorage.removeItem('StudentId');
      sessionStorage.removeItem('Student');

      window.open(environment.gccDevFrontEndUrl, "_self");
  }
}